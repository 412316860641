import {Component, OnInit} from '@angular/core';
import {combineLatest} from 'rxjs';
import {ComboSelectedService} from '../../../combo-selected.service';

@Component({
  selector: 'gtd-flight-confirm-dialog',
  templateUrl: './flight-confirm-dialog.component.html',
  styleUrls: ['./flight-confirm-dialog.component.scss']
})
export class FlightConfirmDialogComponent implements OnInit {
  flightBooking$ = this._comboSelectedService.flightListSelected$;
  flightAutoPrice$ = this._comboSelectedService.priceOfFlightAutoSelected$;
  flightSelectedDone$ = this._comboSelectedService.flightSelectedDone$;
  totalPrice = 0;
  constructor(private _comboSelectedService: ComboSelectedService) {}

  ngOnInit() {
    this.flightSelectedDone$.take(1).subscribe(done => {
      if (!done && this._comboSelectedService.flightReset) {
        this._comboSelectedService.resetFLight();
        const flightBookingFromStorage = JSON.parse(
          localStorage.getItem('flightBooking')
        );
        for (const item of flightBookingFromStorage) {
          this._comboSelectedService.selectFlight(
            item.searchId,
            item.pricedItinerary,
            item.itemFlight,
            item.type
          );
        }
      }
    });
    combineLatest(this.flightBooking$, this.flightAutoPrice$).subscribe(
      data => {
        const [booking, price] = data;
        if (booking && price) {
          for (let i = 0; i < booking.length; i++) {
            this.totalPrice +=
              booking[i].pricedItinerary.airItineraryPricingInfo.adultFare
                .passengerFare.totalFare.amount /
                booking[i].pricedItinerary.airItineraryPricingInfo.adultFare
                  .passengerTypeQuantities.quantity -
              price[i];
          }
        }
      }
    );
  }
}
