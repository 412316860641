import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {
  GroupPricedItinerary,
  OriginDestinationOption,
  PricedItinerary
} from '@gtd/b2c-client';
import { environment } from '@env/environment';
import {
  FlightBookingFacade,
  FlightFarerulesFacade
} from '@gtd/flights/data-access/store';
import { fadeIn } from '@gtd/helpers';
import { first } from 'rxjs/operators';
import { FlightNewTabService } from '@gtd/flights/data-access/services';
import {GaTrackingService} from "@gtd/ga-tracking";


@Component({
  selector: 'gtd-combo-detail-dialog',
  templateUrl: './combo-detail-dialog.component.html',
  styleUrls: ['./combo-detail-dialog.component.scss'],
  animations: [fadeIn]
})
export class ComboDetailDialogComponent implements OnInit, OnDestroy {
  priceDetailToggle = false;
  allFlightFarerules$ = this._flightFarerulesFacade.allFlightFarerules$;
  allFlightBooking$ = this._flightBookingFacade.allFlightBooking$;
  originDestinationOption: OriginDestinationOption;
  constructor(
    private _flightFarerulesFacade: FlightFarerulesFacade,
    private _dialogRef: MatDialogRef<ComboDetailDialogComponent>,
    private _flightBookingFacade: FlightBookingFacade,
    private _flightNewTabService: FlightNewTabService,
    private _gaTrackingService: GaTrackingService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      flightItem: any;
      pricedItinerary: PricedItinerary;
      originDestinationOption: OriginDestinationOption;
      totalTax: number;
      passengerFares: any[];
      searchId: string;
      flightType: string;
      flightBookingType: string;
      groupId: string;
    }
  ) {}

  ngOnInit() {
    const currentLanguage = localStorage.getItem('language');
    if(this.data && this.data.originDestinationOption) {
      this.originDestinationOption = this.data.originDestinationOption
    }
    this._flightFarerulesFacade.loadAll(
      {
        fareSourceCode: this.data.pricedItinerary.airItineraryPricingInfo
          .fareSourceCode,
        groupId: this.data.groupId,
        searchId: this.data.searchId
      },
      currentLanguage
    );
    this.allFlightBooking$.subscribe(allFlightBooking => {
      let originDestinationOption = this.data.pricedItinerary.originDestinationOptions.find(
        originDestinationOption => {
          return allFlightBooking.from
            ? originDestinationOption.flightDirection === 'R'
            : originDestinationOption.flightDirection === 'D';
        }
      );
      if(originDestinationOption) {
        this.originDestinationOption = originDestinationOption;
      }
    });
  }

  selectItem() {
    this._gaTrackingService.track('F_SearchDetail_Select');
    this._flightBookingFacade.setFlightBooking({
      groupPricedItinerary: this.data.flightItem,
      pricedItinerary: this.data.pricedItinerary,
      searchId: this.data.searchId
    });
    this.allFlightBooking$.pipe(first()).subscribe(allFlightBooking => {
      if (
        (allFlightBooking.roundType ===
          GroupPricedItinerary.RoundTypeEnum.ONEWAY &&
          allFlightBooking.from) ||
        (allFlightBooking.roundType ===
          GroupPricedItinerary.RoundTypeEnum.ROUNDTRIP &&
          allFlightBooking.from &&
          allFlightBooking.to)
      ) {
        this._flightNewTabService.setTab(
          window.open('/checkout/flight', '_blank')
        );
      }
    });
    this.closeDialog();
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }, 500);
  }

  displayAirlineLogo(groupPricedItinerary: any) {
    if(groupPricedItinerary.airline) {
      if (
        groupPricedItinerary.airline === 'VN' ||
        groupPricedItinerary.airline === 'VJ' ||
        groupPricedItinerary.airline === 'QH' ||
        groupPricedItinerary.airline === 'BL'
      )
        return environment.cdn.logoAirline + groupPricedItinerary.airline + '.svg';
      else return environment.cdn.logoAirline + groupPricedItinerary.airline + '.gif';
    } else {
      if(groupPricedItinerary
        && groupPricedItinerary.flightSegments
        && groupPricedItinerary.flightSegments.length
      ) {
        let airlineCode = groupPricedItinerary.flightSegments[0].operatingAirline.code
        if (
          airlineCode === 'VN' ||
          airlineCode === 'VJ' ||
          airlineCode === 'QH' ||
          airlineCode === 'BL'
        )
          return environment.cdn.logoAirline + airlineCode + '.svg';
        else return environment.cdn.logoAirline + airlineCode + '.gif';
      }
    }
  }

  closeDialog() {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this._flightFarerulesFacade.reset();
  }

}
