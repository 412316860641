import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightSearchFilterAvailabilityPartialState } from './flight-search-filter-availability.reducer';
import { flightSearchFilterAvailabilityQuery } from './flight-search-filter-availability.selectors';
import {
  FlightFilterAvailable,
  LoadFlightSearchFilterAvailability,
  ResetFlightSearchFilterAvailability
} from './flight-search-filter-availability.actions';

@Injectable()
export class FlightSearchFilterAvailabilityFacade {
  loaded$ = this.store.pipe(
    select(flightSearchFilterAvailabilityQuery.getLoaded)
  );
  allFlightSearchFilterAvailability$ = this.store.pipe(
    select(
      flightSearchFilterAvailabilityQuery.getAllFlightSearchFilterAvailability
    )
  );
  selectedFlightSearchFilterAvailability$ = this.store.pipe(
    select(
      flightSearchFilterAvailabilityQuery.getSelectedFlightSearchFilterAvailability
    )
  );

  constructor(
    private store: Store<FlightSearchFilterAvailabilityPartialState>
  ) {}

  loadAll(payload: FlightFilterAvailable) {
    this.store.dispatch(new LoadFlightSearchFilterAvailability(payload));
  }

  reset() {
    this.store.dispatch(new ResetFlightSearchFilterAvailability());
  }
}
