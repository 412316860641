import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailPriceExchangeComponent } from './detail-price-exchange.component';
import {
  GButtonModule,
  GMenuModule,
  GTypographyModule
} from '@gotadi/design-system';
import { MatIconModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CashAndMilesModule } from '@gtd/hotels/ui/cash-and-miles';
import { PipesModule } from '@gtd/hotels/data-access/pipes';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        GTypographyModule,
        GButtonModule,
        GMenuModule,
        MatIconModule,
        RouterModule,
        CashAndMilesModule,
        PipesModule,
        TranslateModule
    ],
  declarations: [DetailPriceExchangeComponent],
  exports: [DetailPriceExchangeComponent]
})
export class DetailPriceExchangeModule {}
