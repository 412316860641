import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  HotelAllRatesPayload,
  HotelSearchAllRatesPartialState
} from './hotel-search-all-rates.reducer';
import { hotelSearchAllRatesQuery } from './hotel-search-all-rates.selectors';
import {
  LoadHotelSearchAllRates,
  ResetHotelSearchAllRates
} from './hotel-search-all-rates.actions';

@Injectable()
export class HotelSearchAllRatesFacade {
  loaded$ = this.store.pipe(select(hotelSearchAllRatesQuery.getLoaded));
  hotelDetailError$ = this.store.pipe(select(hotelSearchAllRatesQuery.getError));
  searchId$ = this.store.pipe(select(hotelSearchAllRatesQuery.getSearchId));
  paramInfo$ = this.store.pipe(select(hotelSearchAllRatesQuery.getParamInfo));
  tripId$ = this.store.pipe(select(hotelSearchAllRatesQuery.getTripId));
  allHotelSearchAllRates$ = this.store.pipe(
    select(hotelSearchAllRatesQuery.getAllHotelSearchAllRates)
  );
  selectedHotelSearchAllRates$ = this.store.pipe(
    select(hotelSearchAllRatesQuery.getSelectedHotelSearchAllRates)
  );

  constructor(private store: Store<HotelSearchAllRatesPartialState>) {}

  loadAll(payload: HotelAllRatesPayload) {
    this.store.dispatch(new LoadHotelSearchAllRates(payload));
  }

  reset() {
    this.store.dispatch(new ResetHotelSearchAllRates());
  }
}
