import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelCheckoutPartialState } from './hotel-checkout.reducer';
import { hotelCheckoutQuery } from './hotel-checkout.selectors';
import {
  LoadHotelCheckout,
  ResetHotelCheckout
} from './hotel-checkout.actions';
import { CheckoutPayload } from '@gtd/b2c-client';

@Injectable()
export class HotelCheckoutFacade {
  loaded$ = this.store.pipe(select(hotelCheckoutQuery.getLoaded));
  allHotelCheckout$ = this.store.pipe(
    select(hotelCheckoutQuery.getAllHotelCheckout)
  );
  selectedHotelCheckout$ = this.store.pipe(
    select(hotelCheckoutQuery.getSelectedHotelCheckout)
  );

  constructor(private store: Store<HotelCheckoutPartialState>) {}

  loadAll(payload: CheckoutPayload) {
    this.store.dispatch(new LoadHotelCheckout(payload));
  }

  reset() {
    this.store.dispatch(new ResetHotelCheckout());
  }
}
