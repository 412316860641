import { Action } from '@ngrx/store';
import {
  FlightFilterState,
  SelectDepartureItem
} from './flight-filter.reducer';
import { AirItineraryInfo } from '@gtd/b2c-client';

export enum FlightFilterActionTypes {
  ComboVJResetFlightFilter = '[ComboVJ_FlightFilter] Reset FlightFilter',
  ComboVJResetFlightFilterRoundTrip = '[ComboVJ_FlightFilter] Reset  Flight Filter RoundTrip',
  ComboVJChangeFlightFilter = '[ComboVJ_FlightFilter] Change FlightFilter',
  ComboVJChangeAirlineOptions = '[ComboVJ_FlightFilter] Change AirlineOptions',
  ComboVJChangeArrivalDateTimeOptions = '[ComboVJ_FlightFilter] Change ArrivalDateTimeOptions',
  ComboVJChangeArrivalDateTimeReturnOptions = '[ComboVJ_FlightFilter] Change ArrivalDateTimeReturnOptions',
  ComboVJChangeCabinClassOptions = '[ComboVJ_FlightFilter] Change CabinClassOptions',
  ComboVJChangeDepartureDateTimeOptions = '[ComboVJ_FlightFilter] DepartureDateTimeOptions',
  ComboVJChangeFlightType = '[ComboVJ_FlightFilter] Change FlightType',
  ComboVJChangeStep = '[ComboVJ_FlightFilter] Change Step',
  ComboVJChangeStopOptions = '[ComboVJ_FlightFilter] Change StopOptions',
  ComboVJChangeTicketPolicyOptions = '[ComboVJ_FlightFilter] Change TicketPolicyOptions',
  ComboVJChangeFlightPaginationPage = '[ComboVJ_FlightFilter] Change FlightPagination Page',
  ComboVJChangePrice = '[ComboVJ_FlightFilter] Change ComboVJChangePrice',
  ComboVJChangeFlightPaginationSort = '[ComboVJ_FlightFilter] Change FlightPagination Sort',
  ComboVJChangeFlightPaginationSize = '[ComboVJ_FlightFilter] Change FlightPagination Size',
  ComboVJChangeSearchID = '[ComboVJ_FlightFilter] Change SearchID',
  ComboVJSelectDeparture = '[ComboVJ_FlightFilter] Select Departure'
}

export class ComboVJResetFlightFilter implements Action {
  readonly type = FlightFilterActionTypes.ComboVJResetFlightFilter;
}

export class ComboVJResetFlightFilterRoundTrip implements Action {
  readonly type = FlightFilterActionTypes.ComboVJResetFlightFilterRoundTrip;
  constructor(public payload: {
    searchID: string;
    flightType: string;
    departureItinerary: AirItineraryInfo;
  }) {}
}

export class ComboVJChangeFlightFilter implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeFlightFilter;

  constructor(public payload: FlightFilterState) {}
}

export class ComboVJChangeAirlineOptions implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeAirlineOptions;

  constructor(public payload: string[]) {}
}

export class ComboVJChangeArrivalDateTimeOptions implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeArrivalDateTimeOptions;

  constructor(public payload: string[]) {}
}

export class ComboVJChangeArrivalDateTimeReturnOptions implements Action {
  readonly type =
    FlightFilterActionTypes.ComboVJChangeArrivalDateTimeReturnOptions;

  constructor(public payload: string[]) {}
}

export class ComboVJChangeCabinClassOptions implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeCabinClassOptions;

  constructor(public payload: string[]) {}
}

export class ComboVJChangeDepartureDateTimeOptions implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeDepartureDateTimeOptions;

  constructor(public payload: string[]) {}
}

export class ComboVJChangeFlightType implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeFlightType;

  constructor(public payload: string) {}
}

export class ComboVJChangeStep implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeStep;

  constructor(public payload: string) {}
}

export class ComboVJChangeStopOptions implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeStopOptions;

  constructor(public payload: string[]) {}
}

export class ComboVJChangeTicketPolicyOptions implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeTicketPolicyOptions;

  constructor(public payload: string[]) {}
}

export class ComboVJChangePrice implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangePrice;
  constructor(public payload: { fromPrice: number; toPrice: number }) {}
}

export class ComboVJChangeFlightPaginationPage implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeFlightPaginationPage;
  constructor(public payload: number) {}
}

export class ComboVJChangeFlightPaginationSort implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeFlightPaginationSort;
  constructor(public payload: string[]) {}
}

export class ComboVJChangeFlightPaginationSize implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeFlightPaginationSize;
  constructor(public payload: number) {}
}

export class ComboVJChangeSearchID implements Action {
  readonly type = FlightFilterActionTypes.ComboVJChangeSearchID;
  constructor(public payload: string) {}
}

export class ComboVJSelectDeparture implements Action {
  readonly type = FlightFilterActionTypes.ComboVJSelectDeparture;
  constructor(public payload: SelectDepartureItem) {}
}

export type FlightFilterAction =
  | ComboVJResetFlightFilter
  | ComboVJResetFlightFilterRoundTrip
  | ComboVJChangeFlightFilter
  | ComboVJChangeAirlineOptions
  | ComboVJChangeArrivalDateTimeOptions
  | ComboVJChangeArrivalDateTimeReturnOptions
  | ComboVJChangeCabinClassOptions
  | ComboVJChangeDepartureDateTimeOptions
  | ComboVJChangeFlightType
  | ComboVJChangeStep
  | ComboVJChangeStopOptions
  | ComboVJChangeTicketPolicyOptions
  | ComboVJChangeFlightPaginationPage
  | ComboVJChangeFlightPaginationSort
  | ComboVJChangeFlightPaginationSize
  | ComboVJSelectDeparture
  | ComboVJChangePrice
  | ComboVJChangeSearchID;

export const fromFlightFilterActions = {
  ComboVJResetFlightFilter,
  ComboVJResetFlightFilterRoundTrip,
  ComboVJChangeFlightFilter,
  ComboVJChangeAirlineOptions,
  ComboVJChangeArrivalDateTimeOptions,
  ComboVJChangeArrivalDateTimeReturnOptions,
  ComboVJChangeCabinClassOptions,
  ComboVJChangeDepartureDateTimeOptions,
  ComboVJChangeFlightType,
  ComboVJChangeStep,
  ComboVJChangeStopOptions,
  ComboVJChangeTicketPolicyOptions,
  ComboVJChangeFlightPaginationPage,
  ComboVJChangeFlightPaginationSort,
  ComboVJChangeFlightPaginationSize,
  ComboVJSelectDeparture,
  ComboVJChangePrice,
  ComboVJChangeSearchID
};
