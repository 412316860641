import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PropertyAvailable, PropertyRoom, RatePlan } from '@gtd/b2c-client';
import { HotelFunctionService } from '@gtd/hotels/data-access/services';
import { HotelBookingFacade } from '@gtd/hotels/data-access/store';
import { ExchangeOptionService } from '@gtd/lotus/shared/data-access/services';

@Component({
  selector: 'gtd-detail-price-exchange',
  templateUrl: './detail-price-exchange.component.html',
  styleUrls: ['./detail-price-exchange.component.scss']
})
export class DetailPriceExchangeComponent implements OnInit {
  @Input() dayGap: number;
  @Input() numberOfRooms: number;
  @Input() numberOfAdults: number;
  @Input() numberOfChilds: number;
  @Input() priceBeforePromo: number;
  @Input() priceAfterPromo: number;
  @Input() exchangeRate = 0.5;
  @Input() search: any;
  @Input() searchId: string;
  @Input() item: RatePlan;
  @Input() room: PropertyRoom;
  @Input() tripId: string;

  userValue = 0;
  exchangeOption$ = this._exchangeOptionService.option$;
  constructor(
    private _exchangeOptionService: ExchangeOptionService,
    private _hotelFunctionService: HotelFunctionService,
    private _activatedRoute: ActivatedRoute,
    private _hotelBookingFacade: HotelBookingFacade
  ) {
    this._activatedRoute.queryParams.subscribe(params => {
      if (!params || !params.rangeValue) {
        return;
      }
      this.userValue = Number(params.rangeValue);
    });
  }

  ngOnInit() {}

  selectRoom(type?: string) {
    this._hotelBookingFacade.loadAll({
      tripId: this.tripId,
      ratePlanId: this.item.ratePlanId,
      roomId: this.room.id,
      rewardExchangeRate: this.exchangeRate,
      userPaymentRate: type == 'miles' ? 1 : this.userValue * 0.01
    });
  }
}
