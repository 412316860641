import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Btn, DialogMessage } from '../dialogMessage';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gtd-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
export class DialogMessageComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogMessageComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogMessage,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      window.parent.postMessage({ action: 'scrollToTop' }, '*');
    }
  }

  ngOnInit() {}

  onNoClick(btn: Btn, action?: boolean): void {
    if (btn.onClick) {
      console.log(btn.onClick);
      btn.onClick();
    }
    if (action) {
      if (btn.btnLink) {
        this.router.navigate([btn.btnLink]);
      }
      if (btn.btnReload) {
        window.location.reload();
      }
    } else {
      this.dialogRef.close({ action: btn.action ? btn.action : '' });
    }
  }

  onCloseDialog() {
    this.dialogRef.close({ action: '' });
  }

  buildDataTranslateParams() {
    const { dataTranslate } = this.data;
    if (!dataTranslate) return;
    if (typeof dataTranslate == 'string') {
      return { data: dataTranslate };
    } else {
      return dataTranslate.reduce(
        (arr, cur) => ({ ...arr, [cur.key]: cur.value }),
        {}
      );
    }
  }

  parseDataTranslateInnerHTML() {
    return this.translateService.instant(
      this.data.content,
      this.buildDataTranslateParams()
    );
  }
}
