import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailOverviewComponent } from './detail-overview.component';
import {
  GButtonModule,
  GEllipsisModule,
  GTypographyModule
} from '@gotadi/design-system';
import {MatButtonModule, MatDialogModule, MatIconModule} from '@angular/material';
import { DetailDialogModule } from '@gtd/hotels/ui/detail-dialog';
import { DetailLoaderOverviewComponent } from './detail-loader-overview/detail-loader-overview.component';
import { TourLoadingBoxModule } from '@gtd/extra/tour/ui/tour-loading-box';
import { MapViewDetailModule } from '@gtd/hotels/ui/map-view-detail';
import { AgmCoreModule } from '@agm/core';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        GButtonModule,
        MatIconModule,
        GTypographyModule,
        GEllipsisModule,
        MatDialogModule,
        DetailDialogModule,
        TourLoadingBoxModule,
        MapViewDetailModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCtgUEmt9xOzkgnBQsWM0tyNDooMrVgmFQ'
        }),
        TranslateModule,
      MatButtonModule
    ],
  declarations: [DetailOverviewComponent, DetailLoaderOverviewComponent],
  exports: [DetailOverviewComponent, DetailLoaderOverviewComponent]
})
export class DetailOverviewModule {}
