import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightCreateDraftBookingPartialState } from './flight-create-draft-booking.reducer';
import {
  LoadFlightCreateDraftBooking,
  FlightCreateDraftBookingLoaded,
  FlightCreateDraftBookingLoadError,
  FlightCreateDraftBookingActionTypes
} from './flight-create-draft-booking.actions';
import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class FlightCreateDraftBookingEffects {
  @Effect() loadFlightCreateDraftBooking$ = this.dataPersistence.fetch(
    FlightCreateDraftBookingActionTypes.LoadFlightCreateDraftBooking,
    {
      run: (
        action: LoadFlightCreateDraftBooking,
        state: FlightCreateDraftBookingPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this._airTicketsResourceService
          .createDraftBookingUsingPOST(action.payload)
          .pipe(
            map(payload => ({
              type:
                FlightCreateDraftBookingActionTypes.FlightCreateDraftBookingLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadFlightCreateDraftBooking, error) => {
        console.error('Error', error);
        return new FlightCreateDraftBookingLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      FlightCreateDraftBookingPartialState
    >,
    private _airTicketsResourceService: AirTicketsResourceService
  ) {}
}
