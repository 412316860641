import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import {
  DEFAULT_PAGINATION_OFFSET,
  DEFAULT_SORT_COMBO,
  encryptSearchAPI
} from '@gtd/helpers';
import { Observable, Subscription } from 'rxjs/Rx';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { FlightSearchFacade } from './flight-search/flight-search/+state/flight-search.facade';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AvailableFlightsFacade } from './flight-search/flight-cache-search/+state/available-flights.facade';
import { LowestPriceFlightsFacade } from './flight-search/flight-lowest-price/+state/lowest-price-flights.facade';
import { FlightFilterFacade } from './flight-search/flight-filter/+state/flight-filter.facade';
import { FlightFilterState } from './flight-search/flight-filter/+state/flight-filter.reducer';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  AirItineraryInfo,
  AirRevalidateRS,
  AirTicketsResourceService,
  GroupPricedItinerary,
  ItineraryInfoVM,
  TicketDraftBookingRS
} from '@gtd/b2c-client';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { MatDialog, MatSnackBar } from '@angular/material';
import { GroupItineraryFacade } from './flight-search/group-itinerary/+state/group-itinerary.facade';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '@env/environment';
import { BreadcrumbBar } from '@gtd/components/breadcrumb-bar';
import { SelectItemSrvService } from './select-item-srv.service';
import { SubscriptionService } from './flight-search/flight-search/+state/subscription.service';
import { BehaviorSubject } from 'rxjs';
import { ComboVJSearchInformationFacade } from '../../../state/combo-search-information/combo-search-information.facade';
import { FlightConfirmDialogComponent } from './flight-confirm-dialog/flight-confirm-dialog.component';
import { take } from 'rxjs/operators';
import { GaTrackingService } from '@gtd/ga-tracking';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';
import { TimerService } from '@gtd/services';

@Component({
  selector: 'gtd-flight-search-result',
  templateUrl: './flight-search-result.component.html',
  styleUrls: ['./flight-search-result.component.scss'],
  providers: [TimerService],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class FlightSearchResultComponent implements OnInit, OnDestroy {
  allAvailableFlights$ = this.availableFlightsFacade.allAvailableFlights$;
  loaded$ = this.availableFlightsFacade.loaded$;
  loadedFlightSearch$ = this.flightSearchFacade.loaded$;
  allFlightSearch$ = this.flightSearchFacade.allFlightSearch$;
  getErrorFlightSearch$ = this.flightSearchFacade.getErrorFlightSearch$;
  lowestPriceLoaded$ = this.lowestPriceFlightsFacade.loaded$;
  allLowestPriceFlight$ = this.flightSearchFacade.allFlightSearch$;
  currentFilter$ = this.flightFilterFacade.currentFilter$;
  lowestFlightPriceSubject = new BehaviorSubject<number>(null);
  lowestFlightPrice$ = this.lowestFlightPriceSubject.asObservable();
  formSearchResult?: FormGroup;
  loadingInternational: boolean;
  searchParams: any = {};
  navigationSubscription: any;
  isCreateBooking: boolean;
  appName = environment.appName;
  flightBooking;
  itemFlightSelect: number;
  flightParams;
  newTab: any = null;
  flightType: string;
  typeSelect: string;
  flightDirectionSubject$ = new BehaviorSubject<string>('D');
  flightDirection$ = this.flightDirectionSubject$.asObservable();
  flightInformationSubject$ = new BehaviorSubject<any>({});
  flightInformation$ = this.flightInformationSubject$.asObservable();
  flightAutoSelected$ = this._comboSelectedService.flightListSelected$;
  firstFlightBooking: any;
  flightBookingDone$ = this._comboSelectedService.flightSelectedDone$;
  groupId: string;
  params;
  searchParam: any;
  flightlistSelected: any;
  roomLength: number;
  hotelBooking$ = this._comboSelectedService.hotelSelected$;
  hotelBooking;
  roomBooking$ = this._comboSelectedService.roomSelected$;
  roomBooking;
  cancelFilter;
  private subscription: Subscription = new Subscription();
  isLoading: boolean;
  flightSelectedByUser = false;

  breadcrumbBars: BreadcrumbBar[] = [
    {
      name: 'global.homepage',
      isRouterLink: true,
      routerLink: '/'
    },
    {
      isRouterLink: false,
      name: 'global.flight'
    }
  ];
  resetPageSelected = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private flightSearchFacade: FlightSearchFacade,
    private activatedRoute: ActivatedRoute,
    private availableFlightsFacade: AvailableFlightsFacade,
    private lowestPriceFlightsFacade: LowestPriceFlightsFacade,
    private flightFilterFacade: FlightFilterFacade,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private airTicketsResourceService: AirTicketsResourceService,
    private groupItineraryFacade: GroupItineraryFacade,
    private _fb: FormBuilder,
    private deviceService: DeviceDetectorService,
    private selectItemSrvService: SelectItemSrvService,
    private subService: SubscriptionService,
    private comboSearchInformationFacade: ComboVJSearchInformationFacade,
    private _comboSelectedService: ComboSelectedService,
    private _gaTrackingService: GaTrackingService,
    private timerService: TimerService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.flightBooking = JSON.parse(localStorage.getItem('flightBooking'));

    this.dialog.closeAll();
    this.availableFlightsFacade.resetAll();
    // if (
    //   this.flightBooking &&
    //   this.flightBooking.from &&
    //   this.flightBooking.resetFlight
    // )
    //   this.flightDirectionSubject$.next('R');
  }

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

    this.navigationSubscription = this.router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          this.router.navigated = false;
          window.scrollTo(0, 0);
        }
      }
    });
    this.createForm();
    this.timerService.start();
    this.subscription.add(
      this.hotelBooking$.take(1).subscribe(hotelBooking => {
        this.hotelBooking = hotelBooking;
      })
    );
    this.subscription.add(
      this.roomBooking$.take(1).subscribe(roomBooking => {
        this.roomBooking = roomBooking;
      })
    );
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe(param => {
        this.params = param;
        this.flightSelectedByUser = param.flightSelected;
        this.roomLength = JSON.parse(
          JSON.parse(decodeURIComponent(atob(param.params))).rooms
        ).length;
        this._handleQueryFromParams(
          JSON.parse(decodeURIComponent(atob(param.params)))
        );
      })
    );
    this.subscription.add(
      this.allAvailableFlights$.subscribe(data => {
        if (data && data.page && data.page.pageNumber === 0) {
          this.flightInformationSubject$.next(data.groupPricedItineraries[0]);
          this.comboSearchInformationFacade.addSearchInformation(
            data.groupPricedItineraries[0]
          );
          if (
            data.groupPricedItineraries &&
            data.groupPricedItineraries.length &&
            data.groupPricedItineraries[0].pricedItineraries &&
            data.groupPricedItineraries[0].pricedItineraries.length
          ) {
            this.lowestFlightPriceSubject.next(
              data.groupPricedItineraries[0].pricedItineraries[0]
                .airItineraryPricingInfo.adultFare.passengerFare.totalFare
                .amount
            );
          }
        }
      })
    );
    this.subscription.add(
      this.loaded$.subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          this.document.documentElement.scrollTo({
            top: 0
          });
        }
      })
    );
    this.subscription.add(
      this.allFlightSearch$.pipe(take(1)).subscribe(flightSearch => {
        if (Object.keys(flightSearch).length === 0) {
          this.isLoading = true;
          this.flightSearchFacade.loadAll(this.flightParams);
        }
      })
    );
    // this.subscription.add(
    //   this.lowestPriceFlightsFacade.allLowestPriceFlights$.subscribe(
    //     allLowestPriceFlights => {
    //       this.selectItemSrvService.unsetIsLoaded(-1);
    //       if (
    //         allLowestPriceFlights &&
    //         Object.keys(allLowestPriceFlights).length > 0
    //       ) {
    //         if (!allLowestPriceFlights.searchId) {
    //           this.availableFlightsFacade.resetAll();
    //         }
    //       }
    //     }
    //   )
    // );

    this.subscription.add(
      this.flightAutoSelected$.subscribe(listSelected => {
        this.flightlistSelected = listSelected;
        if (
          listSelected &&
          listSelected[0] &&
          listSelected.length === 1 &&
          this.searchParams.roundType === 'ROUNDTRIP' &&
          !this.cancelFilter
        ) {
          this.flightDirectionSubject$.next('R');
          this.resetPageSelected = this.resetPageSelected + 1;
          //this.flightFilterFacade.resetFilterRoundTrip(
          //  listSelected[0].searchId,
          //  listSelected[0].itemFlight.flightType
          //);
          if (
            listSelected[0].itemFlight.flightType ===
            GroupPricedItinerary.FlightTypeEnum.DOMESTIC
          ) {
            // this..resetFilter();
            this._listenLowFareSearch2({
              groupId: listSelected[0].itemFlight.groupId,
              airlineCode: listSelected[0].itemFlight.airline,
              fareSourceCode:
                listSelected[0].pricedItinerary.airItineraryPricingInfo
                  .fareSourceCode,
              supplierCode: listSelected[0].itemFlight.airSupplier,
              searchId: listSelected[0].searchId
            });
          } else {
            this.flightFilterFacade.resetFilterRoundTrip(
              listSelected[0].searchId,
              listSelected[0].itemFlight.flightType,
              {
                groupId: listSelected[0].itemFlight.groupId,
                airlineCode: listSelected[0].itemFlight.airline,
                fareSourceCode:
                  listSelected[0].pricedItinerary.airItineraryPricingInfo
                    .fareSourceCode,
                supplierCode: listSelected[0].itemFlight.airSupplier,
                searchId: listSelected[0].searchId
              }
            );
            this._listenLowFareSearchInter({
              groupId: listSelected[0].itemFlight.groupId,
              airlineCode: listSelected[0].itemFlight.airline,
              fareSourceCode:
                listSelected[0].pricedItinerary.airItineraryPricingInfo
                  .fareSourceCode,
              supplierCode: listSelected[0].itemFlight.airSupplier,
              searchId: listSelected[0].searchId
            });
          }
        }
      })
    );
    this.subscription.add(
      this.flightBookingDone$.subscribe(done => {
        if (done) {
          const dialogRef = this.dialog.open(FlightConfirmDialogComponent, {
            data: {
              btn: [{ btnText: 'global.close' }],
              messageType: 'success'
              // content: 'booking-result.message-error-validate-air-fare',
              // heading: 'booking-result.validate-air-fare-error',
              // icon: 'exclamation-triangle',
              // messageType: 'error',
            },
            position: {
              right: '0',
              top: '0'
            },
            panelClass: [
              'animate__animated',
              'animate__slideInRight',
              'animate__faster',
              'full-height'
            ],
            disableClose: true,
            autoFocus: false
          });
          dialogRef.afterClosed().subscribe(action => {
            if (action === 'confirm') {
              this._snackBar.openFromComponent(SnackbarComponent, {
                data: {
                  message: 'snack-bar.initializing-journey',
                  loading: true
                },
                panelClass: 'snackbar-loading',
                verticalPosition: 'top'
              });
              this.airTicketsResourceService
                .revalidateAirFareUsingPOST({
                  fareSourceCode: this.flightlistSelected[0].pricedItinerary
                    .airItineraryPricingInfo.fareSourceCode,
                  groupId: this.flightlistSelected[0].itemFlight.groupId,
                  searchId: this.flightlistSelected[0].searchId
                })
                .subscribe((result: AirRevalidateRS) => {
                  if (result.valid) {
                    if (this.searchParams.routeType === 'ROUNDTRIP') {
                      this.airTicketsResourceService
                        .revalidateAirFareUsingPOST({
                          fareSourceCode: this.flightlistSelected[1]
                            .pricedItinerary.airItineraryPricingInfo
                            .fareSourceCode,
                          groupId: this.flightlistSelected[1].itemFlight
                            .groupId,
                          searchId: this.flightlistSelected[1].searchId
                        })
                        .subscribe(revalidateAirFare => {
                          if (revalidateAirFare.valid) {
                            this._comboSelectedService.saveFlightLocalStorage();
                            this._snackBar.dismiss();
                            this.directLinkSelected();
                            this._comboSelectedService.saveFlightLocalStorage();
                          }
                        });
                    } else {
                      this._comboSelectedService.saveFlightLocalStorage();
                      this._snackBar.dismiss();
                      this.directLinkSelected();
                    }
                  } else {
                    this._gaTrackingService.track('F_SelectTicket_Failed');
                    this.dialog.open(DialogMessageComponent, {
                      data: {
                        btn: [
                          {
                            btnText: 'booking-result.reload',
                            btnReload: true
                          }
                        ],
                        content:
                          'booking-result.message-error-validate-air-fare',
                        heading: 'booking-result.validate-air-fare-error',
                        icon: 'exclamation-triangle',
                        messageType: 'error'
                      },
                      panelClass: 'dialog-message',
                      disableClose: true
                    });
                  }
                  // this.isCreateBooking = true;
                });
            } else {
              this.cancelFilter = true;
              this._comboSelectedService.removeFlight(
                this.searchParams.roundType === 'ROUNDTRIP' ? 'to' : 'from',
                this.flightlistSelected[this.flightlistSelected.length - 1]
                  .pricedItinerary.airItineraryPricingInfo.itinTotalFare
                  .totalFare.amount
              );
            }
          });
        }
      })
    );

    this.subscription.add(
      this.allFlightSearch$.subscribe(data => {
        if (
          Object.keys(data).length > 0 &&
          this.flightlistSelected.length === 0
        ) {
          this.isLoading = false;
          if (
            data.groupPricedItineraries &&
            data.groupPricedItineraries.length > 0
          ) {
            this.flightFilterFacade.changeSearchID(data.searchId);
            this.lowestPriceFlightsFacade.loadAll({
              searchId: data.searchId,
              departureItinerary: null
            });
          }
        }
      })
    );
    this.subscription.add(
      this.currentFilter$.subscribe((value: FlightFilterState) => {
        if (value.searchID) {
          let filters = {
            filter: {
              searchId: value.searchID,
              filter: {
                cabinClassOptions:
                  value.cabinClassOptions && value.cabinClassOptions.length > 0
                    ? value.cabinClassOptions
                    : value.cabinClassOptions,
                ticketPolicyOptions: value.ticketPolicyOptions,
                airlineOptions: value.airlineOptions,
                stopOptions: value.stopOptions,
                step: value.departureItinerary ? '2' : value.step,
                flightType: this.flightType || value.flightType,
                departureDateTimeOptions: value.departureDateTimeOptions,
                arrivalDateTimeReturnOptions:
                  value.arrivalDateTimeReturnOptions,
                arrivalDateTimeOptions: value.arrivalDateTimeOptions,
                priceItineraryId: value.priceItineraryId,
                minPrice: value.minPrice,
                filterFromPrice: value.filterFromPrice,
                filterToPrice: value.filterToPrice,
                loadMore: value.loadMore,
                groupId: value.groupId,
                departureDateTimeReturnOptions:
                  value.departureDateTimeReturnOptions
              },
              departureItinerary: value.departureItinerary
            },
            includeEquivfare: false,
            page: value.page,
            size: value.size,
            sort: value.sort ? value.sort : DEFAULT_SORT_COMBO
          };
          if (
            this.flightType !== 'INTERNATIONAL' ||
            !value.departureItinerary ||
            this.typeSelect === 'from'
          ) {
            if (this.flightType === 'INTERNATIONAL') {
              filters.filter.departureItinerary = null;
              filters.filter.filter.step = '1';
            }
            this.availableFlightsFacade.loadAll(filters);
            this.typeSelect = null;
          } else {
            if (value.departureItinerary.groupId) {
              setTimeout(() => {
                this.availableFlightsFacade.loadInternationalFlights({
                  id: value.departureItinerary.groupId,
                  airSearchId: {
                    searchId: value.searchID,
                    filter: {
                      airlineOptions: value.airlineOptions,
                      cabinClassOptions: value.cabinClassOptions,
                      arrivalDateTimeOptions: value.arrivalDateTimeOptions,
                      departureDateTimeReturnOptions:
                        value.departureDateTimeOptions,
                      flightType: this.flightType,
                      groupId: value.departureItinerary.groupId,
                      step: '2',
                      loadMore: false,
                      stopOptions: value.stopOptions,
                      ticketPolicyOptions: value.ticketPolicyOptions
                    },
                    departureItinerary: value.departureItinerary
                  },
                  sort: ['stopOptions,asc']
                });
              }, 100);
            }
          }
        }
      })
    );
  }

  private _listenLowFareSearchInter(
    departureItinerary?: AirItineraryInfo,
    reset?: boolean
  ) {
    this.subscription.add(
      this.allFlightSearch$.pipe(take(1)).subscribe(data => {
        if (Object.keys(data).length > 0 && data.departureSearchId) {
          this.lowestPriceFlightsFacade.loadAll({
            searchId: departureItinerary.searchId,
            departureItinerary: departureItinerary
          });
          this.availableFlightsFacade.loadInternationalFlights({
            id: departureItinerary.groupId,
            airSearchId: {
              searchId: departureItinerary.searchId,
              filter: {
                arrivalDateTimeOptions: [],
                departureDateTimeOptions: [],
                flightType: 'INTERNATIONAL',
                groupId: departureItinerary.groupId,
                step: '2',
                stopOptions: [],
                ticketPolicyOptions: []
              },
              departureItinerary: {
                fareSourceCode: departureItinerary.fareSourceCode
              }
            },
            sort: ['stopOptions,asc']
          });
        }
      })
    );
  }
  directLinkSelected() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.location) {
        this.router.navigate([JSON.parse(params.location)], {
          queryParams: {
            from: JSON.parse(localStorage.getItem('comboContentSearch')).from,
            to: JSON.parse(localStorage.getItem('comboContentSearch')).to,
            fromDate: JSON.parse(localStorage.getItem('comboContentSearch'))
              .fromDate,
            toDate: JSON.parse(localStorage.getItem('comboContentSearch'))
              .toDate,
            adult: JSON.parse(localStorage.getItem('comboContentSearch')).adult,
            child: JSON.parse(localStorage.getItem('comboContentSearch')).child,
            infant: JSON.parse(localStorage.getItem('comboContentSearch'))
              .infant
          }
        });
      } else {
        if (this.hotelBooking && this.roomBooking) {
          this.router.navigate(['/combo/confirm'], {
            queryParams: {
              params: this.params.params,
              hotel: this.params.hotel
            }
          });
        } else if (this.hotelBooking) {
          this.router.navigate(['/combo/hotel/detail'], {
            queryParams: {
              params: this.params.params
            }
          });
        } else {
          this.router.navigate(['/combo/hotel'], {
            queryParams: {
              params: this.params.params,
              flightSelected: true
            }
          });
        }
      }
    });
  }
  private createForm() {
    const flightBooking = JSON.parse(localStorage.getItem('flightBooking'));
    this.formSearchResult = this._fb.group({
      listSelected: new FormControl(null)
    });
    if (flightBooking && flightBooking.resetFlight) {
      this.formSearchResult.get('listSelected').patchValue(flightBooking);
    }
  }

  listenSelectFlight(event) {
    this.cancelFilter = false;
    this._comboSelectedService.selectFlight(
      event.searchId,
      event.pricedItinerary,
      event.groupPricedItineraries,
      event.roundType
    );
  }
  createDraftBooking(itineraryInfos: Array<ItineraryInfoVM>) {
    this.airTicketsResourceService
      .createDraftBookingUsingPOST({
        itineraryInfos: itineraryInfos
      })
      .subscribe(
        (draftBooking: TicketDraftBookingRS) => {
          if (draftBooking.success) {
            this.selectItemSrvService.unsetIsLoaded(-1);
            if (isPlatformBrowser(this.platformId)) {
              window.parent.postMessage({ action: 'scrollToTop' }, '*');
            }
            this._snackBar.dismiss();
            let url = this.router.serializeUrl(
              this.router.createUrlTree([
                `/checkout/flight?bookingNumber=${
                  draftBooking.bookingCode.bookingNumber
                }`
              ])
            );

            if (this.appName === 'B2B2C') {
              let newWindow = window.open('', '_self');
              newWindow.location.href = decodeURIComponent(url);
            } else {
              if (this.deviceService.isDesktop() === true) {
                this.newTab.location.href = decodeURIComponent(url);
              } else {
                this.router
                  .navigate(['/checkout/flight'], {
                    queryParams: {
                      bookingNumber: draftBooking.bookingCode.bookingNumber
                    }
                  })
                  .then(() => {});
              }
            }
          } else {
            if (this.newTab !== null) {
              this.newTab.close();
            }
            this._snackBar.dismiss();
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'booking-result.reload', btnReload: true }],
                content: 'booking-result.message-error-validate-air-fare',
                heading: 'booking-result.validate-air-fare-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
          this.isCreateBooking = false;
        },
        () => {
          if (this.newTab !== null) {
            this.newTab.close();
          }
          this._snackBar.dismiss();
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [{ btnText: 'booking-result.reload', btnReload: true }],
              content: 'booking-result.message-error-validate-air-fare',
              heading: 'booking-result.validate-air-fare-error',
              icon: 'exclamation-triangle',
              messageType: 'error'
            },
            panelClass: 'dialog-message',
            disableClose: true
          });
        }
      );
  }

  private _handleQueryFromParams(param) {
    this.searchParams = param;
    this.flightParams = {
      adutsQtt: param.adult,
      cabinClass: param.filterCabinClass || 'E',
      childrenQtt: param.child,
      departureDate: param.fromDate,
      destinationCode: param.to,
      infantsQtt: param.infant,
      originCode: param.from,
      combo: true,
      page: 0,
      returntureDate: param.toDate ? param.toDate : '',
      routeType: param.roundType,
      size: DEFAULT_PAGINATION_OFFSET,
      time: new Date().getTime().toString()
    };
    this.flightType = param.flightType;
    this.typeSelect = param.typeSelect;
    this.flightParams['key'] = encryptSearchAPI(this.flightParams);
    if (!this._comboSelectedService.flightReset) {
      this.router.navigate(['/combo/hotel'], {
        queryParams: {
          params: this.params.params
        }
      });
    }
  }

  changeDepartureFlight(event: any) {
    this.resetPageSelected = this.resetPageSelected + 1;
    if (this.flightType !== 'INTERNATIONAL') {
      this._listenLowFareSearch2(null, true);
      this.flightDirectionSubject$.next('D');
      this._comboSelectedService.removeFlight(
        event.type,
        event.formSearchResult.pricedItinerary.airItineraryPricingInfo
          .itinTotalFare.totalFare.amount
      );
    }

    // localStorage.removeItem('flightBooking');
    // this.formSearchResult.reset();
  }
  private _listenLowFareSearch2(
    departureItinerary?: AirItineraryInfo,
    reset?: boolean
  ) {
    this.subscription.add(
      this.allFlightSearch$.subscribe(data => {
        if (Object.keys(data).length > 0) {
          // this.flightFilterFacade.changePage(0);f
          this.flightFilterFacade.selectDeparture({
            searchID: reset ? data.searchId : data.returnSearchId,
            departureItinerary: departureItinerary
          });
          this.lowestPriceFlightsFacade.loadAll({
            searchId: data.returnSearchId,
            departureItinerary: departureItinerary
          });
        }
      })
    );
  }

  getNewTab(newTab: any) {
    this.newTab = newTab;
  }

  ngOnDestroy(): void {
    this.timerService.stop();
    this.subscription.unsubscribe();
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    this.flightDirectionSubject$.unsubscribe();
    this.flightInformationSubject$.unsubscribe();
    this.subService.unsubscribeSubject$.next();
    // this.flightSearchFacade.resetAll();
    this.availableFlightsFacade.resetAll();
    this.lowestPriceFlightsFacade.resetAll();
    this.flightFilterFacade.resetFilter();
  }
}
