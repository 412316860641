import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelBookingPartialState } from './hotel-booking.reducer';
import {
  LoadHotelBooking,
  HotelBookingLoaded,
  HotelBookingLoadError,
  HotelBookingActionTypes
} from './hotel-booking.actions';

@Injectable()
export class HotelBookingEffects {
  @Effect() loadHotelBooking$ = this.dataPersistence.fetch(
    HotelBookingActionTypes.LoadHotelBooking,
    {
      run: (action: LoadHotelBooking, state: HotelBookingPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return new HotelBookingLoaded(action.payload);
      },

      onError: (action: LoadHotelBooking, error) => {
        console.error('Error', error);
        return new HotelBookingLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<HotelBookingPartialState>
  ) {}
}
