import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform
} from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Router, UrlSerializer } from '@angular/router';
import { PropertyAvailable } from '@gtd/b2c-client';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { ComboSelectedService } from '../../../../combo-selected.service';

@Component({
  selector: 'gtd-hotel-search-result-item',
  templateUrl: './hotel-search-result-item.component.html',
  styleUrls: ['./hotel-search-result-item.component.scss']
})
export class HotelSearchResultItemComponent
  implements OnInit, AfterContentChecked {
  @Input() hotelItems: PropertyAvailable;
  @Input() indexItem: number;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();
  @Input() searchParams: any;
  @Input() searchId: string;
  @Input() index: number;
  @Input() flightTicketPrice: number;
  @Input() adultTicketPrice: number;
  @Input() flightTicketDone = false;
  counter = Array;
  config: SwiperConfigInterface;
  priceOneNightPerOneRoom: number;
  priceOneNightPerOneRoomBeforePromo: number;
  priceOnePerson: number;
  numberNight: number;
  targetHref: string;

  utm_source = localStorage.getItem('utm_source')

  constructor(
    private cdref: ChangeDetectorRef,
    private serializer: UrlSerializer,
    private router: Router,
    private _comboSelectedService: ComboSelectedService
  ) {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 10,
      lazy: true,
      pagination: true
    };
  }

  ngOnInit() {
    this.countPriceOnePerson();
    this.cdref.detectChanges();
    this.numberNight = this.countNumberDate(
      moment(this.searchParams.fromDate, 'MM-DD-YYYY').format('YYYY-MM-DD'),
      moment(this.searchParams.toDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
    );
    // if (environment.appName === 'B2B2C') {
    //   this.targetHref = '_self';
    // } else {
    //   this.targetHref = '_blank';
    // }
  }

  viewDetailItem(value, data) {
    let params = {
      locationCode: data.searchParams.location.name,
      locationName: data.searchParams.location.name,
      searchType: data.searchParams.location.searchType,
      roomsString: data.searchParams.roomsString,
      checkIn: data.searchParams.fromDate,
      checkOut: data.searchParams.toDate,
      searchId: data.searchId,
      propertyId: value.propertyId,
      supplier: value.supplier,
      paxInfos: data.searchParams.rooms
    };
    this.searchParams = {
      ...this.searchParams,
      searchId: data.searchId,
      propertyId: value.propertyId,
      location: {
        ...this.searchParams.location,
        supplier: value.supplier
      }
    };

    this._comboSelectedService.selectHotel(this.hotelItems);
    localStorage.setItem('hotelBooking', JSON.stringify(this.hotelItems));
    this.router.navigate(['/combo/hotel/detail'], {
      queryParams: {
        params: btoa(encodeURIComponent(JSON.stringify(this.searchParams)))
      }
    });
  }

  addressClickViewMap(index, latitude, longitude, label) {
    this.changed.emit({
      index: index,
      latitude: latitude,
      longitude: longitude,
      label: label
    });
  }

  countPriceOnePerson() {
    const numberPerson = this.searchParams.roomsArrs.reduce((arr, cur) => {
      return arr + cur.adultQuantity + cur.infantCount + cur.childQuantity;
    }, 0);
    this.priceOnePerson = Math.ceil(this.hotelItems.totalPrice / numberPerson);
  }

  countPriceOneNightPerOneRoom() {
    const numberRoom = this.searchParams.roomsArrs.length;
    const numberNight = this.countNumberDate(
      moment(this.searchParams.fromDate).format('YYYY-MM-DD'),
      moment(this.searchParams.toDate).format('YYYY-MM-DD')
    );
    this.priceOneNightPerOneRoom =
      Math.ceil(this.hotelItems.basePrice / (numberRoom * numberNight * 1000)) *
      1000;
    this.priceOneNightPerOneRoomBeforePromo =
      Math.ceil(
        this.hotelItems.basePriceBeforePromo / (numberRoom * numberNight * 1000)
      ) * 1000;
  }

  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }

  countNumberDate(departureDate: string, returnDate?: string): number {
    let numbDate;
    if (departureDate && returnDate) {
      numbDate = this.datediff(
        this.parseDate(departureDate),
        this.parseDate(returnDate)
      );
    }
    return numbDate;
  }
  parseDate(str) {
    let mdy = str.split('-');
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }
  datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }
}

@Pipe({
  name: 'generateLink'
})
export class GenerateLink implements PipeTransform {
  transform(
    value: PropertyAvailable,
    data?: { searchParams: any; searchId: string }
  ): string {
    let params = {
      locationCode: data.searchParams.location.name,
      locationName: data.searchParams.location.name,
      searchType: data.searchParams.location.searchType,
      roomsString: data.searchParams.roomsString,
      checkIn: data.searchParams.fromDate,
      checkOut: data.searchParams.toDate,
      searchId: data.searchId,
      propertyId: value.propertyId,
      supplier: value.supplier,
      paxInfos: data.searchParams.rooms
    };
    return (
      '/combo/hotel/detail?params=' +
      btoa(encodeURIComponent(JSON.stringify(params)))
    );
  }
}
@Pipe({
  name: 'roundReviewScore'
})
export class RoundReviewScorePipe implements PipeTransform {
  transform(value: string): string {
    const numericValue = Number(value);
    const roundedValue = Math.round(numericValue * 10) / 10;
    if (Number.isInteger(roundedValue)) {
      return roundedValue.toFixed(0);  // Return as integer
    }
    return roundedValue.toFixed(1);
  }

}
