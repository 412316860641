import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTSEARCHLOWFARE_FEATURE_KEY,
  FlightSearchLowFareState
} from './flight-search-low-fare.reducer';

// Lookup the 'FlightSearchLowFare' feature state managed by NgRx
const getFlightSearchLowFareState = createFeatureSelector<
  FlightSearchLowFareState
>(FLIGHTSEARCHLOWFARE_FEATURE_KEY);

const getLoaded = createSelector(
  getFlightSearchLowFareState,
  (state: FlightSearchLowFareState) => state.loaded
);
const getError = createSelector(
  getFlightSearchLowFareState,
  (state: FlightSearchLowFareState) => state.error
);

const getAllFlightSearchLowFare = createSelector(
  getFlightSearchLowFareState,
  getLoaded,
  (state: FlightSearchLowFareState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getFlightSearchLowFareState,
  (state: FlightSearchLowFareState) => state.selectedId
);
const getSelectedFlightSearchLowFare = createSelector(
  getAllFlightSearchLowFare,
  getSelectedId,
  (flightSearchLowFare, id) => {
    const result = flightSearchLowFare;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const flightSearchLowFareQuery = {
  getLoaded,
  getError,
  getAllFlightSearchLowFare,
  getSelectedFlightSearchLowFare
};
