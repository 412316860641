import {Injectable} from '@angular/core';
import {Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {HotelSearchAllRatesPartialState} from './hotel-search-all-rates.reducer';
import {
  HotelSearchAllRatesActionTypes,
  HotelSearchAllRatesLoadError,
  LoadHotelSearchAllRates
} from './hotel-search-all-rates.actions';
import {HotelResourceV3Service} from '@gtd/b2c-client';
import {map} from 'rxjs/operators';

@Injectable()
export class HotelSearchAllRatesEffects {
  @Effect() loadHotelSearchAllRates$ = this.dataPersistence.fetch(
    HotelSearchAllRatesActionTypes.LoadHotelSearchAllRates,
    {
      run: (
        action: LoadHotelSearchAllRates,
        state: HotelSearchAllRatesPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        this._hotelResourceV3Service.defaultHeaders = this._hotelResourceV3Service.defaultHeaders.set(
          'Access-Control-Allow-Origin',
          '*'
        );

        return this._hotelResourceV3Service
          .searchAllRatesUsingShortLinkGET(
            action.payload.propertyId,
            action.payload.supplier,
            action.payload.shortLinkId,
            action.payload.masterPropertyId,
          )
          .pipe(
            map(payload => ({
              type: HotelSearchAllRatesActionTypes.HotelSearchAllRatesLoaded,
              payload: payload.result
            }))
          );
      },

      onError: (action: LoadHotelSearchAllRates, error) => {
        console.error('Error', error);
        return new HotelSearchAllRatesLoadError(error);
      }
    }
  );

  constructor(
    private dataPersistence: DataPersistence<HotelSearchAllRatesPartialState>,
    private _hotelResourceV3Service: HotelResourceV3Service
  ) {}
}
