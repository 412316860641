import {
  CheckoutPayload,
  CheckoutResult,
  OtaResultOfCheckoutResult
} from '@gtd/b2c-client';
import {
  HotelCheckoutAction,
  HotelCheckoutActionTypes
} from './hotel-checkout.actions';

export const HOTELCHECKOUT_FEATURE_KEY = 'hotelCheckoutRoom';

/**
 * Interface for the 'HotelCheckout' data used in
 *  - HotelCheckoutState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelCheckoutState {
  list: OtaResultOfCheckoutResult; // list of HotelCheckout; analogous to a sql normalized table
  selectedId?: string | number; // which HotelCheckout record has been selected
  loaded: boolean; // has the HotelCheckout list been loaded
  error?: any; // last none error (if any)
}

export interface HotelCheckoutPartialState {
  readonly [HOTELCHECKOUT_FEATURE_KEY]: HotelCheckoutState;
}

export const initialState: HotelCheckoutState = {
  list: null,
  loaded: false
};

export function reducer(
  state: HotelCheckoutState = initialState,
  action: HotelCheckoutAction
): HotelCheckoutState {
  switch (action.type) {
    case HotelCheckoutActionTypes.HotelCheckoutLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }

    case HotelCheckoutActionTypes.ResetHotelCheckout: {
      state = initialState;
      break;
    }

    case HotelCheckoutActionTypes.HotelCheckoutLoadError: {
      state = {
        ...state,
        list: action.payload,
        loaded: true,
        error: action.payload
      };
    }
  }
  return state;
}
