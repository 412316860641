import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightAddBookingTravellerPartialState } from './flight-add-booking-traveller.reducer';
import { flightAddBookingTravellerQuery } from './flight-add-booking-traveller.selectors';
import { LoadFlightAddBookingTraveller } from './flight-add-booking-traveller.actions';

@Injectable()
export class FlightAddBookingTravellerFacade {
  loaded$ = this.store.pipe(select(flightAddBookingTravellerQuery.getLoaded));
  allFlightAddBookingTraveller$ = this.store.pipe(
    select(flightAddBookingTravellerQuery.getAllFlightAddBookingTraveller)
  );
  selectedFlightAddBookingTraveller$ = this.store.pipe(
    select(flightAddBookingTravellerQuery.getSelectedFlightAddBookingTraveller)
  );

  constructor(private store: Store<FlightAddBookingTravellerPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadFlightAddBookingTraveller());
  }
}
