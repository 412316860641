import { Action } from '@ngrx/store';
import { Entity } from './hotel-search-best-rates.reducer';
import { SearchBestRatesResult } from '@gtd/b2c-client';

export enum HotelSearchBestRatesActionTypes {
  LoadHotelSearchBestRates = '[HotelSearchBestRates] Load HotelSearchBestRates',
  ResetHotelSearchBestRates = '[HotelSearchBestRates] Reset HotelSearchBestRates',
  HotelSearchBestRatesLoaded = '[HotelSearchBestRates] HotelSearchBestRates Loaded',
  HotelSearchBestRatesLoadError = '[HotelSearchBestRates] HotelSearchBestRates Load Error'
}

export class LoadHotelSearchBestRates implements Action {
  readonly type = HotelSearchBestRatesActionTypes.LoadHotelSearchBestRates;
  constructor(public payload: HotelPayload, public loadMore?: boolean) {}
}

export class ResetHotelSearchBestRates implements Action {
  readonly type = HotelSearchBestRatesActionTypes.ResetHotelSearchBestRates;
}

export class HotelSearchBestRatesLoadError implements Action {
  readonly type = HotelSearchBestRatesActionTypes.HotelSearchBestRatesLoadError;
  constructor(public payload: any) {}
}

export class HotelSearchBestRatesLoaded implements Action {
  readonly type = HotelSearchBestRatesActionTypes.HotelSearchBestRatesLoaded;
  constructor(public payload: SearchBestRatesResult, public loadMore?: boolean) {}
}

export type HotelSearchBestRatesAction =
  | LoadHotelSearchBestRates
  | ResetHotelSearchBestRates
  | HotelSearchBestRatesLoaded
  | HotelSearchBestRatesLoadError;

export const fromHotelSearchBestRatesActions = {
  LoadHotelSearchBestRates,
  ResetHotelSearchBestRates,
  HotelSearchBestRatesLoaded,
  HotelSearchBestRatesLoadError
};

export interface HotelPayload {
  searchCode: string;
  locationName?: string;
  searchType:
    | 'AUTO'
    | 'CONTINENT'
    | 'COUNTRY'
    | 'PROVINCE_STATE'
    | 'HIGH_LEVEL_REGION'
    | 'MULTI_CITY_VICINITY'
    | 'CITY'
    | 'NEIGHBORHOOD'
    | 'AIRPORT'
    | 'POINT_OF_INTEREST'
    | 'TRAIN_STATION'
    | 'METRO_STATION'
    | 'HOTEL';
  language: string;
  currency: 'VND' | 'USD';
  checkIn: string;
  checkOut: string;
  paxInfos: Array<string>;
  searchId?: string;
  supplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL';
  targetSupplier?: 'EXPEDIA' | 'AXISROOM' | 'BEDLINKER' | 'AGODA' | 'VINPEARL';
  filterHotelName?: string;
  filterHotelCategories?: Array<string>;
  filterBreakfastIncluded?: boolean;
  filterFromPrice?: number;
  filterToPrice?: number;
  filterFromDistance?: number;
  filterToDistance?: number;
  filterStarRating?: Array<string>;
  filterFromGuestRating?: number;
  filterToGuestRating?: number;
  filterPropertyAmenities?: Array<string>;
  filterRoomAmenities?: Array<string>;
  filterRoomViews?: Array<string>;
  filterThemes?: Array<string>;
  filterMealPlans?: Array<string>;
  filterRateAmenities?: Array<string>;
  filterBedTypes?: Array<string>;
  filterGeoDistanceLat?: number;
  filterGeoDistanceLon?: number;
  filterGeoDistanceMeters?: number;
  sortField?: 'order' | 'price' | 'starRating' | 'guestRating';
  sortOrder?: 'ASC' | 'DESC';
  pageNumber?: number;
  pageSize?: number;
}
