import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MilesFunctionService } from '@gtd/hotels/data-access/services';

@Component({
  selector: 'gtd-cash-and-miles',
  templateUrl: './cash-and-miles.component.html',
  styleUrls: ['./cash-and-miles.component.scss']
})
export class CashAndMilesComponent implements OnInit, OnChanges {
  @Input() exchangeRate = 0.5;
  @Input() totalPrice: number;
  @Input() value = 0;
  @Input() showNote: boolean;
  @Output() valueChange = new EventEmitter();
  rangeOptions: any;
  milesValue = 0;
  cashValue = 0;
  constructor(private _milesFunctionService: MilesFunctionService) {}

  ngOnInit() {
    this.rangeOptions = this.makeRangeOptions(0, 100);
    this.cashValue = this.totalPrice;
  }
  onChange(event) {
    this.milesValue = this._milesFunctionService.caculateMiles(
      event.value,
      this.exchangeRate,
      this.totalPrice
    );
    this.cashValue = this._milesFunctionService.caculateCash(
      event.value,
      this.totalPrice
    );
    this.valueChange.emit(event.value);
  }

  makeRangeOptions(min: number, max: number) {
    return {
      animate: false, // Easier to see the single update
      floor: min,
      ceil: max,
      hideLimitLabels: true,
      hidePointerLabels: true,
      draggableRange: false
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { value } = changes;
    if (value && value.currentValue) {
      this.milesValue = this._milesFunctionService.caculateMiles(
        value.currentValue,
        this.exchangeRate,
        this.totalPrice
      );

      this.cashValue = this._milesFunctionService.caculateCash(
        value.currentValue,
        this.totalPrice
      );
    }
  }
}
