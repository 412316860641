import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {MatDialog} from '@angular/material';
import {PopupInfoItemRoomComponent} from './popup-info-item-room/popup-info-item-room.component';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {PropertyRoom} from '@gtd/b2c-client';
import {ComboSelectedService} from '../../../../../combo-selected.service';

@Component({
  selector: 'gtd-room-detail-item',
  templateUrl: './room-detail-item.component.html',
  styleUrls: ['./room-detail-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoomDetailItemComponent),
      multi: true
    }
  ]
})
export class RoomDetailItemComponent implements OnInit, ControlValueAccessor {
  @Input() roomItem: PropertyRoom;
  @Input() tripId: string;
  @Input() roomType: string;
  @Input() hotelName: string;
  @Input() hotelAddress?: string;
  @Input() roomStar: any;
  @Input() isLoading: boolean;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onNewTab = new EventEmitter<any>();

  config: SwiperConfigInterface;
  isCollapsed: boolean;

  roomItemFormGroup: FormGroup;

  constructor(
    public dialog: MatDialog,
    private _comboSelectedService: ComboSelectedService
  ) {
    this.roomItemFormGroup = new FormGroup({
      ratePlanId: new FormControl('', Validators.required)
    });
    this.config = {
      slidesPerView: 1,
      spaceBetween: 30,
      lazy: true,
      navigation: false,
      pagination: true
    };
  }

  ngOnInit() {
    this.roomItemFormGroup.valueChanges.subscribe(valueChange => {
      if (valueChange && valueChange.ratePlanId) {
        this.onChange({
          ratePlanId: valueChange.ratePlanId.ratePlanId,
          tripId: this.tripId,
          roomId: this.roomItem.id,
          roomName: this.roomItem.name,
          roomType: this.roomType,
          star: this.roomStar
        });
        this.onTouched();
        this._comboSelectedService.selectRoom({
          room: this.roomItem,
          ratePlan: valueChange.ratePlanId
        });
        localStorage.setItem(
          'roomBooking',
          JSON.stringify({
            room: this.roomItem,
            ratePlan: valueChange.ratePlanId
          })
        );
        // this.onNewTab.emit(window.open('/checkout/combo'));
      }
    });
  }

  openDialogGallery(
    images: Array<any>,
    index: number,
    descriptions: Array<any>,
    contents: Array<any>,
    title: string
  ) {
    this.dialog.open(PopupInfoItemRoomComponent, {
      data: {
        images: images,
        index: index,
        descriptions: descriptions,
        contents: contents,
        title: title
      },
      position: {
        top: '0px',
        left: '0px'
      },
      panelClass: 'popupGallery'
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  writeValue(obj: any): void {}
}
