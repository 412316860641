export * from './simApiResource.service';
import { SimApiResourceService } from './simApiResource.service';
export * from './apiDelayInsurance.service';
import { ApiDelayInsuranceService } from './apiDelayInsurance.service';
export * from './flexiApiResource.service';
import { FlexiApiResourceService } from './flexiApiResource.service';
export * from './flexiTvcItemResource.service';
import { FlexiTvcItemResourceService } from './flexiTvcItemResource.service';
export * from './insuranceApiResource.service';
import { InsuranceApiResourceService } from './insuranceApiResource.service';
export const APIS = [
  ApiDelayInsuranceService,
  FlexiApiResourceService,
  FlexiTvcItemResourceService,
  InsuranceApiResourceService,
  SimApiResourceService
];
