import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelSearchParamsPartialState } from './hotel-search-params.reducer';
import { hotelSearchParamsQuery } from './hotel-search-params.selectors';
import {
  LoadHotelSearchParams,
  ResetHotelSearchParams
} from './hotel-search-params.actions';

@Injectable()
export class HotelSearchParamsFacade {
  loaded$ = this.store.pipe(select(hotelSearchParamsQuery.getLoaded));
  allHotelSearchParams$ = this.store.pipe(
    select(hotelSearchParamsQuery.getAllHotelSearchParams)
  );
  selectedHotelSearchParams$ = this.store.pipe(
    select(hotelSearchParamsQuery.getSelectedHotelSearchParams)
  );

  constructor(private store: Store<HotelSearchParamsPartialState>) {}

  loadAll(payload: any) {
    this.store.dispatch(new LoadHotelSearchParams(payload));
  }

  reset() {
    this.store.dispatch(new ResetHotelSearchParams());
  }
}
