import { TicketDraftBookingRS, TicketDraftBookingVM } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './flight-create-draft-booking.reducer';

export enum FlightCreateDraftBookingActionTypes {
  LoadFlightCreateDraftBooking = '[FlightCreateDraftBooking] Load FlightCreateDraftBooking',
  ResetFlightCreateDraftBooking = '[FlightCreateDraftBooking] Reset FlightCreateDraftBooking',
  FlightCreateDraftBookingLoaded = '[FlightCreateDraftBooking] FlightCreateDraftBooking Loaded',
  FlightCreateDraftBookingLoadError = '[FlightCreateDraftBooking] FlightCreateDraftBooking Load Error'
}

export class LoadFlightCreateDraftBooking implements Action {
  readonly type =
    FlightCreateDraftBookingActionTypes.LoadFlightCreateDraftBooking;
  constructor(public payload: TicketDraftBookingVM) {}
}

export class ResetFlightCreateDraftBooking implements Action {
  readonly type =
    FlightCreateDraftBookingActionTypes.ResetFlightCreateDraftBooking;
}

export class FlightCreateDraftBookingLoadError implements Action {
  readonly type =
    FlightCreateDraftBookingActionTypes.FlightCreateDraftBookingLoadError;
  constructor(public payload: any) {}
}

export class FlightCreateDraftBookingLoaded implements Action {
  readonly type =
    FlightCreateDraftBookingActionTypes.FlightCreateDraftBookingLoaded;
  constructor(public payload: TicketDraftBookingRS) {}
}

export type FlightCreateDraftBookingAction =
  | LoadFlightCreateDraftBooking
  | FlightCreateDraftBookingLoaded
  | FlightCreateDraftBookingLoadError
  | ResetFlightCreateDraftBooking;

export const fromFlightCreateDraftBookingActions = {
  LoadFlightCreateDraftBooking,
  FlightCreateDraftBookingLoaded,
  FlightCreateDraftBookingLoadError,
  ResetFlightCreateDraftBooking
};
