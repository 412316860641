import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  transform(input: any) {
    return parseFloat(input).toFixed(1);
  }
}
