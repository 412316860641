import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailBannerComponent } from './detail-banner.component';
import { GButtonModule } from '@gotadi/design-system';
import { MatDialogModule, MatIconModule } from '@angular/material';
import { DetailLoaderBannerComponent } from './detail-loader-banner/detail-loader-banner.component';
import { TourLoadingBoxModule } from '@gtd/extra/tour/ui/tour-loading-box';

@NgModule({
  imports: [
    CommonModule,
    GButtonModule,
    MatIconModule,
    MatDialogModule,
    TourLoadingBoxModule
  ],
  declarations: [DetailBannerComponent, DetailLoaderBannerComponent],
  exports: [DetailBannerComponent, DetailLoaderBannerComponent]
})
export class DetailBannerModule {}
