import { AirGroupPricedItineraryRS, AirLowFareFilterVM } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './flight-group-itinerary.reducer';

export enum FlightGroupItineraryActionTypes {
  LoadFlightGroupItinerary = '[FlightGroupItinerary] Load FlightGroupItinerary',
  ResetFlightGroupItinerary = '[FlightGroupItinerary] Reset FlightGroupItinerary',
  FlightGroupItineraryLoaded = '[FlightGroupItinerary] FlightGroupItinerary Loaded',
  FlightGroupItineraryLoadError = '[FlightGroupItinerary] FlightGroupItinerary Load Error'
}
export interface GroupItineraryReq {
  id: string;
  airSearchId: AirLowFareFilterVM;
  includeEquivfare?: boolean;
  page?: number;
  size?: number;
  sort?: Array<string>;
}
export class LoadFlightGroupItinerary implements Action {
  readonly type = FlightGroupItineraryActionTypes.LoadFlightGroupItinerary;
  constructor(public payload: GroupItineraryReq) {}
}

export class ResetFlightGroupItinerary implements Action {
  readonly type = FlightGroupItineraryActionTypes.ResetFlightGroupItinerary;
}

export class FlightGroupItineraryLoadError implements Action {
  readonly type = FlightGroupItineraryActionTypes.FlightGroupItineraryLoadError;
  constructor(public payload: any) {}
}

export class FlightGroupItineraryLoaded implements Action {
  readonly type = FlightGroupItineraryActionTypes.FlightGroupItineraryLoaded;
  constructor(public payload: AirGroupPricedItineraryRS) {}
}

export type FlightGroupItineraryAction =
  | LoadFlightGroupItinerary
  | FlightGroupItineraryLoaded
  | FlightGroupItineraryLoadError
  | ResetFlightGroupItinerary;

export const fromFlightGroupItineraryActions = {
  LoadFlightGroupItinerary,
  FlightGroupItineraryLoaded,
  FlightGroupItineraryLoadError,
  ResetFlightGroupItinerary
};
