import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightGroupItineraryPartialState } from './flight-group-itinerary.reducer';
import {
  LoadFlightGroupItinerary,
  FlightGroupItineraryLoaded,
  FlightGroupItineraryLoadError,
  FlightGroupItineraryActionTypes
} from './flight-group-itinerary.actions';
import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class FlightGroupItineraryEffects {
  @Effect() loadFlightGroupItinerary$ = this.dataPersistence.fetch(
    FlightGroupItineraryActionTypes.LoadFlightGroupItinerary,
    {
      run: (
        action: LoadFlightGroupItinerary,
        state: FlightGroupItineraryPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this._airTicketResourceService
          .getGroupPricedItineraryUsingPOST(
            action.payload.id,
            action.payload.airSearchId,
            action.payload.includeEquivfare,
            action.payload.page,
            action.payload.size,
            action.payload.sort
          )
          .pipe(
            map(payload => ({
              type: FlightGroupItineraryActionTypes.FlightGroupItineraryLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadFlightGroupItinerary, error) => {
        console.error('Error', error);
        return new FlightGroupItineraryLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<FlightGroupItineraryPartialState>,
    private _airTicketResourceService: AirTicketsResourceService
  ) {}
}
