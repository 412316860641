import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightDetailDialogComponent } from './flight-detail-dialog.component';
import {
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatTabsModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { AirlinesModule } from '@gtd/components/airlines';
import { LoadingModule } from '@gtd/components/loading';
import {FlightStoreModule} from "@gtd/flights/data-access/store";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    AirlinesModule,
    MatTabsModule,
    LoadingModule,
    FlightStoreModule
  ],
  declarations: [FlightDetailDialogComponent],
  entryComponents: [FlightDetailDialogComponent],
  providers: [FlightDetailDialogComponent],
  exports: [FlightDetailDialogComponent]
})
export class FlightDetailDialogModule {}
