import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelSearchKeywordPartialState } from './hotel-search-keyword.reducer';
import {
  LoadHotelSearchKeyword,
  HotelSearchKeywordLoaded,
  HotelSearchKeywordLoadError,
  HotelSearchKeywordActionTypes
} from './hotel-search-keyword.actions';

@Injectable()
export class HotelSearchKeywordEffects {
  @Effect() loadHotelSearchKeyword$ = this.dataPersistence.fetch(
    HotelSearchKeywordActionTypes.LoadHotelSearchKeyword,
    {
      run: (
        action: LoadHotelSearchKeyword,
        state: HotelSearchKeywordPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return new HotelSearchKeywordLoaded([]);
      },

      onError: (action: LoadHotelSearchKeyword, error) => {
        console.error('Error', error);
        return new HotelSearchKeywordLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<HotelSearchKeywordPartialState>
  ) {}
}
