import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightGroupItineraryPartialState } from './flight-group-itinerary.reducer';
import { flightGroupItineraryQuery } from './flight-group-itinerary.selectors';
import {
  GroupItineraryReq,
  LoadFlightGroupItinerary,
  ResetFlightGroupItinerary
} from './flight-group-itinerary.actions';

@Injectable()
export class FlightGroupItineraryFacade {
  loaded$ = this.store.pipe(select(flightGroupItineraryQuery.getLoaded));
  allFlightGroupItinerary$ = this.store.pipe(
    select(flightGroupItineraryQuery.getAllFlightGroupItinerary)
  );
  selectedFlightGroupItinerary$ = this.store.pipe(
    select(flightGroupItineraryQuery.getSelectedFlightGroupItinerary)
  );

  constructor(private store: Store<FlightGroupItineraryPartialState>) {}

  loadAll(payload: GroupItineraryReq) {
    this.store.dispatch(new LoadFlightGroupItinerary(payload));
  }

  reset() {
    this.store.dispatch(new ResetFlightGroupItinerary());
  }
}
