import { Action } from '@ngrx/store';
import {
  CheckoutPayload,
  CheckoutResult,
  OtaResultOfCheckoutResult
} from '@gtd/b2c-client';

export enum HotelCheckoutActionTypes {
  LoadHotelCheckout = '[HotelCheckoutRoom] Load HotelCheckout',
  ResetHotelCheckout = '[HotelCheckoutRoom] Reset HotelCheckout',
  HotelCheckoutLoaded = '[HotelCheckoutRoom] HotelCheckout Loaded',
  HotelCheckoutLoadError = '[HotelCheckoutRoom] HotelCheckout Load Error'
}

export class LoadHotelCheckout implements Action {
  readonly type = HotelCheckoutActionTypes.LoadHotelCheckout;
  constructor(public payload: CheckoutPayload) {}
}

export class ResetHotelCheckout implements Action {
  readonly type = HotelCheckoutActionTypes.ResetHotelCheckout;
}

export class HotelCheckoutLoadError implements Action {
  readonly type = HotelCheckoutActionTypes.HotelCheckoutLoadError;
  constructor(public payload: any) {}
}

export class HotelCheckoutLoaded implements Action {
  readonly type = HotelCheckoutActionTypes.HotelCheckoutLoaded;
  constructor(public payload: OtaResultOfCheckoutResult) {}
}

export type HotelCheckoutAction =
  | LoadHotelCheckout
  | ResetHotelCheckout
  | HotelCheckoutLoaded
  | HotelCheckoutLoadError;

export const fromHotelCheckoutActions = {
  LoadHotelCheckout,
  ResetHotelCheckout,
  HotelCheckoutLoaded,
  HotelCheckoutLoadError
};
