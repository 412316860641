import { CheckoutPayload } from '@gtd/b2c-client';
import {
  HotelBookingAction,
  HotelBookingActionTypes
} from './hotel-booking.actions';

export const HOTELBOOKING_FEATURE_KEY = 'hotelBooking';

/**
 * Interface for the 'HotelBooking' data used in
 *  - HotelBookingState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelBookingState {
  list: CheckoutPayload; // list of HotelBooking; analogous to a sql normalized table
  selectedId?: string | number; // which HotelBooking record has been selected
  loaded: boolean; // has the HotelBooking list been loaded
  error?: any; // last none error (if any)
}

export interface HotelBookingPartialState {
  readonly [HOTELBOOKING_FEATURE_KEY]: HotelBookingState;
}

export const initialState: HotelBookingState = {
  list: null,
  loaded: false
};

export function reducer(
  state: HotelBookingState = initialState,
  action: HotelBookingAction
): HotelBookingState {
  switch (action.type) {
    case HotelBookingActionTypes.HotelBookingLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case HotelBookingActionTypes.ResetHotelBooking: {
      state = initialState;
      break;
    }
  }
  return state;
}
