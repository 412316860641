import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelCreateDraftBookingPartialState } from './hotel-create-draft-booking.reducer';
import {
  LoadHotelCreateDraftBooking,
  HotelCreateDraftBookingLoaded,
  HotelCreateDraftBookingLoadError,
  HotelCreateDraftBookingActionTypes
} from './hotel-create-draft-booking.actions';
import { HotelResourceV3Service } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class HotelCreateDraftBookingEffects {
  @Effect() loadHotelCreateDraftBooking$ = this.dataPersistence.fetch(
    HotelCreateDraftBookingActionTypes.LoadHotelCreateDraftBooking,
    {
      run: (
        action: LoadHotelCreateDraftBooking,
        state: HotelCreateDraftBookingPartialState
      ) => {
        this.hotelResourceV3Service.defaultHeaders = this.hotelResourceV3Service.defaultHeaders.set(
          'sales-env',
          ''
        );
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this.hotelResourceV3Service
          .createDraftBookingUsingPOST2(action.payload)
          .pipe(
            map(payload => ({
              type:
                HotelCreateDraftBookingActionTypes.HotelCreateDraftBookingLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadHotelCreateDraftBooking, error) => {
        console.error('Error', error);
        return new HotelCreateDraftBookingLoaded(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      HotelCreateDraftBookingPartialState
    >,
    private hotelResourceV3Service: HotelResourceV3Service
  ) {}
}
