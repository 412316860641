import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTBOOKING_FEATURE_KEY,
  FlightBookingState
} from './flight-booking.reducer';

// Lookup the 'FlightBooking' feature state managed by NgRx
const getFlightBookingState = createFeatureSelector<FlightBookingState>(
  FLIGHTBOOKING_FEATURE_KEY
);

const getAllFlightBooking = createSelector(
  getFlightBookingState,
  (state: FlightBookingState) => {
    return state;
  }
);
export const flightBookingQuery = {
  getAllFlightBooking
};
