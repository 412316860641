import { Action } from '@ngrx/store';
import {
  FlightFilterAvailableState,
  SelectDepartureItem
} from './flight-filter-available.reducer';

export enum FlightFilterAvailableActionTypes {
  ResetFlightFilterAvailable = '[FlightFilterAvailable] Reset FlightFilterAvailable',
  ResetFlightFilterAvailableRoundTrip = '[FlightFilterAvailable] Reset  Flight Filter RoundTrip',
  ChangeFlightFilterAvailable = '[FlightFilterAvailable] Change FlightFilterAvailable',
  ChangeAirlineOptions = '[FlightFilterAvailable] Change AirlineOptions',
  ChangeArrivalDateTimeOptions = '[FlightFilterAvailable] Change ArrivalDateTimeOptions',
  ChangeArrivalDateTimeReturnOptions = '[FlightFilterAvailable] Change ArrivalDateTimeReturnOptions',
  ChangeCabinClassOptions = '[FlightFilterAvailable] Change CabinClassOptions',
  ChangeDepartureDateTimeOptions = '[FlightFilterAvailable] DepartureDateTimeOptions',
  ChangeFlightType = '[FlightFilterAvailable] Change FlightType',
  ChangeStep = '[FlightFilterAvailable] Change Step',
  ChangeStopOptions = '[FlightFilterAvailable] Change StopOptions',
  ChangeTicketPolicyOptions = '[FlightFilterAvailable] Change TicketPolicyOptions',
  ChangeFlightPaginationPage = '[FlightFilterAvailable] Change FlightPagination Page',
  ChangeFlightPaginationSort = '[FlightFilterAvailable] Change FlightPagination Sort',
  ChangeFlightPaginationSize = '[FlightFilterAvailable] Change FlightPagination Size',
  ChangePrice = '[FlightFilterAvailable] Change Price',
  ChangeSearchID = '[FlightFilterAvailable] Change SearchID',
  SelectDeparture = '[FlightFilterAvailable] Select Departure'
}

export class ResetFlightFilterAvailable implements Action {
  readonly type = FlightFilterAvailableActionTypes.ResetFlightFilterAvailable;
}

export class ResetFlightFilterAvailableRoundTrip implements Action {
  readonly type =
    FlightFilterAvailableActionTypes.ResetFlightFilterAvailableRoundTrip;
  constructor(public payload: { searchID: string; flightType: string }) {}
}

export class ChangeFlightFilterAvailable implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeFlightFilterAvailable;

  constructor(public payload: FlightFilterAvailableState) {}
}

export class ChangeAirlineOptions implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeAirlineOptions;

  constructor(public payload: string[]) {}
}

export class ChangeArrivalDateTimeOptions implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeArrivalDateTimeOptions;

  constructor(public payload: string[]) {}
}

export class ChangeArrivalDateTimeReturnOptions implements Action {
  readonly type =
    FlightFilterAvailableActionTypes.ChangeArrivalDateTimeReturnOptions;

  constructor(public payload: string[]) {}
}

export class ChangeCabinClassOptions implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeCabinClassOptions;

  constructor(public payload: string[]) {}
}

export class ChangeDepartureDateTimeOptions implements Action {
  readonly type =
    FlightFilterAvailableActionTypes.ChangeDepartureDateTimeOptions;

  constructor(public payload: string[]) {}
}

export class ChangeFlightType implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeFlightType;

  constructor(public payload: string) {}
}

export class ChangeStep implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeStep;

  constructor(public payload: string) {}
}

export class ChangeStopOptions implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeStopOptions;

  constructor(public payload: string[]) {}
}

export class ChangeTicketPolicyOptions implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeTicketPolicyOptions;

  constructor(public payload: string[]) {}
}

export class ChangeFlightPaginationPage implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeFlightPaginationPage;
  constructor(public payload: number) {}
}
export class ChangePrice implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangePrice;
  constructor(public payload: { fromPrice: number; toPrice: number }) {}
}

export class ChangeFlightPaginationSort implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeFlightPaginationSort;
  constructor(public payload: string[]) {}
}

export class ChangeFlightPaginationSize implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeFlightPaginationSize;
  constructor(public payload: number) {}
}

export class ChangeSearchID implements Action {
  readonly type = FlightFilterAvailableActionTypes.ChangeSearchID;
  constructor(public payload: string) {}
}

export class SelectDeparture implements Action {
  readonly type = FlightFilterAvailableActionTypes.SelectDeparture;
  constructor(public payload: SelectDepartureItem) {}
}

export type FlightFilterAvailableAction =
  | ResetFlightFilterAvailable
  | ResetFlightFilterAvailableRoundTrip
  | ChangeFlightFilterAvailable
  | ChangeAirlineOptions
  | ChangeArrivalDateTimeOptions
  | ChangeArrivalDateTimeReturnOptions
  | ChangeCabinClassOptions
  | ChangeDepartureDateTimeOptions
  | ChangeFlightType
  | ChangeStep
  | ChangeStopOptions
  | ChangeTicketPolicyOptions
  | ChangeFlightPaginationPage
  | ChangeFlightPaginationSort
  | ChangeFlightPaginationSize
  | SelectDeparture
  | ChangeSearchID
  | ChangePrice;

export const fromFlightFilterAvailableActions = {
  ResetFlightFilterAvailable,
  ResetFlightFilterAvailableRoundTrip,
  ChangeFlightFilterAvailable,
  ChangeAirlineOptions,
  ChangeArrivalDateTimeOptions,
  ChangeArrivalDateTimeReturnOptions,
  ChangeCabinClassOptions,
  ChangeDepartureDateTimeOptions,
  ChangeFlightType,
  ChangeStep,
  ChangeStopOptions,
  ChangeTicketPolicyOptions,
  ChangeFlightPaginationPage,
  ChangeFlightPaginationSort,
  ChangeFlightPaginationSize,
  SelectDeparture,
  ChangeSearchID,
  ChangePrice
};
