import { AirFareRuleRS } from '@gtd/b2c-client';
import {
  FlightFarerulesAction,
  FlightFarerulesActionTypes
} from './flight-farerules.actions';

export const FLIGHTFARERULES_FEATURE_KEY = 'flightFarerules';

/**
 * Interface for the 'FlightFarerules' data used in
 *  - FlightFarerulesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FlightFarerulesState {
  list: AirFareRuleRS; // list of FlightFarerules; analogous to a sql normalized table
  selectedId?: string | number; // which FlightFarerules record has been selected
  loaded: boolean; // has the FlightFarerules list been loaded
  error?: any; // last none error (if any)
}

export interface FlightFarerulesPartialState {
  readonly [FLIGHTFARERULES_FEATURE_KEY]: FlightFarerulesState;
}

export const initialState: FlightFarerulesState = {
  list: null,
  loaded: false
};

export function reducer(
  state: FlightFarerulesState = initialState,
  action: FlightFarerulesAction
): FlightFarerulesState {
  switch (action.type) {
    case FlightFarerulesActionTypes.FlightFarerulesLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case FlightFarerulesActionTypes.ResetFlightFarerules: {
      state = {
        ...initialState
      };
      break;
    }
  }
  return state;
}
