import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { HotelImage } from '@gtd/b2c-client';
import { GalleriesComponent } from '@gtd/hotels/ui/detail-dialog';

@Component({
  selector: 'gtd-detail-banner',
  templateUrl: './detail-banner.component.html',
  styleUrls: ['./detail-banner.component.scss']
})
export class DetailBannerComponent implements OnInit {
  @Input() images: Array<HotelImage>;
  constructor(private _dialog: MatDialog) {}

  ngOnInit() {}

  openGalleriesDialog(index: number) {
    this._dialog.open(GalleriesComponent, {
      data: { images: this.images, index },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__fadeIn',
        'animate__faster',
        'hotel-detail-dialog',
        'gallery-dialog'
      ],
      disableClose: true
    });
  }
}
