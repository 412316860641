import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightCreateDraftBookingPartialState } from './flight-create-draft-booking.reducer';
import { flightCreateDraftBookingQuery } from './flight-create-draft-booking.selectors';
import {
  LoadFlightCreateDraftBooking,
  ResetFlightCreateDraftBooking
} from './flight-create-draft-booking.actions';
import { TicketDraftBookingVM } from '@gtd/b2c-client';

@Injectable()
export class FlightCreateDraftBookingFacade {
  loaded$ = this.store.pipe(select(flightCreateDraftBookingQuery.getLoaded));
  allFlightCreateDraftBooking$ = this.store.pipe(
    select(flightCreateDraftBookingQuery.getAllFlightCreateDraftBooking)
  );
  selectedFlightCreateDraftBooking$ = this.store.pipe(
    select(flightCreateDraftBookingQuery.getSelectedFlightCreateDraftBooking)
  );

  constructor(private store: Store<FlightCreateDraftBookingPartialState>) {}

  loadAll(payload: TicketDraftBookingVM) {
    this.store.dispatch(new LoadFlightCreateDraftBooking(payload));
  }

  reset() {
    this.store.dispatch(new ResetFlightCreateDraftBooking());
  }
}
