import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-tour-loading-box',
  templateUrl: './tour-loading-box.component.html',
  styleUrls: ['./tour-loading-box.component.scss']
})
export class TourLoadingBoxComponent implements OnInit {
  @Input() height = 0;
  @Input() full = false;
  @Input() rounded = true;
  constructor() {}

  ngOnInit() {}
}
