import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELSEARCHALLRATES_FEATURE_KEY,
  HotelSearchAllRatesState
} from './hotel-search-all-rates.reducer';

// Lookup the 'HotelSearchAllRates' feature state managed by NgRx
const getHotelSearchAllRatesState = createFeatureSelector<
  HotelSearchAllRatesState
>(HOTELSEARCHALLRATES_FEATURE_KEY);

const getLoaded = createSelector(
  getHotelSearchAllRatesState,
  (state: HotelSearchAllRatesState) => state.loaded
);
const getError = createSelector(
  getHotelSearchAllRatesState,
  (state: HotelSearchAllRatesState) => state.error
);

const getAllHotelSearchAllRates = createSelector(
  getHotelSearchAllRatesState,
  getLoaded,
  (state: HotelSearchAllRatesState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);

const getSearchId = createSelector(
  getHotelSearchAllRatesState,
  (state: HotelSearchAllRatesState) => state.searchId
);


const getParamInfo = createSelector(
  getHotelSearchAllRatesState,
  (state: HotelSearchAllRatesState) => state.paramInfo
);

const getTripId = createSelector(
  getHotelSearchAllRatesState,
  (state: HotelSearchAllRatesState) => state.tripId
);

const getSelectedId = createSelector(
  getHotelSearchAllRatesState,
  (state: HotelSearchAllRatesState) => state.selectedId
);
const getSelectedHotelSearchAllRates = createSelector(
  getAllHotelSearchAllRates,
  getSelectedId,
  (hotelSearchAllRates, id) => {
    const result = hotelSearchAllRates;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const hotelSearchAllRatesQuery = {
  getLoaded,
  getError,
  getAllHotelSearchAllRates,
  getSelectedHotelSearchAllRates,
  getSearchId,
  getParamInfo,
  getTripId
};
