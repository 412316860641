import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightFarerulesPartialState } from './flight-farerules.reducer';
import { flightFarerulesQuery } from './flight-farerules.selectors';
import {
  LoadFlightFarerules,
  ResetFlightFarerules
} from './flight-farerules.actions';
import { AirFareRuleRQ } from '@gtd/b2c-client';

@Injectable()
export class FlightFarerulesFacade {
  loaded$ = this.store.pipe(select(flightFarerulesQuery.getLoaded));
  allFlightFarerules$ = this.store.pipe(
    select(flightFarerulesQuery.getAllFlightFarerules)
  );
  selectedFlightFarerules$ = this.store.pipe(
    select(flightFarerulesQuery.getSelectedFlightFarerules)
  );

  constructor(private store: Store<FlightFarerulesPartialState>) {}

  loadAll(payload: AirFareRuleRQ, language: string) {
    this.store.dispatch(new LoadFlightFarerules(payload, language));
  }

  reset() {
    this.store.dispatch(new ResetFlightFarerules());
  }
}
