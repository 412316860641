import { Component, Input, OnInit } from '@angular/core';
import {ComboSelectedService} from "../../../../combo-selected.service";
import {Router} from "@angular/router";

@Component({
  selector: 'gtd-combo-booking',
  templateUrl: './combo-booking.component.html',
  styleUrls: ['./combo-booking.component.scss']
})
export class ComboBookingComponent implements OnInit {
  @Input() booking;
  @Input() selectedByUser = false;
  @Input() flightNotFound = false;
  constructor(
    private _comboSelectedService: ComboSelectedService,
    private router: Router,
  ) {}

  ngOnInit() {}


  getDestination(item: any, type: string, idx: number): any {
    if(item.itemFlight.flightType === 'INTERNATIONAL') {
      let originDestinationOptions = item.pricedItinerary.originDestinationOptions.filter(
        (originDestinationOption: any) => originDestinationOption.flightDirection === (idx===0? 'D': 'R')
      )
      if (!originDestinationOptions.length) return null;
      return {
        city: type === 'destination' ? originDestinationOptions[0].destinationCity : originDestinationOptions[0].originCity,
        city2: type === 'destination' ? originDestinationOptions[0].destinationCity : originDestinationOptions[0].originCity,
        code: type === 'destination' ? originDestinationOptions[0].destinationLocationCode : originDestinationOptions[0].originLocationCode,
      };
    } else {
      const flight = item.itemFlight;
      return {
        city: type === 'destination' ? flight.destinationCity : flight.originCity,
        city2: type === 'destination' ? flight.destinationCity : flight.originCity,
        code: type === 'destination' ? flight.destinationLocationCode : flight.originLocationCode,
      };
    }
  }

  resetFlight(type: 'from' | 'to') {
    const flightBooking = JSON.parse(localStorage.getItem('flightBooking'));
    this._comboSelectedService.removeFlight(
      type,
      flightBooking[type === 'from' ? 0 : 1].pricedItinerary
        .airItineraryPricingInfo.itinTotalFare.totalFare.amount
    );
    this.router.navigate(['/combo/flight'], {
      queryParams: {
        params: btoa(
          encodeURIComponent(localStorage.getItem('dataComboSearch'))
        )
      }
    });
  }
  getGroupPricedItinerary(item: any, idx: number) {
    if(item.itemFlight.flightType === 'INTERNATIONAL') {
      let originDestinationOptions = item.pricedItinerary.originDestinationOptions.filter(
        (originDestinationOption: any) => originDestinationOption.flightDirection === (idx===0? 'D': 'R')
      )
      return originDestinationOptions.length? originDestinationOptions[0]: null
    } else {
      return item.itemFlight
    }
  }
}
