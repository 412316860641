import { PropertyAllRate } from '@gtd/b2c-client';
import {
  HotelSearchAllRatesAction,
  HotelSearchAllRatesActionTypes, HotelSearchAllRatesLoadError
} from './hotel-search-all-rates.actions';

export const HOTELSEARCHALLRATES_FEATURE_KEY = 'hotelSearchAllRates';

/**
 * Interface for the 'HotelSearchAllRates' data used in
 *  - HotelSearchAllRatesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

export interface HotelAllRatesPayload {
  shortLinkId: string;
  propertyId: string;
  supplier: string;
  masterPropertyId: string;
}

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelSearchAllRatesState {
  list: PropertyAllRate; // list of HotelSearchAllRates; analogous to a sql normalized table
  searchId: string;
  paramInfo?: any;
  tripId: string;
  selectedId?: string | number; // which HotelSearchAllRates record has been selected
  loaded: boolean; // has the HotelSearchAllRates list been loaded
  error?: any; // last none error (if any)
}

export interface HotelSearchAllRatesPartialState {
  readonly [HOTELSEARCHALLRATES_FEATURE_KEY]: HotelSearchAllRatesState;
}

export const initialState: HotelSearchAllRatesState = {
  list: null,
  searchId: null,
  tripId: null,
  loaded: false
};

export function reducer(
  state: HotelSearchAllRatesState = initialState,
  action: HotelSearchAllRatesAction
): HotelSearchAllRatesState {
  switch (action.type) {
    case HotelSearchAllRatesActionTypes.LoadHotelSearchAllRates: {
      state = {
        ...state,
        loaded: false
      };
      break;
    }
    case HotelSearchAllRatesActionTypes.HotelSearchAllRatesLoaded: {
      state = {
        ...state,
        list: action.payload.propertyAllRate,
        searchId: action.payload.searchId,
        paramInfo: action.payload.paramInfo,
        tripId: action.payload.tripId,
        loaded: true
      };
      break;
    }
    case HotelSearchAllRatesActionTypes.ResetHotelSearchAllRates: {
      state = initialState;
      break;
    }
    case HotelSearchAllRatesActionTypes.HotelSearchAllRatesLoadError: {
      state = {
        ...state,
        loaded: true,
        error: 'Load detail error'
      };
      break;
    }
  }
  return state;
}
