import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSelect, MatSnackBar} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ReplaySubject, Subject, Subscription, throwError} from "rxjs";
import {AgencyResourceService, AgencyTO, LookupDTO} from "@gtd/api-services/b2b-client";
import {ActivatedRoute, Router, UrlSerializer} from "@angular/router";
import {LookupB2bFacade} from "@gtd/meta";
import {takeUntil} from "rxjs/operators";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {DialogMessageComponent, DialogOtpReqComponent} from "@gtd/components/dialog-message";
import {GetPageBySlugService} from "@gtd/api-services/contentsrv";
import {TemplateService} from "@gtd/services";

@Component({
  selector: 'gtd-register-ca',
  templateUrl: './register-ca.component.html',
  styleUrls: ['./register-ca.component.scss']
})
export class RegisterCaComponent implements OnInit {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  hide = true;
  isLoading = false;
  registerForm!: FormGroup;
  subscriptions = new Subscription();
  country = {"lookupValue": 'vn', "description": 'Vietnam'};

  protected cities: LookupDTO[];
  public cityFilterCtrl: FormControl = new FormControl();
  public filteredCities: ReplaySubject<LookupDTO[]> = new ReplaySubject<LookupDTO[]>(1);
  protected _onDestroy = new Subject<void>();
  pageData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private lookupFacade: LookupB2bFacade,
    private agencyResourceService: AgencyResourceService,
    private getPageBySlugService: GetPageBySlugService,
    private _elementRef: ElementRef,
    private templateService: TemplateService
  ) {
    this.templateService.setTemplateGtd('ca-template');
    this.createForm();
    this.lookupFacade.loadAll({domainName: 'destination', objectName: 'region', lookupType: 'vn', page: 0, size: 1000});
    this.getPageBySlugService.pageV1GetBySlugSlugGet('register-ca').subscribe(page => {
      if(page.success) this.pageData = page.page;
    });
  }

  ngOnInit() {
    this.lookupFacade.allLookupB2b$.subscribe(allLookupB2b => {
      this.cities = allLookupB2b;
      this.filteredCities.next(allLookupB2b.slice());
    })
    this.cityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCities();
      });
  }

  protected filterCities() {
    if (!this.cities) {
      return;
    }
    let search = this.cityFilterCtrl.value;
    if (!search && this.cities) {
      this.filteredCities.next(this.cities.slice());
      return;
    } else {
      search = search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    this.filteredCities.next(
      this.cities.filter(city => {
        let description = city.description;
        if(city.description === 'HCM') {
          description = 'Ho Chi Minh';
        } else {
          description = city.description.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }
        return (description.toLowerCase().indexOf(search) > -1) || (city.lookupCode.toLowerCase().indexOf(search) > -1) || (city.description.toLowerCase().indexOf(search) > -1)
      })
    );
  }

  private formSubmitApi(agencyTO: AgencyTO) {
    agencyTO.activeNow = false;
    this.agencyResourceService.createAutoAgencyUsingPOST(agencyTO).subscribe(register => {
      this.isLoading = false;
      this._snackBar.dismiss();
      if(register) {
        if(register.agentAdminId) {
          const dialogRef = this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [
                {btnText: 'global.close'},
                {btn: true, btnText: 'user.sign-in-now', btnColor: 'success', action: 'SinginNow'}
              ],
              content: 'user.register-ca-success',
              heading: 'user.register-success',
              messageType: 'primary'
            },
            disableClose: true,
            panelClass: 'dialog-do-confirm',
            position: {
              top: "150px"
            }
          })
          dialogRef.afterClosed().subscribe(diaLogAction => {
            if(diaLogAction && diaLogAction.action === 'SinginNow') {
              window.top.location.href = 'https://partner.gotadi.com/auth/login';
            }
          })
        }
        if(register.success) {
          if(register.smsServiceAvailable) {
            const dialogRefOtp = this.dialog.open(DialogOtpReqComponent, {
              data: {
                content: 'checkout.mess-confirm-otp',
                dataTranslate: this.registerForm.controls['phoneNumber1'].value,
                resend: false,
                heading: 'checkout.authentication-otp',
                messageType: 'warn'
              },
              disableClose: true,
              panelClass: 'dialog-do-confirm',
              position: {
                top: "100px"
              }
            })
            dialogRefOtp.afterClosed().subscribe(diaLogAction => {
              if(diaLogAction && diaLogAction.action === 'Resend') {
                this.isLoading = true;
                this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'checkout.get-otp-on-progress', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
                //TODO
              }
              if(diaLogAction && diaLogAction.action === 'Submit') {
                this.isLoading = true;
                this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'checkout.submit-otp-on-progress', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
                agencyTO.otpServiceReq = {
                  otpCode: diaLogAction.otpCode,
                  verificationCode: register.verificationCode
                }
                this.formSubmitApi(agencyTO);
              }
            })
          }
        } else {
          let errorMessage = 'user.otp-service-error-message';
          if (register.verificationCode) {
            errorMessage= 'user.otp-invalid-message'
          }
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [
                {btnText: 'global.close'}
              ],
              content: errorMessage,
              heading: 'user.heading-login-error',
              icon: 'exclamation-triangle',
              messageType: 'error'
            },
            panelClass: 'dialog-message',
            position: {
              top: "100px"
            },
            disableClose: true
          });
        }
      }
    }, error => {
      this.isLoading = false;
      this._snackBar.dismiss();
      let errorMessage;
      switch (error.error) {
        case 'idexists':
          errorMessage = 'A new agency cannot already have an ID';
          break;
        case 'userexists':
          errorMessage = 'user.user-exist';
          break;
        case 'emailexists':
          errorMessage = 'user.email-exist';
          break;
        case 'phonenumberblank':
          errorMessage = 'user.invalid-phone';
          break;
        case 'phonenumberexists':
          errorMessage = 'user.phone-exist';
          break;
        default:
          errorMessage = 'user.system-error-message';
          break;
      }
      this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [
            {btnText: 'global.close'}
          ],
          content: errorMessage,
          heading: 'user.system-error',
          icon: 'exclamation-triangle',
          messageType: 'error'
        },
        panelClass: 'dialog-message',
        position: {
          top: "100px"
        },
        disableClose: true
      });
    })
  }

  submitForm() {
    if (this.registerForm.invalid){
      return;
    }
    this.isLoading = true;
    this._snackBar.openFromComponent(SnackbarComponent, {data: {message: 'user.registering', loading: true}, panelClass: 'snackbar-loading', verticalPosition: "top"});
    const agencyTO  = {
      address1: this.registerForm.controls['address1'].value,
      agentAdminUsername: this.registerForm.controls['email'].value,
      contactPerson1: this.registerForm.controls['representativeLastName'].value + ' ' + this.registerForm.controls['representativeFirstName'].value,
      contactPerson2: this.registerForm.controls['representativeLastName'].value + ' ' + this.registerForm.controls['representativeFirstName'].value,
      cooperationClass: 'ca',
      cooperationForm: 'ca',
      countryCode: this.country.lookupValue,
      countryName: this.country.description,
      email: this.registerForm.controls['email'].value,
      fullName: this.registerForm.controls['shortName'].value,
      notify: "ENABLE",
      phoneContact1: this.registerForm.controls['phoneNumber1'].value,
      phoneContact2: this.registerForm.controls['phoneNumber1'].value,
      phoneNumber1: this.registerForm.controls['phoneNumber1'].value,
      representativeFirstName: this.registerForm.controls['representativeFirstName'].value,
      representativeLastName: this.registerForm.controls['representativeLastName'].value,
      representativeMail: this.registerForm.controls['email'].value,
      representativeName: this.registerForm.controls['representativeLastName'].value + ' ' + this.registerForm.controls['representativeFirstName'].value,
      representativePhone: this.registerForm.controls['phoneNumber1'].value,
      shortName: this.registerForm.controls['shortName'].value,
      taxCode: this.registerForm.controls['taxCode'].value,
      stateCode: this.registerForm.controls['state'].value.lookupValue,
      stateName: this.registerForm.controls['state'].value.description,
      status: "ACTIVATED",
      subMail: this.registerForm.controls['email'].value
    } as AgencyTO;
    this.formSubmitApi(agencyTO);
  }

  btnLogin() {
    window.top.location.href = 'https://partner.gotadi.com/auth/login';
  }

  scrollTo(el: string) {
    const off = this._elementRef.nativeElement.querySelector(`#${el}`).offsetTop -
      document.querySelector('.header-layout').clientHeight -
      46;
    window.scrollTo({ behavior: 'smooth', top: off });
  }

  private createForm() {
    this.registerForm = this.formBuilder.group({
      shortName: ['', Validators.required],
      taxCode: ['', Validators.required],
      address1: ['', Validators.required],
      representativeLastName: ['', Validators.required],
      representativeFirstName: ['', Validators.required],
      state: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')]],
      phoneNumber1: ['', [Validators.required, Validators.pattern("^((\\+84-?)|0)?[0-9]{10}$")]]
    });
  }

}
