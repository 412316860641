import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELSEARCHFILTEROPTIONS_FEATURE_KEY,
  HotelSearchFilterOptionsState
} from './hotel-search-filter-options.reducer';

// Lookup the 'HotelSearchFilterOptions' feature state managed by NgRx
const getHotelSearchFilterOptionsState = createFeatureSelector<
  HotelSearchFilterOptionsState
>(HOTELSEARCHFILTEROPTIONS_FEATURE_KEY);

const getLoaded = createSelector(
  getHotelSearchFilterOptionsState,
  (state: HotelSearchFilterOptionsState) => state.loaded
);
const getError = createSelector(
  getHotelSearchFilterOptionsState,
  (state: HotelSearchFilterOptionsState) => state.error
);

const getAllHotelSearchFilterOptions = createSelector(
  getHotelSearchFilterOptionsState,
  getLoaded,
  (state: HotelSearchFilterOptionsState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getHotelSearchFilterOptionsState,
  (state: HotelSearchFilterOptionsState) => state.selectedId
);
const getSelectedHotelSearchFilterOptions = createSelector(
  getAllHotelSearchFilterOptions,
  getSelectedId,
  (hotelSearchFilterOptions, id) => {
    return hotelSearchFilterOptions
      ? Object.assign({}, hotelSearchFilterOptions)
      : undefined;
  }
);

export const hotelSearchFilterOptionsQuery = {
  getLoaded,
  getError,
  getAllHotelSearchFilterOptions,
  getSelectedHotelSearchFilterOptions
};
