import { Action } from '@ngrx/store';
import { Entity, HotelAllRatesPayload } from './hotel-search-all-rates.reducer';
import {
  OtaResultOfSearchAllRatesResult,
  SearchAllRatesResult
} from '@gtd/b2c-client';

export enum HotelSearchAllRatesActionTypes {
  LoadHotelSearchAllRates = '[HotelSearchAllRates] Load HotelSearchAllRates',
  ResetHotelSearchAllRates = '[HotelSearchAllRates] Reset HotelSearchAllRates',
  HotelSearchAllRatesLoaded = '[HotelSearchAllRates] HotelSearchAllRates Loaded',
  HotelSearchAllRatesLoadError = '[HotelSearchAllRates] HotelSearchAllRates Load Error'
}

export class LoadHotelSearchAllRates implements Action {
  readonly type = HotelSearchAllRatesActionTypes.LoadHotelSearchAllRates;
  constructor(public payload: HotelAllRatesPayload) {}
}

export class ResetHotelSearchAllRates implements Action {
  readonly type = HotelSearchAllRatesActionTypes.ResetHotelSearchAllRates;
}

export class HotelSearchAllRatesLoadError implements Action {
  readonly type = HotelSearchAllRatesActionTypes.HotelSearchAllRatesLoadError;
  constructor(public payload: any) {}
}

export class HotelSearchAllRatesLoaded implements Action {
  readonly type = HotelSearchAllRatesActionTypes.HotelSearchAllRatesLoaded;
  constructor(public payload: SearchAllRatesResult) {}
}

export type HotelSearchAllRatesAction =
  | LoadHotelSearchAllRates
  | ResetHotelSearchAllRates
  | HotelSearchAllRatesLoaded
  | HotelSearchAllRatesLoadError;

export const fromHotelSearchAllRatesActions = {
  LoadHotelSearchAllRates,
  ResetHotelSearchAllRates,
  HotelSearchAllRatesLoaded,
  HotelSearchAllRatesLoadError
};
