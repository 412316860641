import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Btn, DialogMessageComponent } from '@gtd/components/dialog-message';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class TimerService {
  private PAGE_EXPIRATION_SECONDS = 60 * 15;
  private dialogButtons: Btn[] = [
    { btnText: 'booking-result.reload', btnReload: true }
  ];
  private unsubscribe$ = new Subject<void>();

  constructor(private dialogMessageService: MatDialog) {}

  init(expirationSeconds?: number, dialogButtons?: Btn[]) {
    if (expirationSeconds) {
      this.PAGE_EXPIRATION_SECONDS = expirationSeconds;
    }
    if (dialogButtons) {
      this.dialogButtons = dialogButtons;
    }
  }

  start() {
    interval(1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(second => {
        if (second === this.PAGE_EXPIRATION_SECONDS) {
          this.openExpirationDialog();
          this.stop();
        }
      });
  }

  stop() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private openExpirationDialog() {
    this.dialogMessageService.open(DialogMessageComponent, {
      data: {
        btn: this.dialogButtons,
        content: 'booking-result.page-expired-message',
        heading: 'booking-result.page-expired',
        messageType: 'success',
        hideCloseBtn: true
      },
      panelClass: 'dialog-do-confirm',
      position: {
        top: '150px'
      },
      disableClose: true,
      autoFocus: false
    });
  }
}
