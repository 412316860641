import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightBookingComponent } from './flight-booking.component';
import {MatDialogModule, MatProgressSpinnerModule} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { AirlinesModule } from '@gtd/components/airlines';
import { FlightBookingItemSummaryComponent } from './flight-booking-item-summary/flight-booking-item-summary.component';
import {FlightDetailDialogModule} from "@gtd/flights/ui/flight-detail-dialog";
import { ComboDetailDialogModule } from '@gtd/components/combo-detail-dialog';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    AirlinesModule,
    FlightDetailDialogModule,
    MatDialogModule,
    ComboDetailDialogModule
  ],
  declarations: [
    FlightBookingComponent,
    FlightBookingItemSummaryComponent
  ],
  exports: [
    FlightBookingComponent,
    FlightBookingItemSummaryComponent
  ]
})
export class FlightBookingModule {}
