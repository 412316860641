import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELSEARCHPARAMS_FEATURE_KEY,
  HotelSearchParamsState
} from './hotel-search-params.reducer';

// Lookup the 'HotelSearchParams' feature state managed by NgRx
const getHotelSearchParamsState = createFeatureSelector<HotelSearchParamsState>(
  HOTELSEARCHPARAMS_FEATURE_KEY
);

const getLoaded = createSelector(
  getHotelSearchParamsState,
  (state: HotelSearchParamsState) => state.loaded
);
const getError = createSelector(
  getHotelSearchParamsState,
  (state: HotelSearchParamsState) => state.error
);

const getAllHotelSearchParams = createSelector(
  getHotelSearchParamsState,
  getLoaded,
  (state: HotelSearchParamsState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getHotelSearchParamsState,
  (state: HotelSearchParamsState) => state.selectedId
);
const getSelectedHotelSearchParams = createSelector(
  getAllHotelSearchParams,
  getSelectedId,
  (hotelSearchParams, id) => {
    const result = hotelSearchParams.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const hotelSearchParamsQuery = {
  getLoaded,
  getError,
  getAllHotelSearchParams,
  getSelectedHotelSearchParams
};
