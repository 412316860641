import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AirlineAlliancesResourceService } from './api/airlineAlliancesResource.service';
import { AirlineResourceService } from './api/airlineResource.service';
import { AirportResourceService } from './api/airportResource.service';
import { BannerResourceService } from './api/bannerResource.service';
import { CityCodeResourceService } from './api/cityCodeResource.service';
import { CountryCodeResourceService } from './api/countryCodeResource.service';
import { DestinationMappingCodeResourceService } from './api/destinationMappingCodeResource.service';
import { DestinationMappingRegionResourceService } from './api/destinationMappingRegionResource.service';
import { DestinationMappingResourceService } from './api/destinationMappingResource.service';
import { ElasticsearchIndexResourceService } from './api/elasticsearchIndexResource.service';
import { EmailTemplateResourceService } from './api/emailTemplateResource.service';
import { HotelMappingCodeResourceService } from './api/hotelMappingCodeResource.service';
import { HotelMappingResourceService } from './api/hotelMappingResource.service';
import { HotelMetaContentResourceService } from './api/hotelMetaContentResource.service';
import { InternalConfigResourceService } from './api/internalConfigResource.service';
import { LookupResourceService } from './api/lookupResource.service';
import { MetaCacheDataResourceService } from './api/metaCacheDataResource.service';
import { ProfileInfoResourceService } from './api/profileInfoResource.service';
import { PropertyResourceService } from './api/propertyResource.service';
import { PropertyValueResourceService } from './api/propertyValueResource.service';
import { ReferenceCodeResourceService } from './api/referenceCodeResource.service';
import { RegionResourceService } from './api/regionResource.service';
import { RouteResourceService } from './api/routeResource.service';
import { StateCodeResourceService } from './api/stateCodeResource.service';
import {BASE_PATH} from "./variables";
import {environment} from "@env/environment";

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AirlineAlliancesResourceService,
    AirlineResourceService,
    AirportResourceService,
    BannerResourceService,
    CityCodeResourceService,
    CountryCodeResourceService,
    DestinationMappingCodeResourceService,
    DestinationMappingRegionResourceService,
    DestinationMappingResourceService,
    ElasticsearchIndexResourceService,
    EmailTemplateResourceService,
    HotelMappingCodeResourceService,
    HotelMappingResourceService,
    HotelMetaContentResourceService,
    InternalConfigResourceService,
    LookupResourceService,
    MetaCacheDataResourceService,
    ProfileInfoResourceService,
    PropertyResourceService,
    PropertyValueResourceService,
    ReferenceCodeResourceService,
    RegionResourceService,
    RouteResourceService,
    StateCodeResourceService,
    { provide: BASE_PATH, useValue: environment.api.metasrv },
  ],
})
export class MetaServiceApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: MetaServiceApiModule,
            providers: [
              { provide: BASE_PATH, useValue: environment.api.metasrv },
            ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: MetaServiceApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('B2cApiClientModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
