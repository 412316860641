import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTCREATEDRAFTBOOKING_FEATURE_KEY,
  FlightCreateDraftBookingState
} from './flight-create-draft-booking.reducer';

// Lookup the 'FlightCreateDraftBooking' feature state managed by NgRx
const getFlightCreateDraftBookingState = createFeatureSelector<
  FlightCreateDraftBookingState
>(FLIGHTCREATEDRAFTBOOKING_FEATURE_KEY);

const getLoaded = createSelector(
  getFlightCreateDraftBookingState,
  (state: FlightCreateDraftBookingState) => state.loaded
);
const getError = createSelector(
  getFlightCreateDraftBookingState,
  (state: FlightCreateDraftBookingState) => state.error
);

const getAllFlightCreateDraftBooking = createSelector(
  getFlightCreateDraftBookingState,
  getLoaded,
  (state: FlightCreateDraftBookingState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getFlightCreateDraftBookingState,
  (state: FlightCreateDraftBookingState) => state.selectedId
);
const getSelectedFlightCreateDraftBooking = createSelector(
  getAllFlightCreateDraftBooking,
  getSelectedId,
  (flightCreateDraftBooking, id) => {
    const result = flightCreateDraftBooking;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const flightCreateDraftBookingQuery = {
  getLoaded,
  getError,
  getAllFlightCreateDraftBooking,
  getSelectedFlightCreateDraftBooking
};
