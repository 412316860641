import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gtd-hotel-navbar',
  templateUrl: './hotel-navbar.component.html',
  styleUrls: ['./hotel-navbar.component.scss']
})
export class HotelNavbarComponent implements OnInit, AfterViewInit {
  @ViewChild('el', { static: false }) el: ElementRef;
  @Output() sectionSelected = new EventEmitter<string>();
  navFixed: boolean = false;
  scrollOffset: number;
  navbarHeight: number;
  headerHeigth: number;
  appName: string;
  lang: string;
  navbarSelected: string = 'information';

  lastScrollTop = 0;
  lastActionScrollTop = 0;
  isScrolled = false;


  sectionList: any[] = [
    {
      title: this._translateService.instant(
        'combovj.hotel-detail.navbar.overview'
      ),
      id: 'information'
    },
    {
      title: this._translateService.instant(
        'combovj.hotel-detail.navbar.kind-of-room'
      ),
      id: 'detail'
    },
    {
      title: this._translateService.instant(
        'combovj.hotel-detail.navbar.around-place'
      ),
      id: 'description'
    },
    {
      title: this._translateService.instant(
        'combovj.hotel-detail.navbar.amenities'
      ),
      id: 'amenities'
    },
    {
      title: this._translateService.instant(
        'combovj.hotel-detail.navbar.policies'
      ),
      id: 'policies'
    }
  ];
  downloadHeight: number;
  constructor(
    private _translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem('language');
    this.appName = localStorage.getItem('appName');
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.scrollOffset = this.el.nativeElement.getBoundingClientRect().top;
      this.navbarHeight = this.el.nativeElement.clientHeight;
      this.headerHeigth = document.querySelector('.header-layout').clientHeight;
      this.downloadHeight = document.querySelector('.download-section')
        ? document.querySelector('.download-section').clientHeight
        : 0;
    });
  }
  @HostListener('window:scroll')
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      this.navFixed =
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0) >
        this.scrollOffset + this.navbarHeight - this.headerHeigth;

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (Math.abs(scrollTop - this.lastActionScrollTop) >= 300) {
        this.isScrolled = scrollTop > this.lastScrollTop;
        this.lastActionScrollTop = scrollTop;
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    }
  }
  scrollToSection(label: string) {
    this.navbarSelected = label;
    this.sectionSelected.emit(label);
  }

}
