import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELSEARCHBESTRATES_FEATURE_KEY,
  HotelSearchBestRatesState
} from './hotel-search-best-rates.reducer';

// Lookup the 'HotelSearchBestRates' feature state managed by NgRx
const getHotelSearchBestRatesState = createFeatureSelector<
  HotelSearchBestRatesState
>(HOTELSEARCHBESTRATES_FEATURE_KEY);

const getLoaded = createSelector(
  getHotelSearchBestRatesState,
  (state: HotelSearchBestRatesState) => state.loaded
);
const getError = createSelector(
  getHotelSearchBestRatesState,
  (state: HotelSearchBestRatesState) => state.error
);

const getPageable = createSelector(
  getHotelSearchBestRatesState,
  getLoaded,
  (state: HotelSearchBestRatesState, isLoaded) => {
    return isLoaded ? state.pageable : null;
  }
);

const getSearchId = createSelector(
  getHotelSearchBestRatesState,
  getLoaded,
  (state: HotelSearchBestRatesState, isLoaded) => {
    return isLoaded ? state.searchId : null;
  }
);

const getAllHotelSearchBestRates = createSelector(
  getHotelSearchBestRatesState,
  getLoaded,
  (state: HotelSearchBestRatesState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getShortLinkIdSearchBestRates = createSelector(
  getHotelSearchBestRatesState,
  getLoaded,
  (state: HotelSearchBestRatesState, isLoaded) => {
    return isLoaded ? state.shortLinkId : null;
  }
);
const getSelectedId = createSelector(
  getHotelSearchBestRatesState,
  (state: HotelSearchBestRatesState) => state.selectedId
);
const getSelectedHotelSearchBestRates = createSelector(
  getAllHotelSearchBestRates,
  getSelectedId,
  (hotelSearchBestRates, id) => {
    const result = hotelSearchBestRates.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const hotelSearchBestRatesQuery = {
  getLoaded,
  getError,
  getAllHotelSearchBestRates,
  getShortLinkIdSearchBestRates,
  getSelectedHotelSearchBestRates,
  getPageable,
  getSearchId
};
