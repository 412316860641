import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightSearchLowFarePartialState } from './flight-search-low-fare.reducer';
import {
  LoadFlightSearchLowFare,
  FlightSearchLowFareLoaded,
  FlightSearchLowFareLoadError,
  FlightSearchLowFareActionTypes
} from './flight-search-low-fare.actions';
import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map, takeUntil } from 'rxjs/operators';
import { FlightUnsubscribeService } from '@gtd/flights/data-access/services';

@Injectable()
export class FlightSearchLowFareEffects {
  @Effect() loadFlightSearchLowFare$ = this.dataPersistence.fetch(
    FlightSearchLowFareActionTypes.LoadFlightSearchLowFare,
    {
      run: (
        action: LoadFlightSearchLowFare,
        state: FlightSearchLowFarePartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this._airTicketResourceService
          .lowFareSearchAsyncUsingGET(
            action.payload.originCode,
            action.payload.destinationCode,
            action.payload.departureDate,
            action.payload.time,
            action.payload.key,
            action.payload.returntureDate,
            action.payload.cabinClass,
            action.payload.routeType,
            action.payload.adutsQtt,
            action.payload.childrenQtt,
            action.payload.infantsQtt,
            action.payload.suppliers,
            action.payload.includeEquivfare,
            '',
            action.payload.combo,
            true,
            action.payload.page,
            action.payload.size,
            action.payload.sort
          )
          .pipe(
            map(payload => ({
              type: FlightSearchLowFareActionTypes.FlightSearchLowFareLoaded,
              payload: payload
            })),
            takeUntil(this._flightUnsubscribeService.unsubscribe$)
          );
      },

      onError: (action: LoadFlightSearchLowFare, error) => {
        console.error('Error', error);
        return new FlightSearchLowFareLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<FlightSearchLowFarePartialState>,
    private _airTicketResourceService: AirTicketsResourceService,
    private _flightUnsubscribeService: FlightUnsubscribeService
  ) {}
}
