import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTFARERULES_FEATURE_KEY,
  FlightFarerulesState
} from './flight-farerules.reducer';

// Lookup the 'FlightFarerules' feature state managed by NgRx
const getFlightFarerulesState = createFeatureSelector<FlightFarerulesState>(
  FLIGHTFARERULES_FEATURE_KEY
);

const getLoaded = createSelector(
  getFlightFarerulesState,
  (state: FlightFarerulesState) => state.loaded
);
const getError = createSelector(
  getFlightFarerulesState,
  (state: FlightFarerulesState) => state.error
);

const getAllFlightFarerules = createSelector(
  getFlightFarerulesState,
  getLoaded,
  (state: FlightFarerulesState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getFlightFarerulesState,
  (state: FlightFarerulesState) => state.selectedId
);
const getSelectedFlightFarerules = createSelector(
  getAllFlightFarerules,
  getSelectedId,
  (flightFarerules, id) => {
    const result = flightFarerules;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const flightFarerulesQuery = {
  getLoaded,
  getError,
  getAllFlightFarerules,
  getSelectedFlightFarerules
};
