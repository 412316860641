import { Injectable } from '@angular/core';
import { GroupPricedItinerary } from '@gtd/api-services/b2b-client';
import { PricedItinerary } from '@gtd/b2c-client';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComboSelectedService {
  flightListSelectedSubject$ = new BehaviorSubject<any>(null);
  flightListSelected$ = this.flightListSelectedSubject$.asObservable();
  flightListSelectedArray = [];

  flightListSelectedForSEOSubject$ = new BehaviorSubject<any>(null);
  flightListSelectedForSEO$ = this.flightListSelectedForSEOSubject$.asObservable();
  flightListSelectedForSEOArray = [];

  totalPriceSubject$ = new BehaviorSubject<any>(null);
  totalPrice$ = this.totalPriceSubject$.asObservable();
  totalPrice = 0;

  priceOfFlightAutoSelectedSubject$ = new BehaviorSubject<number[]>([]);
  priceOfFlightAutoSelected$ = this.priceOfFlightAutoSelectedSubject$.asObservable();
  priceOfFlightAutoSelectedArray = [];

  airlineOptionsSubject$ = new BehaviorSubject<string>('');
  airlineOptions$ = this.airlineOptionsSubject$.asObservable();

  flightReset = false;

  flightSelectedDoneSubject$ = new BehaviorSubject<boolean>(false);
  flightSelectedDone$ = this.flightSelectedDoneSubject$.asObservable();

  flightSelectedDoneForSEOSubject$ = new BehaviorSubject<boolean>(false);
  flightSelectedDoneForSEO$ = this.flightSelectedDoneForSEOSubject$.asObservable();

  hotelSelectedSubject$ = new BehaviorSubject<any>(null);
  hotelSelected$ = this.hotelSelectedSubject$.asObservable();

  roomSelectedSubject$ = new BehaviorSubject<any>(null);
  roomSelected$ = this.roomSelectedSubject$.asObservable();
  constructor() {}

  selectFlight(
    searchId: string,
    pricedItinerary: PricedItinerary,
    groupPricedItineraries: GroupPricedItinerary,
    searchType: string = 'ONEWAY'
  ) {
    this.flightListSelectedArray.push({
      searchId: searchId,
      itemFlight: groupPricedItineraries,
      pricedItinerary: pricedItinerary
    });

    // this.priceOfFlightAutoSelectedArray.push(
    //   pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare.totalFare
    //     .amount /
    //     pricedItinerary.airItineraryPricingInfo.adultFare
    //       .passengerTypeQuantities.quantity
    // );
    // console.log(this.priceOfFlightAutoSelectedArray);
    // this.priceOfFlightAutoSelectedSubject$.next(
    //   this.priceOfFlightAutoSelectedArray
    // );
    if(groupPricedItineraries.flightType === 'DOMESTIC') {
      this.totalPriceSubject$.next(
        (this.totalPrice +=
          pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount / pricedItinerary.airItineraryPricingInfo.adultFare.passengerTypeQuantities.quantity)
      );
    } else {
      this.totalPriceSubject$.next(
        (this.totalPrice =
          pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount / pricedItinerary.airItineraryPricingInfo.adultFare.passengerTypeQuantities.quantity)
      );
    }

    this.flightListSelectedSubject$.next(this.flightListSelectedArray);

    if (this.flightListSelectedArray.length === 1) {
      this.airlineOptionsSubject$.next(groupPricedItineraries.airSupplier);
    }
    if (searchType === 'ONEWAY' && this.flightListSelectedArray.length === 1) {
      this.flightReset = false;
      this.flightSelectedDoneSubject$.next(true);
    }
    if (
      searchType === 'ROUNDTRIP' &&
      this.flightListSelectedArray.length === 2
    ) {
      this.flightReset = false;
      this.flightSelectedDoneSubject$.next(true);
    }
  }

  selectFlightForSEO(
    searchId: string,
    pricedItinerary: PricedItinerary,
    groupPricedItineraries: GroupPricedItinerary,
    searchType: string = 'ONEWAY'
  ) {
    this.flightListSelectedForSEOArray.push({
      searchId: searchId,
      itemFlight: groupPricedItineraries,
      pricedItinerary: pricedItinerary
    });
    this.flightListSelectedForSEOSubject$.next(
      this.flightListSelectedForSEOArray
    );
    if (
      searchType === 'ONEWAY' &&
      this.flightListSelectedForSEOArray.length === 1
    ) {
      this.flightReset = false;
      this.flightSelectedDoneForSEOSubject$.next(true);
    }
    if (
      searchType === 'ROUNDTRIP' &&
      this.flightListSelectedForSEOArray.length === 2
    ) {
      this.flightReset = false;
      this.flightSelectedDoneForSEOSubject$.next(true);
    }
  }

  setLowestPriceInFlight(pricedItineraries: PricedItinerary[]) {
    this.priceOfFlightAutoSelectedArray = pricedItineraries.map(
      pricedItinerary =>
        pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .totalFare.amount /
        pricedItinerary.airItineraryPricingInfo.adultFare
          .passengerTypeQuantities.quantity
    );

    this.priceOfFlightAutoSelectedSubject$.next(
      this.priceOfFlightAutoSelectedArray
    );
  }

  selectHotel(hotelInfo) {
    this.hotelSelectedSubject$.next(hotelInfo);
  }

  selectRoom(roomInfo) {
    this.roomSelectedSubject$.next(roomInfo);
  }

  removeFlight(type: 'from' | 'to', price: number) {
    if (type === 'from') {
      this.flightListSelectedArray = [];
      this.totalPrice = 0;
      this.airlineOptionsSubject$.next('');
    } else {
      this.flightListSelectedArray.splice(1, 1);
      this.totalPrice -= price;
    }
    this.flightReset = true;
    this.flightSelectedDoneSubject$.next(false);
    this.totalPriceSubject$.next(this.totalPrice);
    this.flightListSelectedSubject$.next(this.flightListSelectedArray);
  }

  saveFlightLocalStorage() {
    localStorage.setItem(
      'flightBooking',
      JSON.stringify(this.flightListSelectedArray)
    );
  }

  resetAirline() {
    // this.flightListSelectedArray = [];
    // this.totalPrice = 0;
    this.airlineOptionsSubject$.next('');
    // this.flightSelectedDoneSubject$.next(false);
    // this.totalPriceSubject$.next(null);
    // this.flightListSelectedSubject$.next(null);
  }

  resetFLight() {
    this.flightListSelectedArray = [];
    this.priceOfFlightAutoSelectedArray = [];
    this.totalPrice = 0;
    this.airlineOptionsSubject$.next('');
    this.priceOfFlightAutoSelectedSubject$.next([]);
    this.totalPriceSubject$.next(null);
    this.flightListSelectedSubject$.next(null);
    this.flightSelectedDoneSubject$.next(false);
  }
  resetForSEO() {
    this.flightListSelectedForSEOArray = [];
    this.flightSelectedDoneForSEOSubject$.next(false);
    this.flightListSelectedForSEOSubject$.next(null);
  }
  resetAll() {
    this.flightListSelectedArray = [];
    this.priceOfFlightAutoSelectedArray = [];
    this.totalPrice = 0;
    this.flightReset = false;
    this.airlineOptionsSubject$.next('');
    this.flightSelectedDoneSubject$.next(false);
    this.priceOfFlightAutoSelectedSubject$.next([]);
    this.totalPriceSubject$.next(null);
    this.flightListSelectedSubject$.next(null);
    this.roomSelectedSubject$.next(null);
    this.hotelSelectedSubject$.next(null);
  }
}
