import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-detail-loader-banner',
  templateUrl: './detail-loader-banner.component.html',
  styleUrls: ['./detail-loader-banner.component.scss']
})
export class DetailLoaderBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
