import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-detail-loader-overview',
  templateUrl: './detail-loader-overview.component.html',
  styleUrls: ['./detail-loader-overview.component.scss']
})
export class DetailLoaderOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
