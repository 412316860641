import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {Amenity, CancelPenalty, PropertyRoom, RatePlan} from '@gtd/b2c-client';
import {HotelFunctionService} from '@gtd/hotels/data-access/services';
import {HotelSearchAllRatesFacade, HotelSearchParamsFacade} from '@gtd/hotels/data-access/store';

@Component({
  selector: 'gtd-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('thumb', { static: false }) thumb?: ElementRef;
  search: any;
  amenitiesWithoutDescription: Array<Amenity> = [];
  dayGap: number;
  toggleDetail = false;
  slideIndex = 0;
  config: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 15,
    // lazy: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.gallery-next',
      prevEl: '.gallery-prev'
    }
  };
  amenitiesGroup: any;
  utm_source = localStorage.getItem('utm_source');
  constructor(
    public dialogRef: MatDialogRef<RoomComponent>,
    private _hotelSearchAllRatePlansFacade: HotelSearchAllRatesFacade,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      ratePlan: RatePlan;
      room: PropertyRoom;
      amenities: Array<Amenity>;
      exchangeType?: string;
      exchangeRate?: string;
    },
    private _hotelSearchParamsFacade: HotelSearchParamsFacade,
    private _hotelFunctionService: HotelFunctionService
  ) {

    this._hotelSearchAllRatePlansFacade.searchId$
      .subscribe(searchId => {
        if(searchId) {
          this.generateDataSearch(searchId);
        }
      });
  }

  generateDataSearch(searchId: string) {
    const searchIdP = JSON.parse(atob(searchId)) as any;
    this.dayGap = this._hotelFunctionService.getDayGap(
      searchIdP.checkIn,
      searchIdP.checkOut
    );
    this.search = ({
      locationCode: searchIdP.searchCode,
      checkIn: searchIdP.checkIn,
      checkOut: searchIdP.checkOut,
      roomsArrs: searchIdP.paxInfos,
      supplier: searchIdP.supplier,
    })
  }

  ngOnInit() {
    if(this.data.ratePlan) {
      this.amenitiesWithoutDescription = this.data.ratePlan.amenities.filter(
        amenity => amenity.id !== 'rate_description'
      );
    }
    this.amenitiesGroup = this.data.room.amenities.reduce(function(arr, cur) {
      arr[cur.group] = arr[cur.group] || [];
      arr[cur.group].push(cur);
      return arr;
    }, Object.create(null));
  }

  close(selected?: boolean) {
    this.dialogRef.close(selected);
  }

  getCancelPelnaltiesDate(type: 'start' | 'end', cancelPenalty: CancelPenalty) {
    const startDate = cancelPenalty.startDate
      .split(' ')[0]
      .replace('.999999999', '');
    const endDate = cancelPenalty.endDate
      .split(' ')[0]
      .replace('.999999999', '');
    if (type == 'start') {
      return startDate;
    }
    return endDate;
  }

  slideChange(event) {
    const itemWidth = this.thumb.nativeElement.children[0].clientWidth + 2;

    const fullWidth = itemWidth * this.data.room.images.length;
    const center = 350;
    const centerDistanceFromLeft = center - itemWidth / 2;
    if (event !== 0) {
      let distanceFromLeft = event * itemWidth;
      if (distanceFromLeft > centerDistanceFromLeft) {
        const scrollAmount = centerDistanceFromLeft - distanceFromLeft;
        this.thumb.nativeElement.style.transform = `translateX(${scrollAmount}px)`;
      } else {
        this.thumb.nativeElement.style.transform = `translateX(0px)`;
      }
    } else {
      this.thumb.nativeElement.style.transform = `translateX(0px)`;
    }

    this.slideIndex = event;
  }

  thumbClick(index) {
    this.swiper.directiveRef.setIndex(index);
  }

  getPricePerNight(type: 'after' | 'before', utm_source?: string) {
    if(this.search) {
      let numberOfRooms = this.search.roomsArrs.length;
      let numberOfNights = this.dayGap;
      if(utm_source !== 'DHA') {
        const after =
          Math.ceil(
            this.data.ratePlan.basePrice / (numberOfRooms * numberOfNights * 1000)
          ) * 1000;
        const before =
          Math.ceil(
            this.data.ratePlan.basePriceBeforePromo /
            (numberOfRooms * numberOfNights * 1000)
          ) * 1000;

        return type == 'after' ? after : before;
      } else {
        const after = this.data.ratePlan.basePrice / (numberOfRooms * numberOfNights);
        const before = this.data.ratePlan.basePriceBeforePromo / (numberOfRooms * numberOfNights);
        return type == 'after' ? after : before;
      }
    }
  }

  getNumberOfPassengers(type: 'adult' | 'child') {
    return this.search.roomsArrs.reduce((arr, cur) => {
      return arr + cur[type == 'adult' ? 'adultQuantity' : 'childQuantity'];
    }, 0);
  }
}
