import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightFilterBoxComponent } from './flight-filter-box.component';
import {
  MatCheckboxModule,
  MatExpansionModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSliderModule
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { Ng5SliderModule } from 'ng5-slider';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatRadioModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatSliderModule,
    Ng5SliderModule,
    MatProgressSpinnerModule,
    TranslateModule
  ],
  declarations: [FlightFilterBoxComponent],
  exports: [FlightFilterBoxComponent]
})
export class FlightFilterBoxModule {}
