import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightSearchFilterAvailabilityPartialState } from './flight-search-filter-availability.reducer';
import {
  LoadFlightSearchFilterAvailability,
  FlightSearchFilterAvailabilityLoaded,
  FlightSearchFilterAvailabilityLoadError,
  FlightSearchFilterAvailabilityActionTypes
} from './flight-search-filter-availability.actions';
import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class FlightSearchFilterAvailabilityEffects {
  @Effect() loadFlightSearchFilterAvailability$ = this.dataPersistence.fetch(
    FlightSearchFilterAvailabilityActionTypes.LoadFlightSearchFilterAvailability,
    {
      run: (
        action: LoadFlightSearchFilterAvailability,
        state: FlightSearchFilterAvailabilityPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this._airTicketResourceService
          .filterAirLowFareUsingPOST(
            action.payload.filter,
            action.payload.includeEquivfare,
            action.payload.page,
            action.payload.size,
            action.payload.sort
          )
          .pipe(
            map(payload => ({
              type:
                FlightSearchFilterAvailabilityActionTypes.FlightSearchFilterAvailabilityLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadFlightSearchFilterAvailability, error) => {
        console.error('Error', error);
        return new FlightSearchFilterAvailabilityLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      FlightSearchFilterAvailabilityPartialState
    >,
    private _airTicketResourceService: AirTicketsResourceService
  ) {}
}
