import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTSEARCHFILTEROPTIONS_FEATURE_KEY,
  FlightSearchFilterOptionsState
} from './flight-search-filter-options.reducer';

// Lookup the 'FlightSearchFilterOptions' feature state managed by NgRx
const getFlightSearchFilterOptionsState = createFeatureSelector<
  FlightSearchFilterOptionsState
>(FLIGHTSEARCHFILTEROPTIONS_FEATURE_KEY);

const getLoaded = createSelector(
  getFlightSearchFilterOptionsState,
  (state: FlightSearchFilterOptionsState) => state.loaded
);
const getError = createSelector(
  getFlightSearchFilterOptionsState,
  (state: FlightSearchFilterOptionsState) => state.error
);

const getAllFlightSearchFilterOptions = createSelector(
  getFlightSearchFilterOptionsState,
  getLoaded,
  (state: FlightSearchFilterOptionsState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getFlightSearchFilterOptionsState,
  (state: FlightSearchFilterOptionsState) => state.selectedId
);
const getSelectedFlightSearchFilterOptions = createSelector(
  getAllFlightSearchFilterOptions,
  getSelectedId,
  (flightSearchFilterOptions, id) => {
    const result = flightSearchFilterOptions;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const flightSearchFilterOptionsQuery = {
  getLoaded,
  getError,
  getAllFlightSearchFilterOptions,
  getSelectedFlightSearchFilterOptions
};
