import {
  HotelSearchParamsAction,
  HotelSearchParamsActionTypes
} from './hotel-search-params.actions';

export const HOTELSEARCHPARAMS_FEATURE_KEY = 'hotelSearchParams';

/**
 * Interface for the 'HotelSearchParams' data used in
 *  - HotelSearchParamsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelSearchParamsState {
  list: any; // list of HotelSearchParams; analogous to a sql normalized table
  selectedId?: string | number; // which HotelSearchParams record has been selected
  loaded: boolean; // has the HotelSearchParams list been loaded
  error?: any; // last none error (if any)
}

export interface HotelSearchParamsPartialState {
  readonly [HOTELSEARCHPARAMS_FEATURE_KEY]: HotelSearchParamsState;
}

export const initialState: HotelSearchParamsState = {
  list: null,
  loaded: false
};

export function reducer(
  state: HotelSearchParamsState = initialState,
  action: HotelSearchParamsAction
): HotelSearchParamsState {
  switch (action.type) {
    case HotelSearchParamsActionTypes.HotelSearchParamsLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case HotelSearchParamsActionTypes.ResetHotelSearchParams: {
      state = initialState;
      break;
    }
  }
  return state;
}
