import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlightSearchParamsService {
  _flightSearchParamsSubject$ = new BehaviorSubject(null);
  _flightSearchParams$ = this._flightSearchParamsSubject$.asObservable();
  constructor() {}

  setSearchParams(searchParams: any) {
    this._flightSearchParamsSubject$.next(searchParams);
  }

  getSearchParams() {
    return this._flightSearchParams$;
  }
}
