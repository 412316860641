export const STRING_EMPTRY = "";
export const DEFAULT_PAGINATION_PAGE = 0;
export const DEFAULT_PAGINATION_OFFSET = 30;
export const DEFAULT_SORT_SINGLE = ['departureDate,asc'];
export const DEFAULT_SORT = ['price,asc'];
export const DEFAULT_SORT_COMBO = ['defaultCombo,asc'];
export const DEFAULT_PAGINATION_LOAD_MORE = false;
export const DEFAULT_STEP = '1';
export const DEFAULT_MIN_PRICE = 0;
export const EMPTY_ARRAY = [];
export const EMPTY_STRING = '';
export const TIME_FILTER_RANGE = [
  { from: '00:00', to: '06:00', val: '-6', checked: false },
  { from: '06:00', to: '12:00', val: '+6-12', checked: false },
  { from: '12:00', to: '18:00', val: '+12-18', checked: false },
  { from: '18:00', to: '23:59', val: '+18', checked: false }
];
