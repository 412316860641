import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelFilterAvailablePartialState } from './hotel-filter-available.reducer';
import { HotelFilterAvailableActionTypes } from './hotel-filter-available.actions';

@Injectable()
export class HotelFilterAvailableEffects {
  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<HotelFilterAvailablePartialState>
  ) {}
}
