import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelCreateDraftBookingPartialState } from './hotel-create-draft-booking.reducer';
import { hotelCreateDraftBookingQuery } from './hotel-create-draft-booking.selectors';
import {
  LoadHotelCreateDraftBooking,
  ResetHotelCreateDraftBooking
} from './hotel-create-draft-booking.actions';
import { CreateDraftBookingHotelPayload } from '@gtd/b2c-client';

@Injectable()
export class HotelCreateDraftBookingFacade {
  loaded$ = this.store.pipe(select(hotelCreateDraftBookingQuery.getLoaded));
  allHotelCreateDraftBooking$ = this.store.pipe(
    select(hotelCreateDraftBookingQuery.getAllHotelCreateDraftBooking)
  );
  selectedHotelCreateDraftBooking$ = this.store.pipe(
    select(hotelCreateDraftBookingQuery.getSelectedHotelCreateDraftBooking)
  );

  constructor(private store: Store<HotelCreateDraftBookingPartialState>) {}

  loadAll(payload: CreateDraftBookingHotelPayload) {
    this.store.dispatch(new LoadHotelCreateDraftBooking(payload));
  }

  reset() {
    this.store.dispatch(new ResetHotelCreateDraftBooking());
  }
}
