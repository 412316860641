import { Injectable } from '@angular/core';
import { GroupPricedItinerary } from '@gtd/b2c-client';

import { select, Store } from '@ngrx/store';
import {
  FlightBooking,
  ResetFlightBooking,
  ResetFlightWay,
  SetFlightBooking,
  SetRoundType
} from './flight-booking.actions';

import { FlightBookingPartialState } from './flight-booking.reducer';
import { flightBookingQuery } from './flight-booking.selectors';

@Injectable()
export class FlightBookingFacade {
  allFlightBooking$ = this.store.pipe(
    select(flightBookingQuery.getAllFlightBooking)
  );

  constructor(private store: Store<FlightBookingPartialState>) {}

  setFlightBooking(payload: FlightBooking) {
    this.store.dispatch(new SetFlightBooking(payload));
  }

  setRoundType(payload: GroupPricedItinerary.RoundTypeEnum) {
    this.store.dispatch(new SetRoundType(payload));
  }

  reset() {
    this.store.dispatch(new ResetFlightBooking());
  }

  resetFlightWay(payload: 'from' | 'to') {
    this.store.dispatch(new ResetFlightWay(payload));
  }
}
