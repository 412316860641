import { Component, Input, OnInit } from '@angular/core';
import { PropertyAllRate } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-detail-policies',
  templateUrl: './detail-policies.component.html',
  styleUrls: ['./detail-policies.component.scss']
})
export class DetailPoliciesComponent implements OnInit {
  @Input() detail: PropertyAllRate;
  constructor() {}

  ngOnInit() {}
}
