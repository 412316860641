import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboDetailDialogComponent } from './combo-detail-dialog/combo-detail-dialog.component';
import { AirlinesModule } from '@gtd/components/airlines';
import { LoadingModule } from '@gtd/components/loading';
import { MatButtonModule, MatDialogModule, MatIconModule, MatTabsModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { FlightStoreModule } from '@gtd/flights/data-access/store';

@NgModule({
  imports: [
    CommonModule,
    AirlinesModule,
    LoadingModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    TranslateModule,
    MatTabsModule,
    FlightStoreModule
  ],
  declarations: [ComboDetailDialogComponent],
  exports: [ComboDetailDialogComponent],
  providers: [
    ComboDetailDialogComponent
  ],
  entryComponents: [ComboDetailDialogComponent]
})
export class ComboDetailDialogModule {}
