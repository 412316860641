import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  FlightFilterAvailableActionTypes,
  FlightFilterAvailableFacade
} from '@gtd/flights/data-access/store';
import { fadeIn } from '@gtd/helpers';
import { Actions, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gtd-flight-filter-box',
  templateUrl: './flight-filter-box.component.html',
  styleUrls: ['./flight-filter-box.component.scss'],
  animations: [fadeIn]
})
export class FlightFilterBoxComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: 'checkbox' | 'radio' | 'range' | 'timeRange';
  @Input() filterOptions = [];
  @Input() title: string;
  @Input() name: string;
  @Input() priceRange;
  @Input() flightAvailableLoaded: boolean;
  @Input() flightFilterLoaded: boolean;
  typeChanged: 'departure' | 'arrive' = null;
  panelOpenState = true;
  rangeValue = 0;
  filterForm: FormGroup;
  optionSelected: string[] = [];
  timeOptionSelected = {
    from: [],
    to: []
  };
  formLoaded = false;
  rangeOptions: {
    animate: boolean; // Easier to see the single update
    floor: number;
    ceil: number;
    hideLimitLabels: boolean;
    hidePointerLabels: boolean;
    draggableRange: boolean;
  };
  unsubscribe$: Subject<void> = new Subject();
  priceValueDebounce$: Subject<any> = new Subject();
  resetFormListener$: Subject<void> = new Subject();
  constructor(
    private _formBuilder: FormBuilder,
    private _flightFilterAvailableFacade: FlightFilterAvailableFacade,
    private _translateService: TranslateService,
    private _updates$: Actions
  ) {}

  ngOnInit() {
    if (this.type) this.createForm(this.type);
    if (this.name === 'time') {
      const allTime = this.filterOptions.concat(this.filterOptions);
      allTime.forEach(filterOption => {
        this.filters.push(new FormControl(false));
      });
    }
    if (this.filterOptions) {
      this.formValueChangeListener();
    }
    this._updates$
      .pipe(
        takeUntil(this.unsubscribe$),
        ofType(FlightFilterAvailableActionTypes.SelectDeparture)
      )
      .subscribe(updates => {
        this.resetFormListener$.next();
        this.resetFormListener$.complete();
        this.resetForm();
      });
    if (this.name === 'price') {
      this.priceValueDebounce$
        .pipe(
          debounceTime(500),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(value => {
          this.filterForm.get('filter').setValue(value);
        });
    }
  }

  get filters() {
    return (<FormArray>this.filterForm.get('filter')) as FormArray;
  }

  createForm(type: 'checkbox' | 'radio' | 'range' | 'timeRange') {
    this.filterForm = this._formBuilder.group({
      filter: type === 'checkbox' ? new FormArray([]) : new FormControl()
    });
  }

  resetForm() {
    this.filterForm.get('filter').reset();

    this.resetFormListener$ = new Subject();
    if (this.name === 'price') {
      this.rangeValue = this.priceRange.max;
    }
    this.formValueChangeListener(true);
  }

  formValueChangeListener(isReset?: boolean) {
    let skipTime = this.name === 'time' || this.name === 'price' ? 0 : 1;
    // if (isReset) skipTime = 0;
    this.filterForm
      .get('filter')
      .valueChanges.pipe(
        debounceTime(100),
        skip(skipTime),
        takeUntil(this.resetFormListener$)
      )
      .subscribe(valueChange => {
        if (!valueChange || !this.formLoaded) return null;
        if (Array.isArray(valueChange) && this.name !== 'time') {
          this.optionSelected = [];
          valueChange.forEach((value, index) => {
            if (value)
              this.optionSelected.push(this.filterOptions[index].value);
          });
        }
        if (this.name == 'time') {
          this.timeOptionSelected = {
            from: [],
            to: []
          };
          valueChange.forEach((value, index) => {
            if (index > this.filterOptions.length - 1) {
              if (value)
                this.timeOptionSelected.to.push(
                  this.filterOptions[index % this.filterOptions.length].value
                );
            } else {
              if (value)
                this.timeOptionSelected.from.push(
                  this.filterOptions[index].value
                );
            }
          });
        }

        switch (this.name) {
          case 'airlineName':
            this._flightFilterAvailableFacade.changeAirlineOptions(
              this.optionSelected
            );
            break;
          case 'cabinName':
            this._flightFilterAvailableFacade.changeCabinClassOptions(
              this.optionSelected
            );
            break;
          case 'stopName':
            this._flightFilterAvailableFacade.changeStopOptions(
              this.optionSelected
            );
            break;
          case 'price':
            this._flightFilterAvailableFacade.changePrice(
              this.priceRange.min,
              valueChange
            );
            break;
          case 'time':
            this.typeChanged === 'departure'
              ? this._flightFilterAvailableFacade.changeDepartureDateTimeOptions(
                  this.timeOptionSelected.from
                )
              : this._flightFilterAvailableFacade.changeArrivalDateTimeOptions(
                  this.timeOptionSelected.to
                );
            break;
        }
      });
  }

  displayTime(timeArr: string[]) {
    const allTimeSelected = [];
    timeArr.forEach((value, index) => {
      if (value) allTimeSelected.push(this.filterOptions[index].name);
    });
    return allTimeSelected.length == this.filterOptions.length ||
      !allTimeSelected.length
      ? this._translateService.instant('newFlight.filter.allTime')
      : allTimeSelected
          .map(timeSelected => this._translateService.instant(timeSelected))
          .join(', ');
  }

  rangeChanged(event: any) {
    this.priceValueDebounce$.next(event.value);
  }

  displayCheckboxName(name: string, filter: any) {
    switch (name) {
      case 'airlineName':
        return filter.name;
      case 'cabinName':
        return this._translateService.instant('airline.' + filter.value);
      case 'stopName':
        return filter.value == 0 ? 'Bay thẳng' : `${filter.value} điểm dừng`;
    }
  }

  makeRangeOptions(min: number, max: number) {
    return {
      animate: false, // Easier to see the single update
      floor: min,
      ceil: max,
      hideLimitLabels: true,
      hidePointerLabels: true,
      draggableRange: false
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes || !this.filterForm) {
      return null;
    }
    if (
      this.type === 'checkbox' &&
      this.name !== 'time' &&
      changes.flightFilterLoaded &&
      changes.flightFilterLoaded.currentValue
    ) {
      this.filterOptions.forEach(filterOption => {
        this.filters.push(new FormControl(false));
      });
    }
    if (
      this.type === 'range' &&
      changes.priceRange &&
      changes.priceRange.currentValue
    ) {
      const priceRange = changes.priceRange.currentValue;
      this.rangeOptions = this.makeRangeOptions(priceRange.min, priceRange.max);
      this.rangeValue = priceRange.max;
    }
    this.formLoaded = true;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
