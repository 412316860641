import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExchangeOptionService {
  optionSubject = new BehaviorSubject('CASH');
  constructor() {}

  get option$() {
    return this.optionSubject.asObservable();
  }
  set option(option: string) {
    this.optionSubject.next(option);
  }
}
