import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTFILTERAVAILABLE_FEATURE_KEY,
  FlightFilterAvailableState
} from './flight-filter-available.reducer';

// Lookup the 'FlightFilterAvailable' feature state managed by NgRx
const getFlightFilterAvailableState = createFeatureSelector<
  FlightFilterAvailableState
>(FLIGHTFILTERAVAILABLE_FEATURE_KEY);

const getAllFlightFilterAvailable = createSelector(
  getFlightFilterAvailableState,
  (state: FlightFilterAvailableState) => state
);

const getCurrentAirlineOptions = createSelector(
  getFlightFilterAvailableState,
  (state: FlightFilterAvailableState) => state.airlineOptions
);

const getCurrentTicketPolicyOptions = createSelector(
  getFlightFilterAvailableState,
  (state: FlightFilterAvailableState) => state.ticketPolicyOptions
);

const getCurrentCabinClassOptions = createSelector(
  getAllFlightFilterAvailable,
  (state: FlightFilterAvailableState) => state.cabinClassOptions
);

const getCurrentSort = createSelector(
  getFlightFilterAvailableState,
  (state: FlightFilterAvailableState) => state.sort
);

const getCurrentArrivalDateTimeReturnOptions = createSelector(
  getFlightFilterAvailableState,
  (state: FlightFilterAvailableState) => state.arrivalDateTimeReturnOptions
);

const getCurrentArrivalDateTimeOptions = createSelector(
  getFlightFilterAvailableState,
  (state: FlightFilterAvailableState) => state.departureDateTimeOptions
);

const getSearchID = createSelector(
  getFlightFilterAvailableState,
  (state: FlightFilterAvailableState) => state.searchID
);

export const flightFilterAvailableQuery = {
  getAllFlightFilterAvailable,
  getCurrentAirlineOptions,
  getCurrentTicketPolicyOptions,
  getCurrentCabinClassOptions,
  getCurrentSort,
  getCurrentArrivalDateTimeOptions,
  getCurrentArrivalDateTimeReturnOptions,
  getSearchID
};
