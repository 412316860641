import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceToPercent'
})
export class PriceToPercentPipe implements PipeTransform {
  transform(price: number, basePriceBeforePromo: number) {
    let percent = 100 - (price * 100) / basePriceBeforePromo;
    return Math.round(percent);
  }
}
