import { Action } from '@ngrx/store';
import { Entity } from './hotel-booking.reducer';
import { CheckoutPayload } from '@gtd/b2c-client';

export enum HotelBookingActionTypes {
  LoadHotelBooking = '[HotelBooking] Load HotelBooking',
  ResetHotelBooking = '[HotelBooking] Reset HotelBooking',
  HotelBookingLoaded = '[HotelBooking] HotelBooking Loaded',
  HotelBookingLoadError = '[HotelBooking] HotelBooking Load Error'
}

export class LoadHotelBooking implements Action {
  readonly type = HotelBookingActionTypes.LoadHotelBooking;
  constructor(public payload: CheckoutPayload) {}
}

export class ResetHotelBooking implements Action {
  readonly type = HotelBookingActionTypes.ResetHotelBooking;
}

export class HotelBookingLoadError implements Action {
  readonly type = HotelBookingActionTypes.HotelBookingLoadError;
  constructor(public payload: any) {}
}

export class HotelBookingLoaded implements Action {
  readonly type = HotelBookingActionTypes.HotelBookingLoaded;
  constructor(public payload: CheckoutPayload) {}
}

export type HotelBookingAction =
  | LoadHotelBooking
  | ResetHotelBooking
  | HotelBookingLoaded
  | HotelBookingLoadError;

export const fromHotelBookingActions = {
  LoadHotelBooking,
  ResetHotelBooking,
  HotelBookingLoaded,
  HotelBookingLoadError
};
