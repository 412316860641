import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  partnerSubject = new BehaviorSubject<string>('GOTADI');
  constructor() {}

  get partner$() {
    return this.partnerSubject.asObservable();
  }

  set partner(partner: string) {
    this.partnerSubject.next(partner);
  }
}
