import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlightUnsubscribeService {
  unsubscribeSubject$ = new Subject();
  unsubscribe$ = this.unsubscribeSubject$.asObservable();
  constructor() {}
}
