import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELCREATEDRAFTBOOKING_FEATURE_KEY,
  HotelCreateDraftBookingState
} from './hotel-create-draft-booking.reducer';

// Lookup the 'HotelCreateDraftBooking' feature state managed by NgRx
const getHotelCreateDraftBookingState = createFeatureSelector<
  HotelCreateDraftBookingState
>(HOTELCREATEDRAFTBOOKING_FEATURE_KEY);

const getLoaded = createSelector(
  getHotelCreateDraftBookingState,
  (state: HotelCreateDraftBookingState) => state.loaded
);
const getError = createSelector(
  getHotelCreateDraftBookingState,
  (state: HotelCreateDraftBookingState) => state.error
);

const getAllHotelCreateDraftBooking = createSelector(
  getHotelCreateDraftBookingState,
  getLoaded,
  (state: HotelCreateDraftBookingState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getHotelCreateDraftBookingState,
  (state: HotelCreateDraftBookingState) => state.selectedId
);
const getSelectedHotelCreateDraftBooking = createSelector(
  getAllHotelCreateDraftBooking,
  getSelectedId,
  (hotelCreateDraftBooking, id) => {
    const result = hotelCreateDraftBooking;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const hotelCreateDraftBookingQuery = {
  getLoaded,
  getError,
  getAllHotelCreateDraftBooking,
  getSelectedHotelCreateDraftBooking
};
