/**
 * metasrv API
 * metasrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { OtaResultOfstring } from '../model/otaResultOfstring';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EmailTemplateResourceService {

    protected basePath = 'https://10.7.71.20:8286';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * createAgencyEmailTmpl
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAgencyEmailTmplUsingPOST(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public createAgencyEmailTmplUsingPOST(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public createAgencyEmailTmplUsingPOST(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public createAgencyEmailTmplUsingPOST(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling createAgencyEmailTmplUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<string>>(`${this.basePath}/api/email-tmpl/agency/${encodeURIComponent(String(agencyCode))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * deleteAgencyEmailTemplate
     * 
     * @param agencyCode agencyCode
     * @param tmplName tmplName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAgencyEmailTemplateUsingDELETE(agencyCode: string, tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public deleteAgencyEmailTemplateUsingDELETE(agencyCode: string, tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public deleteAgencyEmailTemplateUsingDELETE(agencyCode: string, tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public deleteAgencyEmailTemplateUsingDELETE(agencyCode: string, tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling deleteAgencyEmailTemplateUsingDELETE.');
        }

        if (tmplName === null || tmplName === undefined) {
            throw new Error('Required parameter tmplName was null or undefined when calling deleteAgencyEmailTemplateUsingDELETE.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<OtaResultOfstring>(`${this.basePath}/api/email-tmpl/agency/${encodeURIComponent(String(agencyCode))}/${encodeURIComponent(String(tmplName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAgencyEmailTemplate
     * 
     * @param agencyCode agencyCode
     * @param tmplName tmplName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAgencyEmailTemplateUsingGET(agencyCode: string, tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public getAgencyEmailTemplateUsingGET(agencyCode: string, tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public getAgencyEmailTemplateUsingGET(agencyCode: string, tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public getAgencyEmailTemplateUsingGET(agencyCode: string, tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling getAgencyEmailTemplateUsingGET.');
        }

        if (tmplName === null || tmplName === undefined) {
            throw new Error('Required parameter tmplName was null or undefined when calling getAgencyEmailTemplateUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfstring>(`${this.basePath}/api/email-tmpl/agency/${encodeURIComponent(String(agencyCode))}/${encodeURIComponent(String(tmplName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getEmailTmplKeys
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailTmplKeysUsingGET(observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public getEmailTmplKeysUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public getEmailTmplKeysUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public getEmailTmplKeysUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/email-tmpl/keys`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getGotadiEmailTemplate
     * 
     * @param tmplName tmplName
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGotadiEmailTemplateUsingGET(tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'body', reportProgress?: boolean): Observable<OtaResultOfstring>;
    public getGotadiEmailTemplateUsingGET(tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OtaResultOfstring>>;
    public getGotadiEmailTemplateUsingGET(tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OtaResultOfstring>>;
    public getGotadiEmailTemplateUsingGET(tmplName: 'B2B_ACTIVATION_EMAIL' | 'B2B_ADDITIONAL_REQUEST_EMAIL' | 'B2B_CHANGED_PASSWORD_EMAIL' | 'B2B_CREATION_EMAIL' | 'B2B_PASSWORD_RESET_EMAIL' | 'B2B_CREATION_EMAIL_SA' | 'B2B_CREATION_EMAIL_CA' | 'B2B_CREATION_EMAIL_CN' | 'B2C_ACTIVATION_EMAIL' | 'B2C_CREATION_EMAIL' | 'B2C_PASSWORD_RESET_EMAIL' | 'B2C_PASSWORD_RESET_FINISH_EMAIL' | 'B2C_SOCIAL_REGISTRATION_VALIDATION_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_EMAIL' | 'BOOKING_FLIGHT_PAYMENT_PDF_EMAIL' | 'BOOKING_HOTEL_PAYMENT_EMAIL' | 'BOOKING_HOTEL_PAYMENT_PDF_EMAIL' | 'BOOKING_COMBO_PAYMENT_EMAIL' | 'BOOKING_COMBO_PAYMENT_PDF_EMAIL' | 'FOLLOW_BOOKED_LIMIT_EMAIL' | 'HOTEL_INVENTORY_FORM_EMAIL' | 'B2B_ABACUS_TICKET_IN_PROCESS' | 'B2B_EXPEDIA_HOTEL_IN_PROCESS' | 'B2B_COMBO_TICKET_IN_PROCESS' | 'VISA_NOTIFICATION_EMAIL' | 'VISA_NOTIFICATION_TRAVELER_EMAIL', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tmplName === null || tmplName === undefined) {
            throw new Error('Required parameter tmplName was null or undefined when calling getGotadiEmailTemplateUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<OtaResultOfstring>(`${this.basePath}/api/email-tmpl/gtd/${encodeURIComponent(String(tmplName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * loadAgencyEmailTmplToCache
     * 
     * @param agencyCode agencyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadAgencyEmailTmplToCacheUsingGET(agencyCode: string, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public loadAgencyEmailTmplToCacheUsingGET(agencyCode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public loadAgencyEmailTmplToCacheUsingGET(agencyCode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public loadAgencyEmailTmplToCacheUsingGET(agencyCode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (agencyCode === null || agencyCode === undefined) {
            throw new Error('Required parameter agencyCode was null or undefined when calling loadAgencyEmailTmplToCacheUsingGET.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<number>(`${this.basePath}/api/email-tmpl/load-agency-to-cache/${encodeURIComponent(String(agencyCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * loadAllEmailTmplToCache
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadAllEmailTmplToCacheUsingGET(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public loadAllEmailTmplToCacheUsingGET(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public loadAllEmailTmplToCacheUsingGET(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public loadAllEmailTmplToCacheUsingGET(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<number>(`${this.basePath}/api/email-tmpl/load-all-to-cache`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
