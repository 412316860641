import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTSEARCHFILTERAVAILABILITY_FEATURE_KEY,
  FlightSearchFilterAvailabilityState
} from './flight-search-filter-availability.reducer';

// Lookup the 'FlightSearchFilterAvailability' feature state managed by NgRx
const getFlightSearchFilterAvailabilityState = createFeatureSelector<
  FlightSearchFilterAvailabilityState
>(FLIGHTSEARCHFILTERAVAILABILITY_FEATURE_KEY);

const getLoaded = createSelector(
  getFlightSearchFilterAvailabilityState,
  (state: FlightSearchFilterAvailabilityState) => state.loaded
);
const getError = createSelector(
  getFlightSearchFilterAvailabilityState,
  (state: FlightSearchFilterAvailabilityState) => state.error
);

const getAllFlightSearchFilterAvailability = createSelector(
  getFlightSearchFilterAvailabilityState,
  getLoaded,
  (state: FlightSearchFilterAvailabilityState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getFlightSearchFilterAvailabilityState,
  (state: FlightSearchFilterAvailabilityState) => state.selectedId
);
const getSelectedFlightSearchFilterAvailability = createSelector(
  getAllFlightSearchFilterAvailability,
  getSelectedId,
  (flightSearchFilterAvailability, id) => {
    const result = flightSearchFilterAvailability;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const flightSearchFilterAvailabilityQuery = {
  getLoaded,
  getError,
  getAllFlightSearchFilterAvailability,
  getSelectedFlightSearchFilterAvailability
};
