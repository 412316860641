import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DatePipeComponent,
  DurationFormatComponent,
  NumberNightResult,
  TransitTimeComponent,
  TransitTimeRoomComponent
} from './pipes/all-pipes.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DurationFormatComponent,
    TransitTimeComponent,
    TransitTimeRoomComponent,
    NumberNightResult,
    DatePipeComponent
  ],
  exports: [
    DurationFormatComponent,
    TransitTimeComponent,
    TransitTimeRoomComponent,
    NumberNightResult,
    DatePipeComponent
  ]
})
export class AllPipesModule {}
