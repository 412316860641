import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELFILTERAVAILABLE_FEATURE_KEY,
  HotelFilterAvailableState
} from './hotel-filter-available.reducer';

// Lookup the 'HotelFilterAvailable' feature state managed by NgRx
const getHotelFilterAvailableState = createFeatureSelector<
  HotelFilterAvailableState
>(HOTELFILTERAVAILABLE_FEATURE_KEY);

const getAllHotelFilterAvailable = createSelector(
  getHotelFilterAvailableState,
  (state: HotelFilterAvailableState) => {
    return state;
  }
);
export const hotelFilterAvailableQuery = {
  getAllHotelFilterAvailable
};
