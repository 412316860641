import { AirLowFareFilterVM, AirLowFareSearchRS } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './flight-search-filter-availability.reducer';

export enum FlightSearchFilterAvailabilityActionTypes {
  LoadFlightSearchFilterAvailability = '[FlightSearchFilterAvailability] Load FlightSearchFilterAvailability',
  ResetFlightSearchFilterAvailability = '[FlightSearchFilterAvailability] Reset FlightSearchFilterAvailability',
  FlightSearchFilterAvailabilityLoaded = '[FlightSearchFilterAvailability] FlightSearchFilterAvailability Loaded',
  FlightSearchFilterAvailabilityLoadError = '[FlightSearchFilterAvailability] FlightSearchFilterAvailability Load Error'
}
export interface FlightFilterAvailable {
  filter?: AirLowFareFilterVM;
  includeEquivfare: boolean;
  page?: number;
  size?: number;
  sort?: Array<string>;
}
export class LoadFlightSearchFilterAvailability implements Action {
  readonly type =
    FlightSearchFilterAvailabilityActionTypes.LoadFlightSearchFilterAvailability;
  constructor(public payload: FlightFilterAvailable) {}
}

export class ResetFlightSearchFilterAvailability implements Action {
  readonly type =
    FlightSearchFilterAvailabilityActionTypes.ResetFlightSearchFilterAvailability;
}

export class FlightSearchFilterAvailabilityLoadError implements Action {
  readonly type =
    FlightSearchFilterAvailabilityActionTypes.FlightSearchFilterAvailabilityLoadError;
  constructor(public payload: any) {}
}

export class FlightSearchFilterAvailabilityLoaded implements Action {
  readonly type =
    FlightSearchFilterAvailabilityActionTypes.FlightSearchFilterAvailabilityLoaded;
  constructor(public payload: AirLowFareSearchRS) {}
}

export type FlightSearchFilterAvailabilityAction =
  | LoadFlightSearchFilterAvailability
  | ResetFlightSearchFilterAvailability
  | FlightSearchFilterAvailabilityLoaded
  | FlightSearchFilterAvailabilityLoadError;

export const fromFlightSearchFilterAvailabilityActions = {
  LoadFlightSearchFilterAvailability,
  ResetFlightSearchFilterAvailability,
  FlightSearchFilterAvailabilityLoaded,
  FlightSearchFilterAvailabilityLoadError
};
