import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourLoadingBoxComponent } from './tour-loading-box.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TourLoadingBoxComponent],
  exports: [TourLoadingBoxComponent]
})
export class TourLoadingBoxModule {}
