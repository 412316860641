import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatePlanListComponent } from './rate-plan-list.component';
import { RatePlanItemModule } from '@gtd/hotels/ui/rate-plan-item';

@NgModule({
  imports: [CommonModule, RatePlanItemModule],
  declarations: [RatePlanListComponent],
  exports: [RatePlanListComponent]
})
export class RatePlanListModule {}
