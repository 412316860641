import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFlightSearchLowFare from './flight-search-low-fare/flight-search-low-fare.reducer';
import { FlightSearchLowFareEffects } from './flight-search-low-fare/flight-search-low-fare.effects';
import { FlightSearchLowFareFacade } from './flight-search-low-fare/flight-search-low-fare.facade';
import * as fromFlightSearchFilterOptions from './flight-search-filter-options/flight-search-filter-options.reducer';
import { FlightSearchFilterOptionsEffects } from './flight-search-filter-options/flight-search-filter-options.effects';
import { FlightSearchFilterOptionsFacade } from './flight-search-filter-options/flight-search-filter-options.facade';
import * as fromFlightSearchFilterAvailability from './flight-search-filter-availability/flight-search-filter-availability.reducer';
import { FlightSearchFilterAvailabilityEffects } from './flight-search-filter-availability/flight-search-filter-availability.effects';
import { FlightSearchFilterAvailabilityFacade } from './flight-search-filter-availability/flight-search-filter-availability.facade';
import * as fromFlightFilterAvailable from './flight-filter-available/flight-filter-available.reducer';
import { FlightFilterAvailableFacade } from './flight-filter-available/flight-filter-available.facade';
import * as fromFlightBooking from './flight-booking/flight-booking.reducer';
import { FlightBookingFacade } from './flight-booking/flight-booking.facade';
import * as fromFlightAddBookingTraveller from './flight-add-booking-traveller/flight-add-booking-traveller.reducer';
import { FlightAddBookingTravellerEffects } from './flight-add-booking-traveller/flight-add-booking-traveller.effects';
import { FlightAddBookingTravellerFacade } from './flight-add-booking-traveller/flight-add-booking-traveller.facade';
import * as fromFlightCreateDraftBooking from './flight-create-draft-booking/flight-create-draft-booking.reducer';
import { FlightCreateDraftBookingEffects } from './flight-create-draft-booking/flight-create-draft-booking.effects';
import { FlightCreateDraftBookingFacade } from './flight-create-draft-booking/flight-create-draft-booking.facade';
import { AirTicketsResourceService, BASE_PATH } from '@gtd/b2c-client';
import { environment } from '@env/environment';
import * as fromFlightGroupItinerary from './flight-group-itinerary/flight-group-itinerary.reducer';
import { FlightGroupItineraryEffects } from './flight-group-itinerary/flight-group-itinerary.effects';
import { FlightGroupItineraryFacade } from './flight-group-itinerary/flight-group-itinerary.facade';
import * as fromFlightFarerules from './flight-farerules/flight-farerules.reducer';
import { FlightFarerulesEffects } from './flight-farerules/flight-farerules.effects';
import { FlightFarerulesFacade } from './flight-farerules/flight-farerules.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromFlightSearchLowFare.FLIGHTSEARCHLOWFARE_FEATURE_KEY,
      fromFlightSearchLowFare.reducer
    ),
    EffectsModule.forFeature([FlightSearchLowFareEffects]),
    StoreModule.forFeature(
      fromFlightSearchFilterOptions.FLIGHTSEARCHFILTEROPTIONS_FEATURE_KEY,
      fromFlightSearchFilterOptions.reducer
    ),
    EffectsModule.forFeature([FlightSearchFilterOptionsEffects]),
    StoreModule.forFeature(
      fromFlightSearchFilterAvailability.FLIGHTSEARCHFILTERAVAILABILITY_FEATURE_KEY,
      fromFlightSearchFilterAvailability.reducer
    ),
    EffectsModule.forFeature([FlightSearchFilterAvailabilityEffects]),
    StoreModule.forFeature(
      fromFlightFilterAvailable.FLIGHTFILTERAVAILABLE_FEATURE_KEY,
      fromFlightFilterAvailable.reducer
    ),
    StoreModule.forFeature(
      fromFlightBooking.FLIGHTBOOKING_FEATURE_KEY,
      fromFlightBooking.reducer
    ),
    StoreModule.forFeature(
      fromFlightAddBookingTraveller.FLIGHTADDBOOKINGTRAVELLER_FEATURE_KEY,
      fromFlightAddBookingTraveller.reducer
    ),
    EffectsModule.forFeature([FlightAddBookingTravellerEffects]),
    StoreModule.forFeature(
      fromFlightCreateDraftBooking.FLIGHTCREATEDRAFTBOOKING_FEATURE_KEY,
      fromFlightCreateDraftBooking.reducer
    ),
    EffectsModule.forFeature([FlightCreateDraftBookingEffects]),
    StoreModule.forFeature(
      fromFlightGroupItinerary.FLIGHTGROUPITINERARY_FEATURE_KEY,
      fromFlightGroupItinerary.reducer
    ),
    EffectsModule.forFeature([FlightGroupItineraryEffects]),
    StoreModule.forFeature(
      fromFlightFarerules.FLIGHTFARERULES_FEATURE_KEY,
      fromFlightFarerules.reducer
    ),
    EffectsModule.forFeature([FlightFarerulesEffects])
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    AirTicketsResourceService,
    FlightSearchLowFareFacade,
    FlightSearchFilterOptionsFacade,
    FlightSearchFilterAvailabilityFacade,
    FlightFilterAvailableFacade,
    FlightBookingFacade,
    FlightAddBookingTravellerFacade,
    FlightCreateDraftBookingFacade,
    FlightGroupItineraryFacade,
    FlightFarerulesFacade
  ]
})
export class FlightStoreModule {}
