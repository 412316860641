import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest } from 'rxjs';
import {MatDialog} from "@angular/material";
import {PopupRequestFormComponent} from "../popup-request-form/popup-request-form.component";
import {GaTrackingService} from "@gtd/ga-tracking";
import {Amenity, CancelPenalty, PropertyRoom} from "@gtd/b2c-client";
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';
import { RoomComponent } from '@gtd/hotels/ui/detail-dialog';

@Component({
  selector: 'gtd-rate-plan-item',
  templateUrl: './rate-plan-item.component.html',
  styleUrls: ['./rate-plan-item.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RatePlanItemComponent),
      multi: true
    }
  ]
})
export class RatePlanItemComponent implements OnInit, ControlValueAccessor {
  @Input() ratePlan: any;
  @Input() searchId: any;
  @Input() isLoading: boolean;
  @Input() hotelName: string;
  @Input() hotelAddress?: string;
  @Input() roomName: string;
  @Input() room: PropertyRoom;
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  searchParams: any;
  btnClick: boolean;
  priceOneNightPerOneRoom: number;
  priceBeforePromo: number;
  priceOnePerson: number;
  numberNight: number;
  numberPerson: number;
  additionPricePerson: number;
  flightBooking$ = this._comboSelectedService.flightListSelected$;
  hotelBooking$ = this._comboSelectedService.hotelSelected$;
  amenitiesWithoutDescription: Array<Amenity> = [];
  exchangeOption = 'CASH';

  startDate: string;
  endDate: string;


  constructor(
    private _comboSelectedService: ComboSelectedService,
    public dialog: MatDialog,
    private _gaTrackingService: GaTrackingService,
  ) {}

  ngOnInit() {
    this.searchParams = JSON.parse(localStorage.getItem('dataComboSearch'));
    this.countPriceOnePerson();
    // this.countPriceOneNightPerOneRoom();
    if(this.ratePlan) {
      this.amenitiesWithoutDescription = this.ratePlan.amenities.filter(
        amenity => amenity.id !== 'rate_description'
      );
    }
  }

  selectRoom() {
    this.isLoading = true;
    this._gaTrackingService.track('C_Search_Select');
    this.onChange(this.ratePlan);
    this.onTouched();
  }

  countPriceOnePerson() {
    combineLatest(this.flightBooking$, this.hotelBooking$).subscribe(data => {
      const [flight, hotel] = data;
      if (flight && hotel) {
        const hotelPrice = hotel.totalPrice;

        const roomPrice = this.ratePlan.totalPrice;

        this.numberPerson = JSON.parse(this.searchParams.rooms).reduce(
          (arr, cur) => {
            return (
              arr + cur.adultQuantity + cur.infantCount + cur.childQuantity
            );
          },
          0
        );

        const adultTicketPrice = flight.reduce((arr, cur) => {
          if(cur.itemFlight && cur.itemFlight.flightType === "INTERNATIONAL") {
            return (
              cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount / cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerTypeQuantities.quantity
            );
          } else {
            return (
              arr +
              cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
                .baseFare.amount +
              (cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
                .comboMarkup
                ? cur.pricedItinerary.airItineraryPricingInfo.adultFare
                  .passengerFare.comboMarkup.amount
                : 0) +
              cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
                .serviceTax.amount
            );
          }

        }, 0);
        this.additionPricePerson = Math.ceil(
          this.ratePlan.additionPrice / this.numberPerson
        );
        this.priceOnePerson = roomPrice / this.numberPerson + adultTicketPrice;

      }
    });
  }

  countPriceOneNightPerOneRoom() {
    this.numberNight = this.ratePlan.paxPrice[0].nightPrices.length;
    const numberRoom = this.ratePlan.paxPrice.length;
    this.priceOneNightPerOneRoom =
      Math.ceil(
        this.ratePlan.basePrice / (this.numberNight * numberRoom * 1000)
      ) * 1000;
    this.priceBeforePromo =
      Math.ceil(this.ratePlan.basePriceBeforePromo / 1000) * 1000;
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  writeValue(obj: any): void {}

  requestBook() {
    const dialogRef = this.dialog.open(PopupRequestFormComponent, {
      data: {
        ratePlan: this.ratePlan,
        hotelName: this.hotelName,
        roomName: this.roomName,
        hotelAddress: this.hotelAddress,
      },
      position: {
        top: '100px'
      },
      autoFocus: false,
      panelClass: 'popup-request-form',
      disableClose: true
    });
  }


  getCancelPelnaltiesDate(type: 'start' | 'end', cancelPenalty: CancelPenalty) {
    this.startDate = cancelPenalty.startDate
      .split(' ')[0]
      .replace('.999999999', '');
    this.endDate = cancelPenalty.endDate
      .split(' ')[0]
      .replace('.999999999', '');
    if (type == 'start') {
      return this.startDate;
    }
    return this.endDate;
  }

  openDetailDialog() {
    const dialogRef = this.dialog.open(RoomComponent, {
      data: {
        room: this.room,
        ratePlan: this.ratePlan,
        amenities: this.ratePlan.roomAmenities,
        exchangeType: this.exchangeOption,
        exchangeRate: 0.5,
        searchId: this.searchId,
        priceOnePerson: this.priceOnePerson,
        isCombo: true
      },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__fadeIn',
        'animate__faster',
        'hotel-detail-dialog',
        'room-dialog'
      ],
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectRoom();
      }
    });
  }

}
