import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { PromptUpdateService } from '@gtd/pwa';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { I18nService, untilDestroyed } from '../app/core';
import { merge, Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { filter, map, switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { AffiliatesFacade } from './home/affilates/get-booking-tags/affiliates.facade';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isPlatformBrowser } from '@angular/common';
import { GaTrackingService } from '@gtd/ga-tracking';
import {
  MAT_MENU_SCROLL_STRATEGY,
  MAT_SELECT_SCROLL_STRATEGY
} from '@angular/material';
import { BlockScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { I18NLanguage } from './core/i18n.enum';
import { LanguageService } from '@gtd/services';

export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'gtd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay]
    },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay]
    }
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Gotadi';
  subscription = new Subscription();
  aff_sid: string;
  loading = false;

  constructor(
    private promptUpdateService: PromptUpdateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private i18nService: I18nService,
    private translate: TranslateService,
    private cookieService: CookieService,
    private affiliatesFacade: AffiliatesFacade,
    private deviceService: DeviceDetectorService,
    private _gaTrackingService: GaTrackingService,
    private _languageService: LanguageService,
    private gaTrackingService: GaTrackingService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.translate.setDefaultLang('vi');
    if (isPlatformBrowser(this.platformId)) {
      try {
        let rClientTracking = localStorage.getItem('client-tracking');
        if (!rClientTracking) {
          rClientTracking = require('uuid').v4();
          localStorage.setItem('client-tracking', rClientTracking);
        }
        localStorage.setItem('appName', 'B2C');
      } catch (e) {}
      this.router.events.subscribe(evt => {
        if (evt instanceof NavigationStart) {
          // Show loading indicator
          if (evt.id === 1) {
            this.loading = true;
          }
        }
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        if (evt instanceof NavigationEnd) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            this.loading = false;
          }, 1000);
          this._gaTrackingService.track('page_view');
        }
        window.scrollTo(0, 0);
      });
    }
  }

  ngOnInit() {
    this.gaTrackingService.initialize();

    this.activatedRoute.queryParams.subscribe(param => {
      if (param.utm_source && param.utm_medium && param.utm_campaign) {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 1);
        this.cookieService.set(
          'campaign',
          JSON.stringify({
            source: param.utm_source,
            medium: param.utm_medium,
            campaign: param.utm_campaign
          }),
          expireDate
        );
      }

      const campaign = this.cookieService.get('campaign')
        ? JSON.parse(this.cookieService.get('campaign'))
        : null;

      if (param && param.utm_source) {
        this.cookieService.delete('aff_name');
        this.cookieService.delete('aff_sid');
        this.affiliatesFacade.getBookingTagsByName(
          param.utm_source.toUpperCase()
        );
        this.aff_sid = param.aff_sid ? param.aff_sid : '';
      }
      if (param.language) {
        this.i18nService.language = param.language;
      }
    });
    const defaultLanguage = localStorage.getItem('language');
    this._languageService.setLanguage(
      defaultLanguage ? defaultLanguage : environment.defaultLanguage
    );

    this.i18nService.init(
      defaultLanguage
        ? I18NLanguage[defaultLanguage]
        : I18NLanguage[environment.defaultLanguage],
      environment.supportedLanguages
    );
    const onNavigationEnd = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );
    this.subscription.add(
      this.affiliatesFacade.allAffilates$.subscribe(getBookingTags => {
        if (getBookingTags && getBookingTags.id) {
          const expireDate = new Date();
          expireDate.setDate(expireDate.getDate() + 30);
          this.cookieService.set(
            'aff_name',
            getBookingTags.tagName,
            expireDate
          );
          if (this.aff_sid)
            this.cookieService.set('aff_sid', this.aff_sid, expireDate);
        }
      })
    );
    merge(this.translate.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translate.instant(title));
        }
      });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
    this.subscription.unsubscribe();
  }
}
