import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightAddBookingTravellerPartialState } from './flight-add-booking-traveller.reducer';
import {
  LoadFlightAddBookingTraveller,
  FlightAddBookingTravellerLoaded,
  FlightAddBookingTravellerLoadError,
  FlightAddBookingTravellerActionTypes
} from './flight-add-booking-traveller.actions';

@Injectable()
export class FlightAddBookingTravellerEffects {
  @Effect() loadFlightAddBookingTraveller$ = this.dataPersistence.fetch(
    FlightAddBookingTravellerActionTypes.LoadFlightAddBookingTraveller,
    {
      run: (
        action: LoadFlightAddBookingTraveller,
        state: FlightAddBookingTravellerPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return new FlightAddBookingTravellerLoaded([]);
      },

      onError: (action: LoadFlightAddBookingTraveller, error) => {
        console.error('Error', error);
        return new FlightAddBookingTravellerLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      FlightAddBookingTravellerPartialState
    >
  ) {}
}
