import {Component, Input, OnInit} from '@angular/core';
import {slideInVertical} from '@gtd/helpers';
import {FilterOptionsFacade} from '../../../../../state/filter-options/filter-options.facade';
import {FilterAvailableFacade} from '../../../../../state/filter-available/filter-available.facade';

@Component({
  selector: 'gtd-sort-panel',
  templateUrl: './sort-panel.component.html',
  styleUrls: ['./sort-panel.component.scss'],
  animations: [slideInVertical]
})
export class SortPanelComponent implements OnInit {
  @Input() searchParams: any;

  loadedFilter$ = this.filterOptionsFacade.loaded$;
  tabIndex = 0;
  tabs = [
    { label: 'sort.hotel.recommended', sortField: 'order', sortOrder: 'DESC' },
    { label: 'sort.hotel.price', sortField: 'price', sortOrder: 'DESC' },
    { label: 'sort.hotel.rating', sortField: 'starRating', sortOrder: 'ASC' },
    { label: 'sort.hotel.review', sortField: 'guestRating', sortOrder: 'ASC' }
  ];


  constructor(
    private filterOptionsFacade: FilterOptionsFacade,
    private filterAvailableFacade: FilterAvailableFacade
  ) {}

  ngOnInit() {}


  indexChange(event: any) {
    this.tabIndex = event;
    if (this.tabs[event].sortField !== 'order') {
      this.tabs[event].sortOrder =
        this.tabs[event].sortOrder === 'DESC' ? 'ASC' : 'DESC';
    }
    console.log(this.tabs[event].sortField)
    this.filterAvailableFacade.changeSort(
      this.tabs[event].sortField as
        | 'order'
        | 'price'
        | 'starRating'
        | 'guestRating',
      this.tabs[event].sortOrder as 'DESC' | 'ASC'
    );
  }
}
