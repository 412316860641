import { AirFareRuleRQ, AirFareRuleRS } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './flight-farerules.reducer';

export enum FlightFarerulesActionTypes {
  LoadFlightFarerules = '[FlightFarerules] Load FlightFarerules',
  ResetFlightFarerules = '[FlightFarerules] Reset FlightFarerules',
  FlightFarerulesLoaded = '[FlightFarerules] FlightFarerules Loaded',
  FlightFarerulesLoadError = '[FlightFarerules] FlightFarerules Load Error'
}

export class LoadFlightFarerules implements Action {
  readonly type = FlightFarerulesActionTypes.LoadFlightFarerules;
  constructor(public payload: AirFareRuleRQ, public language: string) {}
}

export class ResetFlightFarerules implements Action {
  readonly type = FlightFarerulesActionTypes.ResetFlightFarerules;
}

export class FlightFarerulesLoadError implements Action {
  readonly type = FlightFarerulesActionTypes.FlightFarerulesLoadError;
  constructor(public payload: any) {}
}

export class FlightFarerulesLoaded implements Action {
  readonly type = FlightFarerulesActionTypes.FlightFarerulesLoaded;
  constructor(public payload: AirFareRuleRS) {}
}

export type FlightFarerulesAction =
  | LoadFlightFarerules
  | FlightFarerulesLoaded
  | FlightFarerulesLoadError
  | ResetFlightFarerules;

export const fromFlightFarerulesActions = {
  LoadFlightFarerules,
  FlightFarerulesLoaded,
  FlightFarerulesLoadError,
  ResetFlightFarerules
};
