import { GroupPricedItinerary } from '@gtd/b2c-client';
import {
  FlightBooking,
  FlightBookingAction,
  FlightBookingActionTypes
} from './flight-booking.actions';

export const FLIGHTBOOKING_FEATURE_KEY = 'flightBooking';

/**
 * Interface for the 'FlightBooking' data used in
 *  - FlightBookingState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FlightBookingState {
  from: FlightBooking;
  to: FlightBooking;
  roundType: GroupPricedItinerary.RoundTypeEnum;
  reset: boolean;
}

export interface FlightBookingPartialState {
  readonly [FLIGHTBOOKING_FEATURE_KEY]: FlightBookingState;
}

export const initialState: FlightBookingState = {
  from: null,
  to: null,
  roundType: null,
  reset: false
};

export function reducer(
  state: FlightBookingState = initialState,
  action: FlightBookingAction
): FlightBookingState {
  switch (action.type) {
    case FlightBookingActionTypes.SetFlightBooking: {
      if (!state.from) {
        state = {
          ...state,
          from: action.payload,
          reset: false
        };
      } else {
        state = {
          ...state,
          to: action.payload,
          reset: false
        };
      }
      break;
    }
    case FlightBookingActionTypes.SetRoundType: {
      state = {
        ...state,
        roundType: action.payload,
        reset: false
      };
      break;
    }
    case FlightBookingActionTypes.ResetFlightBooking: {
      state = {
        ...initialState,
        reset: false
      };
      break;
    }
    case FlightBookingActionTypes.ResetFlightWay: {
      if (action.payload == 'from') {
        state = { ...state, from: null, reset: true };
      } else {
        state = { ...state, to: null, reset: true };
      }
      break;
    }
  }
  return state;
}
