import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform} from '@angular/core';
import {Subscription} from 'rxjs';
import {FilterAvailableFacade} from '../../../../../../state/filter-available/filter-available.facade';
import {ActivatedRoute} from '@angular/router';
import {FilterAvailableState} from '../../../../../../state/filter-available/filter-available.reducer';

@Component({
  selector: 'gtd-price-panel',
  templateUrl: './price-panel.component.html',
  styleUrls: ['./price-panel.component.scss']
})
export class PricePanelComponent implements OnInit, OnDestroy {
  @Input() filterPrices: Array<any>;
  @Input() searchParams: any;
  @Input() currentFilterPrices: FilterAvailableState;
  @Output() valueOnChanging = new EventEmitter<any>(null);

  subscriptions = new Subscription();

  rangeOptions: {
    animate: boolean; // Easier to see the single update
    floor: number;
    ceil: number;
    hideLimitLabels: boolean;
    hidePointerLabels: boolean;
    draggableRange: boolean;
  };


  minApply: number;
  maxApply: number;
  value: number;
  highValue: number;
  valueChange: EventEmitter<any> = new EventEmitter<any>();
  highValueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private filterAvailableFacade: FilterAvailableFacade
  ) {}

  private _handleQueryFromParams(param) {
    if (param.filterPrice) {
      this.minApply = Math.floor(param.filterPrice.fromPrice / 1000);
      this.maxApply = Math.floor(param.filterPrice.toPrice / 1000);
    }
  }

  rangeChange(event) {
    this.filterAvailableFacade.changeFilterPrice(
      this.minApply,
      event.value !== 5000 ? event.value * 1000 : null
    );
  }



  ngOnInit() {
    if (this.filterPrices) {
      this.value = Math.floor(this.filterPrices[0].to / 1000);
      this.minApply = Math.floor(this.filterPrices[0].to / 1000);
      this.highValue = Math.ceil(this.filterPrices.slice(-1)[0].from / 1000);
      this.maxApply = Math.ceil(this.filterPrices.slice(-1)[0].from / 1000);
      this.rangeOptions = this.makeOptions();
    }
    if (this.currentFilterPrices && this.currentFilterPrices.filterPrice) {
      this.value = Math.floor(
        this.currentFilterPrices.filterPrice.fromPrice / 1000
      );
      this.minApply = Math.floor(
        this.currentFilterPrices.filterPrice.fromPrice / 1000
      );
      this.maxApply = Math.ceil(
        this.currentFilterPrices.filterPrice.toPrice / 1000
      );
      this.highValue = Math.ceil(
        this.currentFilterPrices.filterPrice.toPrice / 1000
      );
    }
    this.valueChange.subscribe(value => {
      this.value = value;
    });
    this.highValueChange.subscribe(value => {
      this.highValue = value;
    });
    setTimeout(() => {
      this.activatedRoute.queryParams.subscribe(param => {
        this._handleQueryFromParams(
          JSON.parse(decodeURIComponent(atob(param.params)))
        );
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  makeOptions() {
    return {
      animate: false, // Easier to see the single update
      floor: this.minApply,
      ceil: this.highValue,
      hideLimitLabels: true,
      hidePointerLabels: true,
      draggableRange: false
    };
  }

}
@Pipe({
  name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {
  numberChars = new RegExp('[^0-9]', 'g');
  transform(value: any): any {
    let temp;
    if (typeof value === 'string') {
      temp = Number(value.replace(this.numberChars, ''));
    } else {
      temp = value;
    }
    return Number(temp);
  }
}
