import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelSearchKeywordPartialState } from './hotel-search-keyword.reducer';
import { hotelSearchKeywordQuery } from './hotel-search-keyword.selectors';
import { LoadHotelSearchKeyword } from './hotel-search-keyword.actions';

@Injectable()
export class HotelSearchKeywordFacade {
  loaded$ = this.store.pipe(select(hotelSearchKeywordQuery.getLoaded));
  allHotelSearchKeyword$ = this.store.pipe(
    select(hotelSearchKeywordQuery.getAllHotelSearchKeyword)
  );
  selectedHotelSearchKeyword$ = this.store.pipe(
    select(hotelSearchKeywordQuery.getSelectedHotelSearchKeyword)
  );

  constructor(private store: Store<HotelSearchKeywordPartialState>) {}

  loadAll() {
    this.store.dispatch(new LoadHotelSearchKeyword());
  }
}
