import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlightNewTabService {
  private _flightNewTabSubject$ = new Subject();
  private _flightNewTab$ = this._flightNewTabSubject$.asObservable();
  constructor() {}

  setTab(newTab) {
    this._flightNewTabSubject$.next(newTab);
  }

  getTab() {
    return this._flightNewTab$;
  }
}
