import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupBooking, Room } from '@gtd/b2c-client';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'gtd-hotel-booking-info',
  templateUrl: './hotel-booking-info.component.html',
  styleUrls: ['./hotel-booking-info.component.scss']
})
export class HotelBookingInfoComponent implements OnInit {
  @Input() hotelBooking: any;
  @Input() roomBooking: any;
  @Input() searchParams: any;
  @Output() resetHotel = new EventEmitter<'hotel' | 'room'>();
  roomLength: number;
  hotelDetail; //TODO TRANSLATE
  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    if (this.searchParams) {
      this.roomLength = JSON.parse(this.searchParams.rooms).length;
    }
  }


  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }

  // openPopupPanelDetail() {
  //   this.dialog.open(DialogPanelComponent, {
  //     data: this.groupBooking,
  //     panelClass: 'dialog-panel',
  //     position: {
  //       top: '100px'
  //     }
  //   });
  // }

  // getPassengertotal(type) {
  //   let temp = 0;
  //   switch (type) {
  //     case 'child':
  //       for (let room of this.groupBooking.hotelAvailability.products[0]
  //         .rooms) {
  //         temp = temp + room.pax.childQuantity;
  //       }
  //       break;
  //     case 'adult':
  //       for (let room of this.groupBooking.hotelAvailability.products[0]
  //         .rooms) {
  //         temp = temp + room.pax.adultQuantity;
  //       }
  //       break;
  //   }
  //   return temp;
  // }
  resetHotelBooking(type: 'hotel' | 'room') {
    this.resetHotel.emit(type);
  }

  protected readonly counter = Array;
}
