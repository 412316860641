import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HotelImage } from '@gtd/b2c-client';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'gtd-galleries',
  templateUrl: './galleries.component.html',
  styleUrls: ['./galleries.component.scss']
})
export class GalleriesComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('thumb', { static: false }) thumb?: ElementRef;

  slideIndex = 0;
  config: SwiperConfigInterface = {
    slidesPerView: 1,
    spaceBetween: 15,
    // lazy: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.gallery-next',
      prevEl: '.gallery-prev'
    }
  };

  constructor(
    public dialogRef: MatDialogRef<GalleriesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      images: Array<HotelImage>;
      index: number;
    }
  ) {}

  ngOnInit(): void {
    if (this.data.index) {
      setTimeout(() => {
        this.swiper.directiveRef.setIndex(this.data.index);
      }, 100);
    }
  }

  close() {
    this.dialogRef.close();
  }

  slideChange(event) {
    const itemWidth = this.thumb.nativeElement.children[0].clientWidth + 2;

    const fullWidth = itemWidth * this.data.images.length;
    const center = 350;
    const centerDistanceFromLeft = center - itemWidth / 2;
    if (event !== 0) {
      let distanceFromLeft = event * itemWidth;
      if (distanceFromLeft > centerDistanceFromLeft) {
        const scrollAmount = centerDistanceFromLeft - distanceFromLeft;
        this.thumb.nativeElement.style.transform = `translateX(${scrollAmount}px)`;
      } else {
        this.thumb.nativeElement.style.transform = `translateX(0px)`;
      }
    } else {
      this.thumb.nativeElement.style.transform = `translateX(0px)`;
    }

    this.slideIndex = event;
  }
  thumbClick(index) {
    this.swiper.directiveRef.setIndex(index);
  }
}
