import { AirGroupPricedItineraryRS } from '@gtd/b2c-client';
import {
  FlightGroupItineraryAction,
  FlightGroupItineraryActionTypes
} from './flight-group-itinerary.actions';

export const FLIGHTGROUPITINERARY_FEATURE_KEY = 'flightGroupItinerary';

/**
 * Interface for the 'FlightGroupItinerary' data used in
 *  - FlightGroupItineraryState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FlightGroupItineraryState {
  list: AirGroupPricedItineraryRS; // list of FlightGroupItinerary; analogous to a sql normalized table
  selectedId?: string | number; // which FlightGroupItinerary record has been selected
  loaded: boolean; // has the FlightGroupItinerary list been loaded
  error?: any; // last none error (if any)
}

export interface FlightGroupItineraryPartialState {
  readonly [FLIGHTGROUPITINERARY_FEATURE_KEY]: FlightGroupItineraryState;
}

export const initialState: FlightGroupItineraryState = {
  list: null,
  loaded: false
};

export function reducer(
  state: FlightGroupItineraryState = initialState,
  action: FlightGroupItineraryAction
): FlightGroupItineraryState {
  switch (action.type) {
    case FlightGroupItineraryActionTypes.FlightGroupItineraryLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case FlightGroupItineraryActionTypes.LoadFlightGroupItinerary: {
      state = { ...initialState };
      break;
    }
    case FlightGroupItineraryActionTypes.ResetFlightGroupItinerary: {
      state = {
        ...initialState
      };
      break;
    }
  }
  return state;
}
