import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'durationFormat'
})
export class DurationFormatComponent implements PipeTransform {
  transform(millseconds: number, format?: any): any {
    return this.durationFilter(millseconds, format);
  }
  public durationFilter(millseconds, format) {
    let oneSecond = 1;
    let oneMinute = oneSecond * 1;
    let oneHour = oneMinute * 60;
    let oneDay = oneHour * 24;

    let seconds = Math.floor((millseconds % oneMinute) / oneSecond);
    let minutes = Math.floor((millseconds % oneHour) / oneMinute);
    let hours = Math.floor((millseconds % oneDay) / oneHour);
    let days = Math.floor(millseconds / oneDay);

    let timeString = '';

    if (format == 'short') {
      if (days !== 0) {
        timeString += days !== 1 ? days + 'd' : days + 'd';
      }
      if (hours !== 0) {
        timeString += hours !== 1 ? hours + 'h' : hours + 'h';
      }
      if (minutes !== 0) {
        timeString += minutes !== 1 ? minutes + 'm' : minutes + 'm';
      }
      return timeString;
    } else if (format == 'dayOnly') {
      if (days !== 0) {
        timeString += days !== 1 ? days : days;
      }
      return timeString;
    } else if (format == 'defvn') {
      if (days !== 0) {
        timeString += days !== 1 ? days + 'd' : days + 'd';
      }
      if (hours !== 0) {
        timeString += hours !== 1 ? hours + 'h' : hours + 'h';
      }
      if (minutes !== 0) {
        timeString += minutes !== 1 ? minutes + 'm' : minutes + 'm';
      }

      return timeString;
    } else {
      if (days !== 0) {
        timeString += days !== 1 ? days + ' ngày ' : days + ' ngày ';
      }
      if (hours !== 0) {
        timeString += hours !== 1 ? hours + ' giờ ' : hours + ' giờ ';
      }
      if (minutes !== 0) {
        timeString += minutes !== 1 ? minutes + ' phút ' : minutes + ' phút ';
      }
      return timeString;
    }
  }
}
@Pipe({
  name: 'transitTime'
})
export class TransitTimeComponent implements PipeTransform {
  transform(nextTime: Date, currentTime?: Date): any {
    return this.transitTime(currentTime, nextTime);
  }
  transitTime(currentTime, nextTime) {
    let one_Minute = 1000 * 60;
    let date1 = new Date(currentTime);
    let date2 = new Date(nextTime);
    let difference_ms = date2.getTime() - date1.getTime();
    const transitTime = DurationFormatComponent.prototype.durationFilter(
      Math.round(difference_ms / one_Minute),
      'short'
    );
    return transitTime;
  }
}

@Pipe({
  name: 'transitTimeRoom'
})
export class TransitTimeRoomComponent implements PipeTransform {
  transform(nextTime: Date, currentTime?: Date): any {
    return this.transitTime(currentTime, nextTime);
  }
  transitTime(currentTime, nextTime) {
    let one_Minute = 1000 * 60;
    let date1 = new Date(currentTime);
    let date2 = new Date(nextTime);
    let difference_ms = date2.getTime() - date1.getTime();
    const transitTime = DurationFormatComponent.prototype.durationFilter(
      Math.round(difference_ms / one_Minute),
      'dayOnly'
    );
    return transitTime;
  }
}
@Pipe({
  name: 'numberNight'
})
export class NumberNightResult implements PipeTransform {
  transform(departureDate: string, returnDate?: string): number {
    let numbDate = 0;
    if(departureDate && returnDate) {
      numbDate = this.datediff(this.parseDate(departureDate), this.parseDate(returnDate))
    }
    return numbDate;
  }
  parseDate(str) {
    let mdy = str.split('/');
    return new Date(mdy[2], mdy[0]-1, mdy[1]);
  }
  datediff(first, second) {
    return Math.round((second-first)/(1000*60*60*24));
  }
}

@Pipe({
  name: 'customDate'
})
export class DatePipeComponent extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    enum Day {
      'Monday' = 'T2',
      'Tuesday' = 'T3',
      'Wednesday' = 'T4',
      'Thursday' = 'T5',
      'Friday' = 'T6',
      'Saturday' = 'T7',
      'Sunday' = 'CN'
    }
    const time = super.transform(value, 'HH:mm', 'UTC');
    const day = Day[super.transform(value, 'EEEE', 'UTC')];
    const date = super.transform(value, 'dd/MM/yyyy', 'UTC');
    return `${time} ${day}, ${date}`;
  }
}
