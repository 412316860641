import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import {
  AirItineraryPricingInfo,
  GroupBooking,
  PaymentFeeOptionRes
} from '@gtd/b2c-client';
import { fadeIn } from '@gtd/helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gtd-combo-price-summary',
  templateUrl: './combo-price-summary.component.html',
  styleUrls: ['./combo-price-summary.component.scss'],
  animations: [fadeIn]
})
export class ComboPriceSummaryComponent implements OnInit {
  @Input() bookingDetail: GroupBooking;
  @Input() paymentFee: PaymentFeeOptionRes;
  @Input() totalSsrValue: number;
  @Input() insuranceFees: number;
  @Input() discount = 0;
  @Input() searchParams;
  hotelDetail;

  hotelOnePersonPrice: number;
  adultTicketPrice = 0;
  childTicketPrice = 0;
  infantTicketPrice = 0;
  numOfPerson = 0;
  @Input() flightBooking;
  @Input() hotelBooking;
  @Input() roomBooking;
  hotelBookingPrice;
  flightBookingPrice;
  subscriptions = new Subscription();
  isDetailPrice: boolean;
  panelOpenState: boolean;

  constructor() {}

  ngOnInit() {
    this.priceCalculator();
  }
  priceCalculator() {
    this.hotelBookingPrice = this.roomBooking.ratePlan.totalPrice;
    this.flightBookingPrice = this.flightBooking.reduce((arr, cur) => {
      if(cur.itemFlight && cur.itemFlight.flightType === "INTERNATIONAL") {
        return (
          cur.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare
            .amount
        );
      } else {
        return (
          arr +
          cur.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare
            .amount
        );
      }

    }, 0);
    this.numOfPerson = JSON.parse(this.searchParams.rooms).reduce(
      (arr, cur) => {
        return arr + cur.adultQuantity + cur.infantCount + cur.childQuantity;
      },
      0
    );
    if (this.searchParams.adult) {
      this.adultTicketPrice = this.flightBooking.reduce((arr, cur) => {
        if(cur.itemFlight && cur.itemFlight.flightType === "INTERNATIONAL") {
          return (
            cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .baseFare.amount +
            (cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .comboMarkup
              ? cur.pricedItinerary.airItineraryPricingInfo.adultFare
                .passengerFare.comboMarkup.amount
              : 0) +
            cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .serviceTax.amount
          );
        } else {
          return (
            arr +
            cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .baseFare.amount +
            (cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .comboMarkup
              ? cur.pricedItinerary.airItineraryPricingInfo.adultFare
                .passengerFare.comboMarkup.amount
              : 0) +
            cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .serviceTax.amount
          );
        }

      }, 0);
    }
    if (this.searchParams.child) {
      this.childTicketPrice = this.flightBooking.reduce((arr, cur) => {
        if(cur.itemFlight && cur.itemFlight.flightType === "INTERNATIONAL") {
          return (
            cur.pricedItinerary.airItineraryPricingInfo.childFare.passengerFare
              .baseFare.amount +
            (cur.pricedItinerary.airItineraryPricingInfo.childFare.passengerFare
              .comboMarkup
              ? cur.pricedItinerary.airItineraryPricingInfo.childFare
                .passengerFare.comboMarkup.amount
              : 0) +
            cur.pricedItinerary.airItineraryPricingInfo.childFare.passengerFare
              .serviceTax.amount
          );
        } else {
          return (
            arr +
            cur.pricedItinerary.airItineraryPricingInfo.childFare.passengerFare
              .baseFare.amount +
            (cur.pricedItinerary.airItineraryPricingInfo.childFare.passengerFare
              .comboMarkup
              ? cur.pricedItinerary.airItineraryPricingInfo.childFare
                .passengerFare.comboMarkup.amount
              : 0) +
            cur.pricedItinerary.airItineraryPricingInfo.childFare.passengerFare
              .serviceTax.amount
          );
        }
      }, 0);
    }
    if (this.searchParams.infant) {
      this.infantTicketPrice = this.flightBooking.reduce((arr, cur) => {
        if(cur.itemFlight && cur.itemFlight.flightType === "INTERNATIONAL") {
          return (
            cur.pricedItinerary.airItineraryPricingInfo.infantFare.passengerFare
              .baseFare.amount +
            (cur.pricedItinerary.airItineraryPricingInfo.infantFare.passengerFare
              .comboMarkup
              ? cur.pricedItinerary.airItineraryPricingInfo.infantFare
                .passengerFare.comboMarkup.amount
              : 0) +
            cur.pricedItinerary.airItineraryPricingInfo.infantFare.passengerFare
              .serviceTax.amount
          );
        } else {
          return (
            arr +
            cur.pricedItinerary.airItineraryPricingInfo.infantFare.passengerFare
              .baseFare.amount +
            (cur.pricedItinerary.airItineraryPricingInfo.infantFare.passengerFare
              .comboMarkup
              ? cur.pricedItinerary.airItineraryPricingInfo.infantFare
                .passengerFare.comboMarkup.amount
              : 0) +
            cur.pricedItinerary.airItineraryPricingInfo.infantFare.passengerFare
              .serviceTax.amount
          );
        }

      }, 0);
    }
    this.hotelOnePersonPrice = this.hotelBookingPrice / this.numOfPerson;
  }
}

export interface PriceAddition {
  totalSsr?: number;
  insuranceFees?: number;
  discount?: number;
  paymentFee?: PaymentFeeOptionRes;
}
