import { Action } from '@ngrx/store';
import { Entity } from './hotel-search-keyword.reducer';

export enum HotelSearchKeywordActionTypes {
  LoadHotelSearchKeyword = '[HotelSearchKeyword] Load HotelSearchKeyword',
  HotelSearchKeywordLoaded = '[HotelSearchKeyword] HotelSearchKeyword Loaded',
  HotelSearchKeywordLoadError = '[HotelSearchKeyword] HotelSearchKeyword Load Error'
}

export class LoadHotelSearchKeyword implements Action {
  readonly type = HotelSearchKeywordActionTypes.LoadHotelSearchKeyword;
}

export class HotelSearchKeywordLoadError implements Action {
  readonly type = HotelSearchKeywordActionTypes.HotelSearchKeywordLoadError;
  constructor(public payload: any) {}
}

export class HotelSearchKeywordLoaded implements Action {
  readonly type = HotelSearchKeywordActionTypes.HotelSearchKeywordLoaded;
  constructor(public payload: Entity[]) {}
}

export type HotelSearchKeywordAction =
  | LoadHotelSearchKeyword
  | HotelSearchKeywordLoaded
  | HotelSearchKeywordLoadError;

export const fromHotelSearchKeywordActions = {
  LoadHotelSearchKeyword,
  HotelSearchKeywordLoaded,
  HotelSearchKeywordLoadError
};
