import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailPoliciesComponent } from './detail-policies.component';
import { GTypographyModule } from '@gotadi/design-system';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule, GTypographyModule, TranslateModule],
  declarations: [DetailPoliciesComponent],
  exports: [DetailPoliciesComponent]
})
export class DetailPoliciesModule {}
