import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview/overview.component';
import { AmenitiesComponent } from './amenities/amenities.component';
import { RoomComponent } from './room/room.component';
import { GalleriesComponent } from './galleries/galleries.component';
import { MatDialogModule, MatIconModule } from '@angular/material';
import {
  GButtonModule,
  GMenuModule,
  GTypographyModule
} from '@gotadi/design-system';
import { PipesModule } from '@gtd/hotels/data-access/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'ngx-swiper-wrapper';
import {CurrencyExchangeModule} from "@gtd/pipes/currency-exchange";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatDialogModule,
        GTypographyModule,
        PipesModule,
        TranslateModule,
        SwiperModule,
        GButtonModule,
        MatIconModule,
        GMenuModule,
        PipesModule,
        CurrencyExchangeModule
    ],
  declarations: [
    OverviewComponent,
    AmenitiesComponent,
    RoomComponent,
    GalleriesComponent
  ],
  exports: [
    OverviewComponent,
    AmenitiesComponent,
    RoomComponent,
    GalleriesComponent
  ],
  entryComponents: [
    OverviewComponent,
    AmenitiesComponent,
    RoomComponent,
    GalleriesComponent
  ]
})
export class DetailDialogModule {}
