import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelCheckoutPartialState } from './hotel-checkout.reducer';
import {
  LoadHotelCheckout,
  HotelCheckoutLoaded,
  HotelCheckoutLoadError,
  HotelCheckoutActionTypes
} from './hotel-checkout.actions';
import { HotelResourceV3Service } from '@gtd/b2c-client';
import { first, map, tap } from 'rxjs/operators';
import { PartnerService } from '@gtd/lotus/shared/data-access/services';

@Injectable()
export class HotelCheckoutEffects {
  @Effect() loadHotelCheckout$ = this.dataPersistence.fetch(
    HotelCheckoutActionTypes.LoadHotelCheckout,
    {
      run: (action: LoadHotelCheckout, state: HotelCheckoutPartialState) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        this._partnerService.partner$
          .pipe(
            first(),
            tap(partner => {
              this.hotelResourceV3Service.defaultHeaders = this.hotelResourceV3Service.defaultHeaders.set(
                'sales-env',
                ''
              );
              if (partner === 'LOTUS') {
                this.hotelResourceV3Service.defaultHeaders = this.hotelResourceV3Service.defaultHeaders.set(
                  'sales-env',
                  'REWARD_EXCHANGE'
                );
              }
            })
          )
          .subscribe();
        return this.hotelResourceV3Service
          .checkoutUsingPOST(action.payload)
          .pipe(
            map(payload => ({
              type: HotelCheckoutActionTypes.HotelCheckoutLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadHotelCheckout, error) => {
        console.error('Error', error);
        return new HotelCheckoutLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<HotelCheckoutPartialState>,
    private hotelResourceV3Service: HotelResourceV3Service,
    private _partnerService: PartnerService
  ) {}
}
