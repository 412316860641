import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELSEARCHKEYWORD_FEATURE_KEY,
  HotelSearchKeywordState
} from './hotel-search-keyword.reducer';

// Lookup the 'HotelSearchKeyword' feature state managed by NgRx
const getHotelSearchKeywordState = createFeatureSelector<
  HotelSearchKeywordState
>(HOTELSEARCHKEYWORD_FEATURE_KEY);

const getLoaded = createSelector(
  getHotelSearchKeywordState,
  (state: HotelSearchKeywordState) => state.loaded
);
const getError = createSelector(
  getHotelSearchKeywordState,
  (state: HotelSearchKeywordState) => state.error
);

const getAllHotelSearchKeyword = createSelector(
  getHotelSearchKeywordState,
  getLoaded,
  (state: HotelSearchKeywordState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getHotelSearchKeywordState,
  (state: HotelSearchKeywordState) => state.selectedId
);
const getSelectedHotelSearchKeyword = createSelector(
  getAllHotelSearchKeyword,
  getSelectedId,
  (hotelSearchKeyword, id) => {
    const result = hotelSearchKeyword.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const hotelSearchKeywordQuery = {
  getLoaded,
  getError,
  getAllHotelSearchKeyword,
  getSelectedHotelSearchKeyword
};
