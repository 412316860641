import { Action } from '@ngrx/store';
import { Entity } from './hotel-search-params.reducer';

export enum HotelSearchParamsActionTypes {
  LoadHotelSearchParams = '[HotelSearchParams] Load HotelSearchParams',
  ResetHotelSearchParams = '[HotelSearchParams] Reset HotelSearchParams',
  HotelSearchParamsLoaded = '[HotelSearchParams] HotelSearchParams Loaded',
  HotelSearchParamsLoadError = '[HotelSearchParams] HotelSearchParams Load Error'
}

export class LoadHotelSearchParams implements Action {
  readonly type = HotelSearchParamsActionTypes.LoadHotelSearchParams;
  constructor(public payload: any) {}
}

export class ResetHotelSearchParams implements Action {
  readonly type = HotelSearchParamsActionTypes.ResetHotelSearchParams;
}
export class HotelSearchParamsLoadError implements Action {
  readonly type = HotelSearchParamsActionTypes.HotelSearchParamsLoadError;
  constructor(public payload: any) {}
}

export class HotelSearchParamsLoaded implements Action {
  readonly type = HotelSearchParamsActionTypes.HotelSearchParamsLoaded;
  constructor(public payload: any) {}
}

export type HotelSearchParamsAction =
  | LoadHotelSearchParams
  | ResetHotelSearchParams
  | HotelSearchParamsLoaded
  | HotelSearchParamsLoadError;

export const fromHotelSearchParamsActions = {
  LoadHotelSearchParams,
  ResetHotelSearchParams,
  HotelSearchParamsLoaded,
  HotelSearchParamsLoadError
};
