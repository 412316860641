import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PropertyAllRate } from '@gtd/b2c-client';
import {
  AmenitiesComponent,
  OverviewComponent
} from '@gtd/hotels/ui/detail-dialog';
import { MapViewDetailComponent } from 'libs/hotels/ui/map-view-detail/src/lib/map-view-detail.component';

@Component({
  selector: 'gtd-detail-overview',
  templateUrl: './detail-overview.component.html',
  styleUrls: ['./detail-overview.component.scss']
})
export class DetailOverviewComponent implements OnInit {
  @Input() detail: PropertyAllRate;
  constructor(private _dialog: MatDialog) {}

  ngOnInit() {}

  openOverviewDialog() {
    this._dialog.open(OverviewComponent, {
      data: { descriptions: this.detail.descriptions },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__fadeIn',
        'animate__faster',
        'hotel-detail-dialog'
      ],
      disableClose: true
    });
  }

  openMapDialog() {
    // window.open('http://maps.google.com/maps?z=20&t=m&q=loc:'+this.detail.latitude+'+'+this.detail.longitude, '_blank').focus();
    this._dialog.open(MapViewDetailComponent, {
      data: {
        hotelDetail: this.detail
      },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__fadeIn',
        'animate__faster',
        'hotel-detail-dialog',
        'hotel-detail-map'
      ]
    });
  }

  openAmenitiesDialog() {
    this._dialog.open(AmenitiesComponent, {
      data: { amenities: this.detail.amenities },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__fadeIn',
        'animate__faster',
        'hotel-detail-dialog'
      ],
      disableClose: true
    });
  }
  checkEmpty(descriptions: any[]) {
    return descriptions.length && descriptions[0].value
  }
}
