import {
  FlightFilterAction,
  FlightFilterActionTypes
} from './flight-filter.actions';
import {
  DEFAULT_PAGINATION_OFFSET,
  DEFAULT_SORT, DEFAULT_SORT_COMBO,
  defaultFilter
} from '@gtd/helpers';
import { AirItineraryInfo, ItineraryFilter } from '@gtd/b2c-client';

export const FLIGHTFILTER_FEATURE_KEY = 'comboVJFlightFilter';

export interface FlightFilterState extends ItineraryFilter {
  page?: number;
  size?: number;
  sort?: Array<string>;
  searchID?: string;
  departureItinerary: AirItineraryInfo;
}

export interface SelectDepartureItem {
  searchID?: string;
  departureItinerary?: AirItineraryInfo;
}

export interface FlightFilterPartialState {
  readonly [FLIGHTFILTER_FEATURE_KEY]: FlightFilterState;
}

export function getInitial() {
  return {
    ...defaultFilter,
    page: 0,
    size: DEFAULT_PAGINATION_OFFSET,
    sort: DEFAULT_SORT_COMBO,
    searchID: '',
    departureItinerary: null
  };
}

export const initialState: FlightFilterState = getInitial();

export function flightFilterReducer(
  state: FlightFilterState = initialState,
  action: FlightFilterAction
): FlightFilterState {
  switch (action.type) {
    case FlightFilterActionTypes.ComboVJResetFlightFilter: {
      state = { ...initialState };
      break;
    }
    case FlightFilterActionTypes.ComboVJResetFlightFilterRoundTrip: {
      initialState.searchID = action.payload.searchID;
      initialState.flightType = action.payload.flightType;
      initialState.departureItinerary = action.payload.departureItinerary;
      state = { ...initialState };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeAirlineOptions: {
      state = {
        ...state,
        airlineOptions: action.payload.slice(),
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeArrivalDateTimeOptions: {
      state = {
        ...state,
        arrivalDateTimeOptions: action.payload.slice(),
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeArrivalDateTimeReturnOptions: {
      state = {
        ...state,
        arrivalDateTimeReturnOptions: action.payload.slice(),
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeCabinClassOptions: {
      state = {
        ...state,
        cabinClassOptions: action.payload.slice(),
        page: 0
      };

      break;
    }
    case FlightFilterActionTypes.ComboVJChangeDepartureDateTimeOptions: {
      state = {
        ...state,
        departureDateTimeOptions: action.payload.slice(),
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeFlightType: {
      state = {
        ...state,
        flightType: action.payload,
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeStep: {
      state = {
        ...state,
        step: action.payload
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeStopOptions: {
      state = {
        ...state,
        stopOptions: action.payload.slice(),
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeTicketPolicyOptions: {
      state = {
        ...state,
        ticketPolicyOptions: action.payload.slice(),
        page: 0
      };
      break;
    }

    case FlightFilterActionTypes.ComboVJChangeFlightPaginationPage: {
      state = {
        ...state,
        page: action.payload
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeFlightPaginationSize: {
      state = {
        ...state,
        size: action.payload,
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeFlightPaginationSort: {
      state = {
        ...state,
        sort: action.payload.slice(),
        page: 0
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJChangeSearchID: {
      state = {
        ...state,
        searchID: action.payload
      };
      break;
    }
    case FlightFilterActionTypes.ComboVJSelectDeparture: {
      state = {
        ...state,
        airlineOptions: [],
        searchID: action.payload.searchID,
        departureItinerary: action.payload.departureItinerary,
        page: 0
      };
    }
  }
  return state;
}
