import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOTELCHECKOUT_FEATURE_KEY,
  HotelCheckoutState
} from './hotel-checkout.reducer';

// Lookup the 'HotelCheckout' feature state managed by NgRx
const getHotelCheckoutState = createFeatureSelector<HotelCheckoutState>(
  HOTELCHECKOUT_FEATURE_KEY
);

const getLoaded = createSelector(
  getHotelCheckoutState,
  (state: HotelCheckoutState) => state.loaded
);
const getError = createSelector(
  getHotelCheckoutState,
  (state: HotelCheckoutState) => state.error
);

const getAllHotelCheckout = createSelector(
  getHotelCheckoutState,
  getLoaded,
  (state: HotelCheckoutState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getHotelCheckoutState,
  (state: HotelCheckoutState) => state.selectedId
);
const getSelectedHotelCheckout = createSelector(
  getAllHotelCheckout,
  getSelectedId,
  (hotelCheckout, id) => {
    const result = hotelCheckout;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const hotelCheckoutQuery = {
  getLoaded,
  getError,
  getAllHotelCheckout,
  getSelectedHotelCheckout
};
