import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import {
  ChangeAirlineOptions,
  ChangeArrivalDateTimeOptions,
  ChangeArrivalDateTimeReturnOptions,
  ChangeCabinClassOptions,
  ChangeDepartureDateTimeOptions,
  ChangeFlightPaginationPage,
  ChangeFlightPaginationSort,
  ChangeFlightType,
  ChangePrice,
  ChangeSearchID,
  ChangeStep,
  ChangeStopOptions,
  ChangeTicketPolicyOptions,
  ResetFlightFilterAvailable,
  ResetFlightFilterAvailableRoundTrip,
  SelectDeparture
} from './flight-filter-available.actions';

import {
  FlightFilterAvailablePartialState,
  SelectDepartureItem
} from './flight-filter-available.reducer';
import { flightFilterAvailableQuery } from './flight-filter-available.selectors';

@Injectable()
export class FlightFilterAvailableFacade {
  allFlightFilterAvailable$ = this.store.pipe(
    select(flightFilterAvailableQuery.getAllFlightFilterAvailable)
  );

  constructor(private store: Store<FlightFilterAvailablePartialState>) {}

  reset() {
    this.store.dispatch(new ResetFlightFilterAvailable());
  }

  resetFilterRoundTrip(searchID: string, flightType: string) {
    this.store.dispatch(
      new ResetFlightFilterAvailableRoundTrip({
        searchID: searchID,
        flightType: flightType
      })
    );
  }

  changeSearchID(searchID: string) {
    this.store.dispatch(new ChangeSearchID(searchID));
  }

  selectDeparture(selectDepartureItem: SelectDepartureItem) {
    this.store.dispatch(new SelectDeparture(selectDepartureItem));
  }

  changeAirlineOptions(selectedAirLine: string[]) {
    this.store.dispatch(new ChangeAirlineOptions(selectedAirLine));
  }

  changeArrivalDateTimeOptions(selectedArrivalDateTimeOptions: string[]) {
    this.store.dispatch(
      new ChangeArrivalDateTimeOptions(selectedArrivalDateTimeOptions)
    );
  }

  changeArrivalDateTimeReturnOptions(
    selectedArrivalDateTimeReturnOptions: string[]
  ) {
    this.store.dispatch(
      new ChangeArrivalDateTimeReturnOptions(
        selectedArrivalDateTimeReturnOptions
      )
    );
  }

  changeCabinClassOptions(selectedCabinClassOptions: string[]) {
    this.store.dispatch(new ChangeCabinClassOptions(selectedCabinClassOptions));
  }

  changeDepartureDateTimeOptions(selectedDepartureDateTimeOptions: string[]) {
    this.store.dispatch(
      new ChangeDepartureDateTimeOptions(selectedDepartureDateTimeOptions)
    );
  }

  changeFlightType(selectedFlightType: string) {
    this.store.dispatch(new ChangeFlightType(selectedFlightType));
  }

  changeStep(selectedStep: string) {
    this.store.dispatch(new ChangeStep(selectedStep));
  }

  changeStopOptions(selectedStopOptions: string[]) {
    this.store.dispatch(new ChangeStopOptions(selectedStopOptions));
  }

  changeTicketPolicyOptions(selectedTicketPolicyOptions: string[]) {
    this.store.dispatch(
      new ChangeTicketPolicyOptions(selectedTicketPolicyOptions)
    );
  }

  changeSort(sort: string[]) {
    this.store.dispatch(new ChangeFlightPaginationSort(sort));
  }

  changePage(page: number) {
    this.store.dispatch(new ChangeFlightPaginationPage(page));
  }

  changePrice(fromPrice: number, toPrice: number) {
    this.store.dispatch(new ChangePrice({ fromPrice, toPrice }));
  }
}
