import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {ActivatedRoute} from "@angular/router";
import {ContactFormsService} from "@gtd/api-services/contentsrv";
import {SnackbarComponent} from "@gtd/components/snackbar";
import {DialogMessageComponent} from "@gtd/components/dialog-message";
import {ComboSelectedService} from "../../../../../../combo-selected.service";
import * as moment from "moment";

@Component({
  selector: 'gtd-popup-request-form',
  templateUrl: './popup-request-form.component.html',
  styleUrls: ['./popup-request-form.component.scss']
})
export class PopupRequestFormComponent implements OnInit {
  formRequest!: FormGroup;
  flightListSelected: any;
  flightSelected: any;
  isLoading: boolean;
  flightBooking$ = this._comboSelectedService.flightListSelected$;

  constructor(
    public dialogRef: MatDialogRef<PopupRequestFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private contactFormsService: ContactFormsService,
    private _comboSelectedService: ComboSelectedService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this._comboSelectedService.flightListSelected$.subscribe(flightSelected => {
      if(flightSelected && flightSelected.length) {
        this.flightListSelected = flightSelected;
        this.flightSelected = flightSelected.filter(flightSelect => flightSelect.pricedItinerary.originDestinationOptions[0].flightDirection === 'D');
      }
    })
  }

  getDate(flightSelected: any, flightDirection: 'D'|'R') {
    const flights = flightSelected.filter(flightSelect => flightSelect.pricedItinerary.originDestinationOptions[0].flightDirection === flightDirection);
    if(flights && flights.length) {
      return moment(flights[0].itemFlight.arrivalDateTime).format('DD-MM-YYYY');
    }
  }

  private createForm() {
    this.formRequest = this.formBuilder.group({
      yourName: ['', Validators.required],
      yourEmail: ['', [Validators.required, Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$')]],
      yourPhone: ['', [Validators.pattern("^((\\+84-?)|0)?[0-9]{10}$")]],
      note: ['']
    });
  }

  closePopup() {
    this.dialogRef.close();
  }

  submitForm() {
    if(this.formRequest.valid && this.flightSelected && this.flightSelected.length) {
      this.isLoading = true;
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Đang gửi yêu cầu nhận báo giá...', loading: true},
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      this.contactFormsService.contactForm7V1ContactFormsIdFeedbackPost(2908, {
        ...this.formRequest.value,
        sequenceNumber: this.flightSelected[0].pricedItinerary.sequenceNumber,
        originCity: this.flightSelected[0].itemFlight.originCity,
        destinationCity: this.flightSelected[0].itemFlight.destinationCity,
        hotelName: this.data.hotelName,
        roomName: this.data.roomName,
        checkIn: this.getDate(this.flightListSelected, 'D'),
        checkOut: this.getDate(this.flightListSelected, 'R'),
      }).subscribe(requestBook => {
        this.isLoading = false;
        this.dialogRef.close();
        this._snackBar.dismiss();
        this.dialog.open(DialogMessageComponent, {
          data: {
            btn: [{ btnText: 'global.close' }],
            content: 'Cảm ơn Quý Khách đã đăng ký nhận báo giá Combo tại Gotadi, chúng tôi sẽ liên hệ ngay khi nhận được thông tin đăng ký của Quý Khách.',
            heading: 'Đăng ký nhận báo giá thành công!',
            icon: 'check',
            messageType: 'success'
          },
          panelClass: 'dialog-message',
          position: {
            top: '200px'
          },
          disableClose: true
        });
      }, error => {
        this.isLoading = false;
        this._snackBar.dismiss();
        this.dialog.open(DialogMessageComponent, {
          data: {
            btn: [{ btnText: 'global.close' }],
            content: 'Quý Khách đăng ký nhận báo giá Combo không thành công. \n Vui lòng thử lại hoặc liên hệ hotline: 1900 9002 để được hổ trợ. Xin cảm ơn!',
            heading: 'Đăng ký nhận báo giá thất bại',
            icon: 'exclamation-triangle',
            messageType: 'error'
          },
          panelClass: 'dialog-message',
          disableClose: true
        });
      })
    } else {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          message: 'Vui lòng điền đầy đủ thông tin bắt buộc trước khi đăng ký',
          icon: 'exclamation-triangle'
        },
        panelClass: 'snackbar-error',
        verticalPosition: "top",
        duration: 3000
      });
    }
  }
}
