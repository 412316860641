import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {DataPersistence} from '@nrwl/angular';

import {HotelSearchBestRatesPartialState} from './hotel-search-best-rates.reducer';
import {
  HotelSearchBestRatesActionTypes,
  HotelSearchBestRatesLoadError,
  LoadHotelSearchBestRates
} from './hotel-search-best-rates.actions';
import {HotelResourceV3Service} from '@gtd/b2c-client';
import {delay, map} from 'rxjs/operators';
import {PartnerService} from '@gtd/lotus/shared/data-access/services';

@Injectable()
export class HotelSearchBestRatesEffects {
  @Effect() loadHotelSearchBestRates$ = this.dataPersistence.fetch(
    HotelSearchBestRatesActionTypes.LoadHotelSearchBestRates,
    {
      run: (
        action: LoadHotelSearchBestRates,
        state: HotelSearchBestRatesPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        this.hotelV3ResourceService.defaultHeaders = this.hotelV3ResourceService.defaultHeaders.set(
          'sales-env',
          ''
        );
        return this.hotelV3ResourceService
          .searchBestRatesUsingGET(
            action.payload.searchCode,
            action.payload.searchType,
            action.payload.language as 'vi' | 'en',
            action.payload.currency,
            action.payload.checkIn,
            action.payload.checkOut,
            action.payload.paxInfos,
            action.payload.searchId,
            action.payload.supplier,
            action.payload.targetSupplier,
            action.payload.filterHotelName,
            action.payload.filterHotelCategories,
            action.payload.filterBreakfastIncluded,
            action.payload.filterFromPrice,
            action.payload.filterToPrice,
            action.payload.filterFromDistance,
            action.payload.filterToDistance,
            action.payload.filterStarRating,
            action.payload.filterFromGuestRating,
            action.payload.filterToGuestRating,
            action.payload.filterPropertyAmenities,
            action.payload.filterRoomAmenities,
            action.payload.filterRateAmenities,
            action.payload.filterRoomViews,
            action.payload.filterThemes,
            action.payload.filterMealPlans,
            action.payload.filterBedTypes,
            action.payload.filterGeoDistanceLat,
            action.payload.filterGeoDistanceLon,
            action.payload.filterGeoDistanceMeters,
            action.payload.sortField,
            action.payload.sortOrder,
            action.payload.pageNumber,
            action.payload.pageSize,
            action.payload.locationName
          )
          .pipe(
            map(payload => ({
              type: HotelSearchBestRatesActionTypes.HotelSearchBestRatesLoaded,
              payload: payload.result,
              loadMore: action.loadMore
            }))
          );
      },

      onError: (action: LoadHotelSearchBestRates, error) => {
        console.error('Error', error);
        return new HotelSearchBestRatesLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<HotelSearchBestRatesPartialState>,
    private hotelV3ResourceService: HotelResourceV3Service,
    private _partnerService: PartnerService
  ) {}
}
