import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { fadeIn, slideInHorizontal, slideInVertical } from '@gtd/helpers';
import {
  AirItineraryInfo,
  AirTicketsResourceService,
  FlightSegment,
  GroupPricedItinerary,
  PricedItinerary
} from '@gtd/b2c-client';
import { Subject, Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material';
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { FarerulesFacade } from '../../flight-search/farerules/+state/farerules.facade';
import { FlightResultItemService } from '../../flight-result-item/flight-result-item.service';
import { GroupItineraryFacade } from '../../flight-search/group-itinerary/+state/group-itinerary.facade';
import { SelectItemSrvService } from '../../select-item-srv.service';
import { ComboVJComboBookingFacade } from '../../../../../state/combo-booking/combo-booking.facade';
import { FareRules } from '@gtd/api-services/b2b-client';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';

@Component({
  selector: 'gtd-flight-booking-item',
  templateUrl: './flight-booking-item.component.html',
  styleUrls: ['./flight-booking-item.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class FlightBookingItemComponent
  implements OnInit, OnDestroy, AfterContentChecked {
  @Input() groupPricedItinerary: GroupPricedItinerary;
  @Input() bookingNumber: string;
  @Input() bookingDirectionIndex: number;
  @Input() pricedItinerary: PricedItinerary;
  @Input() firstPricedItinerary: PricedItinerary;
  @Input() lowestPrice: number;
  @Input() firstLowestPrice: number;
  @Input() searchId: string;
  flightSegments?: Array<FlightSegment>;
  allFarerules: FareRules[];
  subscriptions = new Subscription();
  isDetailJourney: boolean;
  panelOpenState: boolean;

  submitLoadMore: boolean = false;
  flightAutoSelected$ = this._comboSelectedService.flightListSelected$;

  constructor(
    private cdref: ChangeDetectorRef,
    private farerulesFacade: FarerulesFacade,
    private airTicketsResourceService: AirTicketsResourceService,
    private _comboSelectedService: ComboSelectedService,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      if (this.groupPricedItinerary) {
        this.flightSegments = this.groupPricedItinerary.pricedItineraries[0].originDestinationOptions[0].flightSegments;
      }
    });

    this.subscriptions.add(
      this.farerulesFacade.allFarerules$.subscribe(allFarerules => {
        if (allFarerules) {
          this.allFarerules = allFarerules.fareRules;
          this.submitLoadMore = false;
        } else {
          this.allFarerules = null;
          this.submitLoadMore = false;
        }
      })
    );
  }
  showFareRules: boolean;
  getFareRules() {
    // this.fareSourceCode = fareSourceCode;
    if(!this.showFareRules) {
      const currentLanguage = localStorage.getItem('language');
      this.submitLoadMore = true;
      this.farerulesFacade.loadAll(
        {
          fareSourceCode: this.pricedItinerary.airItineraryPricingInfo
            .fareSourceCode,
          groupId: this.groupPricedItinerary.groupId,
          searchId: this.searchId
        },
        currentLanguage
      );
    }
    this.showFareRules = !this.showFareRules;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  displayAirlineLogo(airlineCode: string) {
    if (
      airlineCode === 'VN' ||
      airlineCode === 'VJ' ||
      airlineCode === 'QH' ||
      airlineCode === 'BL'
    )
      return environment.cdn.logoAirline + airlineCode + '.svg';
    else return environment.cdn.logoAirline + airlineCode + '.gif';
  }




  countPriceTicketPerOnePassenger(priceTicket: number, quantities: number): number {
    return priceTicket / quantities;
  }

  calculatePrice(pricedItinerary: any, firstPricedItinerary: any, firstLowestPrice: number, lowestPrice: number): number {
    const currentPrice = this.countPriceTicketPerOnePassenger(
      pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount,
      pricedItinerary.airItineraryPricingInfo.adultFare.passengerTypeQuantities.quantity
    );

    const firstPrice = this.countPriceTicketPerOnePassenger(
      firstPricedItinerary.airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount,
      firstPricedItinerary.airItineraryPricingInfo.adultFare.passengerTypeQuantities.quantity
    );

    return currentPrice - (firstPrice - firstLowestPrice) - lowestPrice;
  }

  isPriceHigherOrEqual(pricedItinerary: any, lowestPrice: number): boolean {
    const currentPrice = this.countPriceTicketPerOnePassenger(
      pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount,
      pricedItinerary.airItineraryPricingInfo.adultFare.passengerTypeQuantities.quantity
    );

    return currentPrice >= lowestPrice;
  }
}
