import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  LoadHotelSearchFilterOptions,
  ResetHotelSearchFilterOptions
} from './hotel-search-filter-options.actions';
import { HotelSearchFilterOptionsPartialState } from './hotel-search-filter-options.reducer';
import { hotelSearchFilterOptionsQuery } from './hotel-search-filter-options.selectors';

@Injectable()
export class HotelSearchFilterOptionsFacade {
  loaded$ = this.store.pipe(select(hotelSearchFilterOptionsQuery.getLoaded));
  allHotelSearchFilterOptions$ = this.store.pipe(
    select(hotelSearchFilterOptionsQuery.getAllHotelSearchFilterOptions)
  );
  selectedHotelSearchFilterOptions$ = this.store.pipe(
    select(hotelSearchFilterOptionsQuery.getSelectedHotelSearchFilterOptions)
  );

  constructor(private store: Store<HotelSearchFilterOptionsPartialState>) {}

  loadAll(payload: string) {
    this.store.dispatch(new LoadHotelSearchFilterOptions(payload));
  }

  reset() {
    this.store.dispatch(new ResetHotelSearchFilterOptions());
  }
}
