import { Pipe, PipeTransform } from '@angular/core';
import { EXCHANGE_VALUE } from '@gtd/hotels/data-access/contansts';

@Pipe({
  name: 'miles'
})
export class MilesPipe implements PipeTransform {
  transform(value: any, exchange: number): any {
    return Math.floor(value / (exchange * EXCHANGE_VALUE));
  }
}
