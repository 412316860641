import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gtd-gift-service',
  templateUrl: './gift-service.component.html',
  styleUrls: ['./gift-service.component.scss']
})
export class GiftServiceComponent implements OnInit {
  @Input() label: string;
  @Input() isGiftService: boolean;

  constructor() { }

  ngOnInit() {
  }

}
