import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PropertyAllRate } from '@gtd/b2c-client';
import * as mapTypes from '@agm/core/services/google-maps-types';

@Component({
  selector: 'gtd-map-view-detail',
  templateUrl: './map-view-detail.component.html',
  styleUrls: ['./map-view-detail.component.scss']
})
export class MapViewDetailComponent implements OnInit {
  marker: Marker;
  zoom: number = 18;
  counter = Array;

  constructor(
    public dialogRef: MatDialogRef<MapViewDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { hotelDetail: PropertyAllRate }
  ) {}

  ngOnInit() {}

  closePopup() {
    this.dialogRef.close();
  }

  mathFloor(star: any) {
    return Math.floor(star);
  }
  isFloat(star) {
    return star % 1 != 0;
  }
}
interface Marker {
  lat: number;
  lng: number;
  label?: string | mapTypes.MarkerLabel;
  icon?: {
    labelOrigin?: {
      x: number;
      y: number;
    };
    url?: string;
  };
  animation?: Animation;
  contents?: any;
  urlDetail?: string;
  draggable: boolean;
}
