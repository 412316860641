import { AirLowFareSearchRS } from '@gtd/b2c-client';
import {
  FlightSearchFilterAvailabilityAction,
  FlightSearchFilterAvailabilityActionTypes
} from './flight-search-filter-availability.actions';

export const FLIGHTSEARCHFILTERAVAILABILITY_FEATURE_KEY =
  'flightSearchFilterAvailability';

/**
 * Interface for the 'FlightSearchFilterAvailability' data used in
 *  - FlightSearchFilterAvailabilityState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FlightSearchFilterAvailabilityState {
  list: AirLowFareSearchRS; // list of FlightSearchFilterAvailability; analogous to a sql normalized table
  selectedId?: string | number; // which FlightSearchFilterAvailability record has been selected
  loaded: boolean; // has the FlightSearchFilterAvailability list been loaded
  error?: any; // last none error (if any)
}

export interface FlightSearchFilterAvailabilityPartialState {
  readonly [FLIGHTSEARCHFILTERAVAILABILITY_FEATURE_KEY]: FlightSearchFilterAvailabilityState;
}

export const initialState: FlightSearchFilterAvailabilityState = {
  list: null,
  loaded: false
};

export function reducer(
  state: FlightSearchFilterAvailabilityState = initialState,
  action: FlightSearchFilterAvailabilityAction
): FlightSearchFilterAvailabilityState {
  switch (action.type) {
    case FlightSearchFilterAvailabilityActionTypes.LoadFlightSearchFilterAvailability: {
      state = {
        ...initialState
      };
      break;
    }
    case FlightSearchFilterAvailabilityActionTypes.FlightSearchFilterAvailabilityLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case FlightSearchFilterAvailabilityActionTypes.ResetFlightSearchFilterAvailability: {
      state = {
        ...initialState
      };
      break;
    }
  }
  return state;
}
