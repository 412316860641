import { PageResult, PropertyAvailable } from '@gtd/b2c-client';
import {
  HotelSearchBestRatesAction,
  HotelSearchBestRatesActionTypes
} from './hotel-search-best-rates.actions';

export const HOTELSEARCHBESTRATES_FEATURE_KEY = 'hotelSearchBestRates';

/**
 * Interface for the 'HotelSearchBestRates' data used in
 *  - HotelSearchBestRatesState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelSearchBestRatesState {
  list: PropertyAvailable[]; // list of HotelSearchBestRates; analogous to a sql normalized table
  pageable: PageResult;
  searchId: string;
  shortLinkId?: string;
  selectedId?: string | number; // which HotelSearchBestRates record has been selected
  loaded: boolean; // has the HotelSearchBestRates list been loaded
  error?: any; // last none error (if any)
}

export interface HotelSearchBestRatesPartialState {
  readonly [HOTELSEARCHBESTRATES_FEATURE_KEY]: HotelSearchBestRatesState;
}

export const initialState: HotelSearchBestRatesState = {
  list: [],
  pageable: null,
  searchId: null,
  shortLinkId: null,
  loaded: false
};

export function reducer(
  state: HotelSearchBestRatesState = initialState,
  action: HotelSearchBestRatesAction
): HotelSearchBestRatesState {
  switch (action.type) {
    case HotelSearchBestRatesActionTypes.HotelSearchBestRatesLoaded: {
      state = {
        ...state,
        list: action.loadMore? [...state.list, ...action.payload.propertyAvailable]: action.payload.propertyAvailable,
        pageable: action.payload.pageResult,
        searchId: action.payload.searchId,
        shortLinkId: state.shortLinkId? state.shortLinkId: action.payload.shortLinkId,
        loaded: true
      };
      break;
    }
    case HotelSearchBestRatesActionTypes.ResetHotelSearchBestRates: {
      state = initialState;
      break;
    }
    case HotelSearchBestRatesActionTypes.HotelSearchBestRatesLoadError: {
      state = {
        ...state,
        error: action.payload.error,
        loaded: true
      };
      break;
    }
  }
  return state;
}
