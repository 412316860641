import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightSearchFilterOptionsPartialState } from './flight-search-filter-options.reducer';
import { flightSearchFilterOptionsQuery } from './flight-search-filter-options.selectors';
import {
  LoadFlightSearchFilterOptions,
  ResetFlightSearchFilterOptions
} from './flight-search-filter-options.actions';
import { AirLowFareFilterVM } from '@gtd/b2c-client';

@Injectable()
export class FlightSearchFilterOptionsFacade {
  loaded$ = this.store.pipe(select(flightSearchFilterOptionsQuery.getLoaded));
  allFlightSearchFilterOptions$ = this.store.pipe(
    select(flightSearchFilterOptionsQuery.getAllFlightSearchFilterOptions)
  );
  selectedFlightSearchFilterOptions$ = this.store.pipe(
    select(flightSearchFilterOptionsQuery.getSelectedFlightSearchFilterOptions)
  );

  constructor(private store: Store<FlightSearchFilterOptionsPartialState>) {}

  loadAll(payload: AirLowFareFilterVM) {
    this.store.dispatch(new LoadFlightSearchFilterOptions(payload));
  }

  reset() {
    this.store.dispatch(new ResetFlightSearchFilterOptions());
  }
}
