import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { HotelSearchParamsPartialState } from './hotel-search-params.reducer';
import {
  LoadHotelSearchParams,
  HotelSearchParamsLoaded,
  HotelSearchParamsLoadError,
  HotelSearchParamsActionTypes
} from './hotel-search-params.actions';

@Injectable()
export class HotelSearchParamsEffects {
  @Effect() loadHotelSearchParams$ = this.dataPersistence.fetch(
    HotelSearchParamsActionTypes.LoadHotelSearchParams,
    {
      run: (
        action: LoadHotelSearchParams,
        state: HotelSearchParamsPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return new HotelSearchParamsLoaded(action.payload);
      },

      onError: (action: LoadHotelSearchParams, error) => {
        console.error('Error', error);
        return new HotelSearchParamsLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<HotelSearchParamsPartialState>
  ) {}
}
