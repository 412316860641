import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromHotelSearchBestRates from './hotel-search-best-rates/hotel-search-best-rates.reducer';
import { HotelSearchBestRatesEffects } from './hotel-search-best-rates/hotel-search-best-rates.effects';
import { HotelSearchBestRatesFacade } from './hotel-search-best-rates/hotel-search-best-rates.facade';
import * as fromHotelSearchAllRates from './hotel-search-all-rates/hotel-search-all-rates.reducer';
import { HotelSearchAllRatesEffects } from './hotel-search-all-rates/hotel-search-all-rates.effects';
import { HotelSearchAllRatesFacade } from './hotel-search-all-rates/hotel-search-all-rates.facade';
import * as fromHotelSearchFilterOptions from './hotel-search-filter-options/hotel-search-filter-options.reducer';
import { HotelSearchFilterOptionsEffects } from './hotel-search-filter-options/hotel-search-filter-options.effects';
import { HotelSearchFilterOptionsFacade } from './hotel-search-filter-options/hotel-search-filter-options.facade';
import * as fromHotelSearchParams from './hotel-search-params/hotel-search-params.reducer';
import { HotelSearchParamsEffects } from './hotel-search-params/hotel-search-params.effects';
import { HotelSearchParamsFacade } from './hotel-search-params/hotel-search-params.facade';
import { BASE_PATH, HotelResourceV3Service } from '@gtd/b2c-client';
import { environment } from '@env/environment';
import * as fromHotelFilterAvailable from './hotel-filter-available/hotel-filter-available.reducer';
import { HotelFilterAvailableEffects } from './hotel-filter-available/hotel-filter-available.effects';
import { HotelFilterAvailableFacade } from './hotel-filter-available/hotel-filter-available.facade';
import * as fromHotelCheckout from './hotel-checkout/hotel-checkout.reducer';
import { HotelCheckoutEffects } from './hotel-checkout/hotel-checkout.effects';
import { HotelCheckoutFacade } from './hotel-checkout/hotel-checkout.facade';
import * as fromHotelSearchKeyword from './hotel-search-keyword/hotel-search-keyword.reducer';
import { HotelSearchKeywordEffects } from './hotel-search-keyword/hotel-search-keyword.effects';
import { HotelSearchKeywordFacade } from './hotel-search-keyword/hotel-search-keyword.facade';
import * as fromHotelCreateDraftBooking from './hotel-create-draft-booking/hotel-create-draft-booking.reducer';
import { HotelCreateDraftBookingEffects } from './hotel-create-draft-booking/hotel-create-draft-booking.effects';
import { HotelCreateDraftBookingFacade } from './hotel-create-draft-booking/hotel-create-draft-booking.facade';
import * as fromHotelBooking from './hotel-booking/hotel-booking.reducer';
import { HotelBookingEffects } from './hotel-booking/hotel-booking.effects';
import { HotelBookingFacade } from './hotel-booking/hotel-booking.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromHotelSearchBestRates.HOTELSEARCHBESTRATES_FEATURE_KEY,
      fromHotelSearchBestRates.reducer
    ),
    EffectsModule.forFeature([HotelSearchBestRatesEffects]),
    StoreModule.forFeature(
      fromHotelSearchAllRates.HOTELSEARCHALLRATES_FEATURE_KEY,
      fromHotelSearchAllRates.reducer
    ),
    EffectsModule.forFeature([HotelSearchAllRatesEffects]),
    StoreModule.forFeature(
      fromHotelSearchFilterOptions.HOTELSEARCHFILTEROPTIONS_FEATURE_KEY,
      fromHotelSearchFilterOptions.reducer
    ),
    EffectsModule.forFeature([HotelSearchFilterOptionsEffects]),
    StoreModule.forFeature(
      fromHotelSearchParams.HOTELSEARCHPARAMS_FEATURE_KEY,
      fromHotelSearchParams.reducer
    ),
    EffectsModule.forFeature([HotelSearchParamsEffects]),
    StoreModule.forFeature(
      fromHotelFilterAvailable.HOTELFILTERAVAILABLE_FEATURE_KEY,
      fromHotelFilterAvailable.reducer
    ),
    EffectsModule.forFeature([HotelFilterAvailableEffects]),
    StoreModule.forFeature(
      fromHotelCheckout.HOTELCHECKOUT_FEATURE_KEY,
      fromHotelCheckout.reducer
    ),
    EffectsModule.forFeature([HotelCheckoutEffects]),
    StoreModule.forFeature(
      fromHotelSearchKeyword.HOTELSEARCHKEYWORD_FEATURE_KEY,
      fromHotelSearchKeyword.reducer
    ),
    EffectsModule.forFeature([HotelSearchKeywordEffects]),
    StoreModule.forFeature(
      fromHotelCreateDraftBooking.HOTELCREATEDRAFTBOOKING_FEATURE_KEY,
      fromHotelCreateDraftBooking.reducer
    ),
    EffectsModule.forFeature([HotelCreateDraftBookingEffects]),
    StoreModule.forFeature(
      fromHotelBooking.HOTELBOOKING_FEATURE_KEY,
      fromHotelBooking.reducer
    ),
    EffectsModule.forFeature([HotelBookingEffects])
  ],
  providers: [
    HotelSearchBestRatesFacade,
    HotelSearchAllRatesFacade,
    HotelSearchFilterOptionsFacade,
    HotelSearchParamsFacade,
    { provide: BASE_PATH, useValue: environment.api.b2cClient },
    HotelResourceV3Service,
    HotelFilterAvailableFacade,
    HotelCheckoutFacade,
    HotelSearchKeywordFacade,
    HotelCreateDraftBookingFacade,
    HotelBookingFacade
  ]
})
export class HotelStoreModule {}
