import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelSearchBestRatesPartialState } from './hotel-search-best-rates.reducer';
import { hotelSearchBestRatesQuery } from './hotel-search-best-rates.selectors';
import {
  HotelPayload,
  LoadHotelSearchBestRates,
  ResetHotelSearchBestRates
} from './hotel-search-best-rates.actions';

@Injectable()
export class HotelSearchBestRatesFacade {
  loaded$ = this.store.pipe(select(hotelSearchBestRatesQuery.getLoaded));
  allHotelSearchBestRates$ = this.store.pipe(
    select(hotelSearchBestRatesQuery.getAllHotelSearchBestRates)
  );
  getShortLinkIdSearchBestRates$ = this.store.pipe(
    select(hotelSearchBestRatesQuery.getShortLinkIdSearchBestRates)
  );
  selectedHotelSearchBestRates$ = this.store.pipe(
    select(hotelSearchBestRatesQuery.getSelectedHotelSearchBestRates)
  );
  pageable$ = this.store.pipe(select(hotelSearchBestRatesQuery.getPageable));
  searchId$ = this.store.pipe(select(hotelSearchBestRatesQuery.getSearchId));

  constructor(private store: Store<HotelSearchBestRatesPartialState>) {}

  loadAll(payload: HotelPayload, loadMore?: boolean) {
    this.store.dispatch(new LoadHotelSearchBestRates(payload, loadMore));
  }

  reset() {
    this.store.dispatch(new ResetHotelSearchBestRates());
  }
}
