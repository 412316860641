import { FilterOptionsResult } from '@gtd/b2c-client';
import {
  HotelSearchFilterOptionsAction,
  HotelSearchFilterOptionsActionTypes
} from './hotel-search-filter-options.actions';

export const HOTELSEARCHFILTEROPTIONS_FEATURE_KEY = 'hotelSearchFilterOptions';

/**
 * Interface for the 'HotelSearchFilterOptions' data used in
 *  - HotelSearchFilterOptionsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelSearchFilterOptionsState {
  list: FilterOptionsResult; // list of HotelSearchFilterOptions; analogous to a sql normalized table
  selectedId?: string | number; // which HotelSearchFilterOptions record has been selected
  loaded: boolean; // has the HotelSearchFilterOptions list been loaded
  error?: any; // last none error (if any)
}

export interface HotelSearchFilterOptionsPartialState {
  readonly [HOTELSEARCHFILTEROPTIONS_FEATURE_KEY]: HotelSearchFilterOptionsState;
}

export const initialState: HotelSearchFilterOptionsState = {
  list: null,
  loaded: false
};

export function reducer(
  state: HotelSearchFilterOptionsState = initialState,
  action: HotelSearchFilterOptionsAction
): HotelSearchFilterOptionsState {
  switch (action.type) {
    case HotelSearchFilterOptionsActionTypes.HotelSearchFilterOptionsLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case HotelSearchFilterOptionsActionTypes.ResetHotelSearchFilterOptions: {
      state = initialState;
      break;
    }
  }
  return state;
}
