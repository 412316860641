import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { BranchFilter } from '../brand-filter/brand-item/string2-branch-mapper.pipe';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'gtd-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CheckboxFilterComponent implements OnInit, OnChanges {
  @Input() data: BranchFilter[] = [];
  @Input() checkedItems: string[] = [];
  @Input() name: string;
  @Input() rightInfo: boolean;
  @Input() isLoading: boolean;
  @Input() type: 'checkbox' | 'radio' | 'range' | 'timeRange' = 'checkbox';
  @Input() flightFilterLoaded: boolean;
  @Input() title: string;
  @Input() translateField: boolean;
  @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() priceRange;
  rangeValue = 0;
  priceValueDebounce$: Subject<any> = new Subject();

  formCheckbox: FormGroup;

  rangeOptions: {
    animate: boolean;
    floor: number;
    ceil: number;
    hideLimitLabels: boolean;
    hidePointerLabels: boolean;
    draggableRange: boolean;
  };

  panelOpenState = false;

  checkedResults = this.checkedItems.slice();

  constructor(
    private _comboSelectedService: ComboSelectedService,
    private _translateService: TranslateService,
    private fb: FormBuilder,
    private deviceService: DeviceDetectorService,
  ) {}
  get branches(): FormArray {
    return this.formCheckbox.get('branches') as FormArray;
  }

  ngOnInit() {
    if (this.name === 'price') {
      this.rangeValue = this.priceRange.max;
    }
    if(this.deviceService.isDesktop()) {
      this.panelOpenState = true;
    }

    this.formCheckbox = this.fb.group({
      branches: this.fb.array(
        this.data.map(item =>
          this.fb.group({
            branchCode: [item.branchCode],
            branchName: [item.branchName],
            checked: [false]
          })
        )
      )
    });

    this.branches.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(changeForm => {
        if (!this.isLoading) {
          this.onCheckboxChanged();
        }
      });

    this._comboSelectedService.airlineOptions$.subscribe(airline => {
      if (airline) {
        if (airline === 'VJ') {
          const VJIndex = this.data.findIndex(item => item.branchCode === 'VJ');
          this.data = this.data.slice(VJIndex, 1);
        } else {
          this.data = this.data.filter(item => item.branchCode !== 'VJ');
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.type === 'range' &&
      changes.priceRange &&
      changes.priceRange.currentValue
    ) {
      const priceRange = changes.priceRange.currentValue;
      this.rangeOptions = this.makeRangeOptions(priceRange.min, priceRange.max);
      this.rangeValue = priceRange.max;
    }
  }

  rangeChanged(event: any) {
    this.priceValueDebounce$.next(event.value);
  }

  makeRangeOptions(min: number, max: number) {
    return {
      animate: false,
      floor: min,
      ceil: max,
      hideLimitLabels: true,
      hidePointerLabels: true,
      draggableRange: false
    };
  }

  onCheckboxChanged(): void {
    const formArray = this.branches;
    this.isLoading = true;
    this.checkedResults = [];
    formArray.controls.forEach(group => {
      const checked = group.get('checked').value;
      const branchCode = group.get('branchCode').value;
      if (checked && branchCode) {
        this.checkedResults.push(branchCode);
      }
    });

    this.applyChecked();

    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  applyChecked() {
    this.changed.emit(this.checkedResults);
  }
}
