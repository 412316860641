import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { FlightBookingFacade } from '@gtd/flights/data-access/store';

@Component({
  selector: 'gtd-flight-booking',
  templateUrl: './flight-booking.component.html',
  styleUrls: ['./flight-booking.component.scss']
})
export class FlightBookingComponent implements OnInit {
  @Input() searchParams: any;
  currentLang: string;
  allFlightBooking$ = this._flightBookingFacade.allFlightBooking$;
  constructor(private _flightBookingFacade: FlightBookingFacade) {}

  ngOnInit() {
    this.currentLang = localStorage.getItem('language');
  }

  displayDestination(destination) {
    return destination && destination.city && destination.code
      ? `<span>${
          this.currentLang == 'vi' && destination.city2
            ? destination.city2
            : destination.city
        } (</span>${destination.code}<span>)</span>`
      : destination;
  }

  displayAirlineLogo(airlineCode: string) {
    if (
      airlineCode === 'VN' ||
      airlineCode === 'VJ' ||
      airlineCode === 'QH' ||
      airlineCode === 'BL'
    )
      return environment.cdn.logoAirline + airlineCode + '.svg';
    else return environment.cdn.logoAirline + airlineCode + '.gif';
  }

  editFlight() {
    this._flightBookingFacade.resetFlightWay('from');
  }

  getGroupPricedItinerary(item: any, idx: number) {
    if(item && item.pricedItinerary) {
      let originDestinationOptions = item.pricedItinerary.originDestinationOptions.filter(
        (originDestinationOption: any) => originDestinationOption.flightDirection === (idx===0? 'D': 'R')
      )
      return originDestinationOptions.length? originDestinationOptions[0]: null
    }
  }
}
