import {
  HotelFilterAvailableAction,
  HotelFilterAvailableActionTypes, RetryHotelSearch
} from './hotel-filter-available.actions';

export const HOTELFILTERAVAILABLE_FEATURE_KEY = 'hotelFilterAvailable';

/**
 * Interface for the 'HotelFilterAvailable' data used in
 *  - HotelFilterAvailableState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface HotelFilterAvailableState {
  language: 'vi' | 'en';
  currency: 'VND' | 'USD';
  filterHotelName?: string;
  filterHotelCategories?: Array<string>;
  filterBreakfastIncluded?: boolean;
  filterPrice?: {
    fromPrice?: number;
    toPrice?: number;
  };
  filterDistance?: {
    fromDistance?: number;
    toDistance?: number;
  };
  filterStarRating?: Array<string>;
  filterGuestRating?: {
    from?: number;
    to?: number;
  };
  filterPropertyAmenities?: Array<string>;
  filterRoomAmenities?: Array<string>;
  filterRoomViews?: Array<string>;
  filterThemes?: Array<string>;
  filterMealPlans?: Array<string>;
  filterBedTypes?: Array<string>;
  filterRateAmenities?: Array<string>;
  sort?: {
    sortField?: 'order' | 'price' | 'starRating' | 'guestRating';
    sortOrder?: 'ASC' | 'DESC';
  };
  pageNumber?: number;
  pageSize?: number;
  loadMore?: boolean;
}
export interface HotelFilterAvailablePartialState {
  readonly [HOTELFILTERAVAILABLE_FEATURE_KEY]: HotelFilterAvailableState;
}

export const initialState: HotelFilterAvailableState = null;

export function reducer(
  state: HotelFilterAvailableState = initialState,
  action: HotelFilterAvailableAction
): HotelFilterAvailableState {
  switch (action.type) {
    case HotelFilterAvailableActionTypes.ResetHotelFilter: {
      state = { ...initialState };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeHotelFilterAvailable: {
      state = action.payload;
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterHotelName: {
      state = {
        ...state,
        pageNumber: 0,
        filterHotelName: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterHotelCategories: {
      state = {
        ...state,
        pageNumber: 0,
        filterHotelCategories: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterBreakfastIncluded: {
      state = {
        ...state,
        pageNumber: 0,
        filterBreakfastIncluded: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterPrice: {
      state = {
        ...state,
        pageNumber: 0,
        filterPrice: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterDistance: {
      state = {
        ...state,
        pageNumber: 0,
        filterDistance: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterStarRating: {
      state = {
        ...state,
        pageNumber: 0,
        filterStarRating: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterGuestRating: {
      state = {
        ...state,
        pageNumber: 0,
        filterGuestRating: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterRoomAmenities: {
      state = {
        ...state,
        pageNumber: 0,
        filterRoomAmenities: action.payload.slice(),
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterPropertyAmenities: {
      state = {
        ...state,
        pageNumber: 0,
        filterPropertyAmenities: action.payload.slice(),
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterRateAmenities: {
      state = {
        ...state,
        pageNumber: 0,
        filterRateAmenities: action.payload.slice(),
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterRoomViews: {
      state = {
        ...state,
        pageNumber: 0,
        filterRoomViews: action.payload.slice(),
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterThemes: {
      state = {
        ...state,
        pageNumber: 0,
        filterThemes: action.payload.slice(),
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterMealPlans: {
      state = {
        ...state,
        pageNumber: 0,
        filterMealPlans: action.payload.slice(),
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterBedTypes: {
      state = {
        ...state,
        pageNumber: 0,
        filterBedTypes: action.payload.slice(),
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeSort: {
      state = {
        ...state,
        pageNumber: 0,
        sort: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangeFilterLanguage: {
      state = {
        ...state,
        pageNumber: 0,
        language: action.payload,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangePageNumber: {
      state = {
        ...state,
        pageNumber: action.payload,
        loadMore: true
      };
      break;
    }
    case HotelFilterAvailableActionTypes.RetryHotelSearch: {
      state = {
        ...state,
        loadMore: false
      };
      break;
    }
    case HotelFilterAvailableActionTypes.ChangePageSize: {
      state = {
        ...state,
        pageSize: action.payload,
        loadMore: false
      };
      break;
    }
  }
  return state;
}
