import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTGROUPITINERARY_FEATURE_KEY,
  FlightGroupItineraryState
} from './flight-group-itinerary.reducer';

// Lookup the 'FlightGroupItinerary' feature state managed by NgRx
const getFlightGroupItineraryState = createFeatureSelector<
  FlightGroupItineraryState
>(FLIGHTGROUPITINERARY_FEATURE_KEY);

const getLoaded = createSelector(
  getFlightGroupItineraryState,
  (state: FlightGroupItineraryState) => state.loaded
);
const getError = createSelector(
  getFlightGroupItineraryState,
  (state: FlightGroupItineraryState) => state.error
);

const getAllFlightGroupItinerary = createSelector(
  getFlightGroupItineraryState,
  getLoaded,
  (state: FlightGroupItineraryState, isLoaded) => {
    return isLoaded ? state.list : null;
  }
);
const getSelectedId = createSelector(
  getFlightGroupItineraryState,
  (state: FlightGroupItineraryState) => state.selectedId
);
const getSelectedFlightGroupItinerary = createSelector(
  getAllFlightGroupItinerary,
  getSelectedId,
  (flightGroupItinerary, id) => {
    const result = flightGroupItinerary;
    return result ? Object.assign({}, result) : undefined;
  }
);

export const flightGroupItineraryQuery = {
  getLoaded,
  getError,
  getAllFlightGroupItinerary,
  getSelectedFlightGroupItinerary
};
