import { BannerResourceService } from './api/bannerResource.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '@env/environment';
import { BASE_PATH } from './variables';

@NgModule({
  imports: [CommonModule],
  providers: [{provide: BASE_PATH, useValue: environment.api.metasrv}, BannerResourceService],
})
export class MetasrvModule {}
