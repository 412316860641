import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightFarerulesPartialState } from './flight-farerules.reducer';
import {
  LoadFlightFarerules,
  FlightFarerulesLoaded,
  FlightFarerulesLoadError,
  FlightFarerulesActionTypes
} from './flight-farerules.actions';
import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class FlightFarerulesEffects {
  @Effect() loadFlightFarerules$ = this.dataPersistence.fetch(
    FlightFarerulesActionTypes.LoadFlightFarerules,
    {
      run: (
        action: LoadFlightFarerules,
        state: FlightFarerulesPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this._airTicketResourceService
          .retrieveFareRulesUsingPOST(action.payload, action.language)
          .pipe(
            map(payload => ({
              type: FlightFarerulesActionTypes.FlightFarerulesLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadFlightFarerules, error) => {
        console.error('Error', error);
        return new FlightFarerulesLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<FlightFarerulesPartialState>,
    private _airTicketResourceService: AirTicketsResourceService
  ) {}
}
