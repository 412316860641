import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLIGHTADDBOOKINGTRAVELLER_FEATURE_KEY,
  FlightAddBookingTravellerState
} from './flight-add-booking-traveller.reducer';

// Lookup the 'FlightAddBookingTraveller' feature state managed by NgRx
const getFlightAddBookingTravellerState = createFeatureSelector<
  FlightAddBookingTravellerState
>(FLIGHTADDBOOKINGTRAVELLER_FEATURE_KEY);

const getLoaded = createSelector(
  getFlightAddBookingTravellerState,
  (state: FlightAddBookingTravellerState) => state.loaded
);
const getError = createSelector(
  getFlightAddBookingTravellerState,
  (state: FlightAddBookingTravellerState) => state.error
);

const getAllFlightAddBookingTraveller = createSelector(
  getFlightAddBookingTravellerState,
  getLoaded,
  (state: FlightAddBookingTravellerState, isLoaded) => {
    return isLoaded ? state.list : [];
  }
);
const getSelectedId = createSelector(
  getFlightAddBookingTravellerState,
  (state: FlightAddBookingTravellerState) => state.selectedId
);
const getSelectedFlightAddBookingTraveller = createSelector(
  getAllFlightAddBookingTraveller,
  getSelectedId,
  (flightAddBookingTraveller, id) => {
    const result = flightAddBookingTraveller.find(it => it['id'] === id);
    return result ? Object.assign({}, result) : undefined;
  }
);

export const flightAddBookingTravellerQuery = {
  getLoaded,
  getError,
  getAllFlightAddBookingTraveller,
  getSelectedFlightAddBookingTraveller
};
