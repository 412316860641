import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BASE_PATH, SimApiResourceService } from './api-client';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    SimApiResourceService,
    { provide: BASE_PATH, useValue: environment.api.inventoryService },
  ]
})
export class ServiceInventoryModule {}
