import { AirItineraryFilterRS } from '@gtd/b2c-client';
import {
  FlightSearchFilterOptionsAction,
  FlightSearchFilterOptionsActionTypes
} from './flight-search-filter-options.actions';

export const FLIGHTSEARCHFILTEROPTIONS_FEATURE_KEY =
  'flightSearchFilterOptions';

/**
 * Interface for the 'FlightSearchFilterOptions' data used in
 *  - FlightSearchFilterOptionsState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FlightSearchFilterOptionsState {
  list: AirItineraryFilterRS; // list of FlightSearchFilterOptions; analogous to a sql normalized table
  selectedId?: string | number; // which FlightSearchFilterOptions record has been selected
  loaded: boolean; // has the FlightSearchFilterOptions list been loaded
  error?: any; // last none error (if any)
}

export interface FlightSearchFilterOptionsPartialState {
  readonly [FLIGHTSEARCHFILTEROPTIONS_FEATURE_KEY]: FlightSearchFilterOptionsState;
}

export const initialState: FlightSearchFilterOptionsState = {
  list: null,
  loaded: false
};

export function reducer(
  state: FlightSearchFilterOptionsState = initialState,
  action: FlightSearchFilterOptionsAction
): FlightSearchFilterOptionsState {
  switch (action.type) {
    case FlightSearchFilterOptionsActionTypes.LoadFlightSearchFilterOptions: {
      state = {
        ...state,
        loaded: false
      };
      break;
    }
    case FlightSearchFilterOptionsActionTypes.FlightSearchFilterOptionsLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
    case FlightSearchFilterOptionsActionTypes.ResetFlightSearchFilterOptions: {
      state = {
        ...initialState
      };
      break;
    }
  }
  return state;
}
