/**
 * gtd_service-invntory API
 * gtd_service_inventory API documentation
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { SimApiRs } from '../model/simResult';
import { CustomHttpUrlEncodingCodec } from '../../../../../../b2c-client/src/lib/b2c-api-gateway/encoder';


@Injectable()
export class SimApiResourceService {

    protected basePath = 'https://10.7.71.20:7883';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     * getPolicyByBooking
     *
     * @param type
     * @param isFree
     * @param page
     * @param size
     * @param observe set whether to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListSim(type?: number, isFree?: boolean, page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<SimApiRs>;
    public getListSim(type?: number, isFree?: boolean, page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SimApiRs>>;
    public getListSim(type?: number, isFree?: boolean, page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SimApiRs>>;
    public getListSim(type?: number, isFree?: boolean, page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      if (page !== undefined && page !== null) {
        queryParameters = queryParameters.set('page', <any>page);
      }
      if (size !== undefined && size !== null) {
        queryParameters = queryParameters.set('size', <any>size);
      }
      if (isFree !== undefined && isFree !== null) {
        queryParameters = queryParameters.set('isFree', <any>isFree);
      }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        //TODO replace url api
        return this.httpClient.get<SimApiRs>(`${this.basePath}/api/v1/sim`, //${this.basePath}
            {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress
            }
        );
    }

}
