import {
  FlightAddBookingTravellerAction,
  FlightAddBookingTravellerActionTypes
} from './flight-add-booking-traveller.actions';

export const FLIGHTADDBOOKINGTRAVELLER_FEATURE_KEY =
  'flightAddBookingTraveller';

/**
 * Interface for the 'FlightAddBookingTraveller' data used in
 *  - FlightAddBookingTravellerState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FlightAddBookingTravellerState {
  list: Entity[]; // list of FlightAddBookingTraveller; analogous to a sql normalized table
  selectedId?: string | number; // which FlightAddBookingTraveller record has been selected
  loaded: boolean; // has the FlightAddBookingTraveller list been loaded
  error?: any; // last none error (if any)
}

export interface FlightAddBookingTravellerPartialState {
  readonly [FLIGHTADDBOOKINGTRAVELLER_FEATURE_KEY]: FlightAddBookingTravellerState;
}

export const initialState: FlightAddBookingTravellerState = {
  list: [],
  loaded: false
};

export function reducer(
  state: FlightAddBookingTravellerState = initialState,
  action: FlightAddBookingTravellerAction
): FlightAddBookingTravellerState {
  switch (action.type) {
    case FlightAddBookingTravellerActionTypes.FlightAddBookingTravellerLoaded: {
      state = {
        ...state,
        list: action.payload,
        loaded: true
      };
      break;
    }
  }
  return state;
}
