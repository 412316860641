import {Component, Input, OnInit} from '@angular/core';
import {PropertyRoom, RatePlan} from '@gtd/b2c-client';

@Component({
  selector: 'gtd-rate-plan-list',
  templateUrl: './rate-plan-list.component.html',
  styleUrls: ['./rate-plan-list.component.scss']
})
export class RatePlanListComponent implements OnInit {
  @Input() ratePlans: Array<RatePlan>;
  @Input() room: PropertyRoom;
  @Input() expandMore: boolean;
  constructor() {}

  ngOnInit() {}
}
