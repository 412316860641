import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { CustomerTravellerFacade } from '../../customer-traveller/+state/customer-traveller.facade';
import { ProfileFacade } from '../+state/profile.facade';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { MatDialog, MatSelect, MatSnackBar } from '@angular/material';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { CountryFacade } from '@gtd/meta';
import { CountryCodeDTO } from '@gtd/meta-client';
import { takeUntil, tap } from 'rxjs/operators';
import {
  CustomerTravellerDTO,
  CustomerTravellerMemberCardDTO,
  CustomerTravellerResourceService
} from '@gtd/api-services/customer-service';
import {AccountFacade, AccountResourceService, CustomerResourceService} from '@gtd/b2c-client';
import {
  GetUserProfileFacade,
  UserProfileResourceService
} from '@gtd/api-services/b2b-client';
import { MustMatch2 } from '@gtd/helpers';
import { AgenciesService } from '@gtd/services';

@Component({
  selector: 'gtd-profile-update',
  templateUrl: './profile-update.component.html',
  styleUrls: ['./profile-update.component.scss']
})
export class ProfileUpdateComponent implements OnInit, OnDestroy {
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  isLoading = false;
  formProfile?: FormGroup;
  subscriptions = new Subscription();
  memberCardData: Array<CustomerTravellerMemberCardDTO>;
  accountData: any;
  userProfile: any;
  appName = localStorage.getItem('appName');
  today = new Date();
  agencyCA: any;
  protected countries: CountryCodeDTO[];
  public countryFilterCtrl: FormControl = new FormControl();
  public filteredCountries: ReplaySubject<CountryCodeDTO[]> = new ReplaySubject<
    CountryCodeDTO[]
  >(1);

  protected _onDestroy = new Subject<void>();

  constructor(
    private accountFacade: AccountFacade,
    private accountResourceService: AccountResourceService,
    private profileFacade: ProfileFacade,
    private formBuilder: FormBuilder,
    private customerTravellerFacade: CustomerTravellerFacade,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private countryFacade: CountryFacade,
    private userProfileResourceService: UserProfileResourceService,
    private customerTravellerResourceService: CustomerTravellerResourceService,
    private getUserProfileFacade: GetUserProfileFacade,
    private agenciesService: AgenciesService,
    private customerResourceService: CustomerResourceService,
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.subscriptions.add(
      this.agenciesService.getAgencies
        .pipe(tap(agencies => (this.agencyCA = agencies)))
        .subscribe()
    );
    this.subscriptions.add(
      this.getUserProfileFacade.allGetUserProfile$.subscribe(userProfile => {
        if (userProfile && userProfile.id) {
          this.userProfile = userProfile;
          this.formProfile.patchValue({
            id: userProfile.id,
            email: userProfile.email,
            phoneNumber1: userProfile.phoneNumber1,
            address1: userProfile.address1,
            country: userProfile.countryCode,
            agencyName: userProfile.agencyName,
            agentName: userProfile.agentName,
            agentCACode:
              userProfile.agencyCACodes.length &&
              userProfile.agencyCACodes[0].agentCACode
                ? userProfile.agencyCACodes[0].agentCACode
                : ''
          });
        }
      })
    );
    this.subscriptions.add(
      this.profileFacade.selectedProfile$.subscribe(dataProfile => {
        if (
          dataProfile &&
          dataProfile.lists &&
          dataProfile.type === 'LoadAll' &&
          this.appName !== 'B2B'
        ) {
          this.customerTravellerFacade.getCustomerTravellerUsingGet(
            dataProfile.lists.defaultTravellerId
          );
        }
      })
    );
    if (this.appName !== 'B2B') {
      this.subscriptions.add(
        combineLatest([
          this.accountFacade.getAccountLogged$,
          this.customerTravellerFacade.selectedCustomerTraveller$
        ]).subscribe(([account, customer]) => {
          if (account && customer) {
            this.formProfile.patchValue({
              id: customer.id,
              surName: account.lastName,
              firstName: account.firstName,
              email: account.email || customer.email,
              phoneNumber1: account.phoneNumber || customer.phoneNumber1,
              dob: customer.dob,
              gender: customer.gender ? customer.gender : null,
              address1: customer.address1,
              country: customer.country,
              customerCode: customer.customerCode,
              profileId: customer.profileId,
              documentType: account.documentType,
              documentNumber: account.documentNumber,
              nationality: account.nationality,
              expiredDate: account.expiredDate
            });
            this.memberCardData = account.memberCards;
          }
        })
      );
    }

    this.subscriptions.add(
      this.countryFacade.allCountry$.subscribe(
        (countries: Array<CountryCodeDTO>) => {
          this.countries = countries;
          this.filteredCountries.next(countries.slice());
        }
      )
    );

    this.subscriptions.add(
      this.accountFacade.getAccountLogged$.subscribe(accountData => {
        if (accountData) {
          this.accountData = accountData;
        }
      })
    );

    this.countryFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCountries();
      });

    // this.today = new Date(this.today.getFullYear()-12, this.today.getMonth(), this.today.getDate()+1);
  }

  protected filterCountries() {
    if (!this.countries) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search && this.countries) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountries.next(
      this.countries.filter(
        country => country.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  submitForm() {
    if (this.formProfile.valid) {
      this.isLoading = true;
      const formProfile = this.formProfile.getRawValue();
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'user.updating-information', loading: true },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      if (this.appName === 'B2B') {
        this.updateProfileB2B(formProfile);
      } else {
        this.updateProfileB2C(formProfile);
      }
    }
  }
  updateProfileB2B(formProfile) {
    this.userProfileResourceService
      .updateUserProfileUsingPUT({
        ...this.userProfile,
        id: formProfile.id,
        agentName: formProfile.agentName,
        email: formProfile.email,
        countryCode: formProfile.country,
        address1: formProfile.address1,
        agencyCACodes: [
          {
            agentCACode: formProfile.agentCACode,
            agencyCode: this.agencyCA.agencyCode,
            agentCompanyName: formProfile.agentName,
            supplierName: 'VNA',
            agencyId: this.agencyCA.id
          }
        ]
      })
      .subscribe(updateCustomer => this.callbackUpdate(updateCustomer));
  }
  updateProfileB2C(formProfile) {
    this.customerResourceService
      .updateAirCustomerTravellerUsingPUT([
        {
          id: formProfile.id,
          adultType: CustomerTravellerDTO.AdultTypeEnum.ADT,
          customerCode: formProfile.customerCode,
          address1: formProfile.address1,
          firstName: formProfile.firstName.toUpperCase(),
          surName: formProfile.surName.toUpperCase(),
          dob: formProfile.dob,
          country: formProfile.country,
          phoneNumber1: formProfile.phoneNumber1,
          email: formProfile.email,
          profileId: formProfile.profileId,
          gender: formProfile.gender,
          documentType: formProfile.documentType,
          documentNumber: formProfile.documentNumber,
          nationality: formProfile.nationality,
          expiredDate: formProfile.expiredDate,
          isDefault: true,
          travelerCode: formProfile.travelerCode
        }
      ])
      .subscribe(updateCustomer => {
        if(this.appName !== 'B2B') {
          this.accountResourceService
            .saveAccountUsingPOST(this.accountData)
            .subscribe(rs => {
              this._snackBar.openFromComponent(SnackbarComponent, {
                data: { message: 'user.update-success', icon: 'check' },
                panelClass: 'snackbar-success',
                verticalPosition: 'top',
                duration: 2000
              });
            });
        } else {
          this.callbackUpdate(updateCustomer)
        }
      });
  }

  callbackUpdate(updateCustomer) {
    if (updateCustomer && updateCustomer.length > 0) {
      setTimeout(() => {
        this._snackBar.openFromComponent(SnackbarComponent, {
          data: { message: 'user.update-success', icon: 'check' },
          panelClass: 'snackbar-success',
          verticalPosition: 'top',
          duration: 2000
        });
        this.accountData.lastName = this.formProfile
          .get('surName')
          .value.toUpperCase();
        this.accountData.firstName = this.formProfile
          .get('firstName')
          .value.toUpperCase();
        this.accountData.phoneNumber = this.formProfile.get(
          'phoneNumber1'
        ).value;
        this.accountData.dob = this.formProfile.get('dob').value;
        this.accountResourceService
          .saveAccountUsingPOST(this.accountData)
          .subscribe();
      }, 500);
    } else if (this.appName === 'B2B' && updateCustomer && updateCustomer.id) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'user.update-success', icon: 'check' },
        panelClass: 'snackbar-success',
        verticalPosition: 'top',
        duration: 2000
      });
    } else {
      this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [{ btnText: 'global.close' }],
          content: 'user.system-error-message',
          heading: 'user.error-message',
          icon: 'exclamation-triangle',
          messageType: 'error'
        },
        panelClass: 'dialog-message',
        disableClose: true
      });
    }
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.customerTravellerFacade.customerTravellerUpdateDetroy();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private createForm() {
    this.formProfile = this.formBuilder.group(
      {
        id: ['', Validators.required],
        customerCode: [''],
        profileId: [''],
        surName: [''],
        firstName: [''],
        agencyName: [{ value: '', disabled: true }],
        agentName: [{ value: '', disabled: true }],
        email: [{ value: '', disabled: true }, Validators.email],
        phoneNumber1: [
          { value: '', disabled: true },
          Validators.pattern('^[0-9]*$')
        ],
        dob: [''],
        gender: [''],
        address1: [''],
        country: [''],
        documentType: [''],
        documentNumber: [''],
        nationality: [''],
        expiredDate: [''],
        agentCACode: [{ value: '', disabled: true }]
      },
      {
        validators: [
          MustMatch2(!!this.appName, 'surName'),
          MustMatch2(!!this.appName, 'firstName'),
          MustMatch2(!!this.appName, 'profileId'),
          MustMatch2(!!this.appName, 'agentName')
        ]
      }
    );
  }
}
