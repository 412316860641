import { AirLowFareSearchResultVM } from '@gtd/b2c-client';
import { LowfareSearchQueryParam } from '@gtd/helpers';
import { Action } from '@ngrx/store';

export enum FlightSearchLowFareActionTypes {
  LoadFlightSearchLowFare = '[FlightSearchLowFare] Load FlightSearchLowFare',
  FlightSearchLowFareLoaded = '[FlightSearchLowFare] FlightSearchLowFare Loaded',
  FlightSearchLowFareLoadError = '[FlightSearchLowFare] FlightSearchLowFare Load Error',
  ResetFlightSearchLowFare = '[FlightSearchLowFare] Reset FlightSearchLowFare'
}

export class LoadFlightSearchLowFare implements Action {
  readonly type = FlightSearchLowFareActionTypes.LoadFlightSearchLowFare;
  constructor(public payload: LowfareSearchQueryParam) {}
}

export class ResetFlightSearchLowFare implements Action {
  readonly type = FlightSearchLowFareActionTypes.ResetFlightSearchLowFare;
}

export class FlightSearchLowFareLoadError implements Action {
  readonly type = FlightSearchLowFareActionTypes.FlightSearchLowFareLoadError;
  constructor(public payload: any) {}
}

export class FlightSearchLowFareLoaded implements Action {
  readonly type = FlightSearchLowFareActionTypes.FlightSearchLowFareLoaded;
  constructor(public payload: AirLowFareSearchResultVM) {}
}

export type FlightSearchLowFareAction =
  | LoadFlightSearchLowFare
  | FlightSearchLowFareLoaded
  | FlightSearchLowFareLoadError
  | ResetFlightSearchLowFare;

export const fromFlightSearchLowFareActions = {
  LoadFlightSearchLowFare,
  FlightSearchLowFareLoaded,
  FlightSearchLowFareLoadError,
  ResetFlightSearchLowFare
};
