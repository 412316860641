import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { ItineraryInfoVM, PricedItinerary } from '@gtd/b2c-client';
import { DialogMessageComponent } from '@gtd/components/dialog-message';
import { SnackbarComponent } from '@gtd/components/snackbar';
import { GaTrackingService } from '@gtd/ga-tracking';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, Subscription } from 'rxjs';
import { ComboCreateDraftBookingFacade } from '../../state/combo-create-draft-booking/combo-create-draft-booking.facade';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';

@Component({
  selector: 'gtd-combo-confirm',
  templateUrl: './combo-confirm.component.html',
  styleUrls: ['./combo-confirm.component.scss'],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated
})
export class ComboConfirmComponent implements OnInit, OnDestroy {
  searchParams: any;
  flightBooking$ = this._comboSelectedService.flightListSelected$;
  hotelBooking$ = this._comboSelectedService.hotelSelected$;
  roomBooking$ = this._comboSelectedService.roomSelected$;
  hotelInfo: any;
  newTab = null;
  param;
  appName = environment.appName;
  hotelOnePersonPrice: number;
  adultTicketPrice = 0;
  childTicketPrice = 0;
  infantTicketPrice = 0;
  flightSelectedDone$ = this._comboSelectedService.flightSelectedDone$;
  subscriptions = new Subscription();
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _comboSelectedService: ComboSelectedService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public deviceService: DeviceDetectorService,
    public comboCreateDraftbookingFacade: ComboCreateDraftBookingFacade,
    private _gaTrackingService: GaTrackingService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.activatedRoute.queryParams.subscribe(param => {
      this.param = param;
      this._handleQueryFromParams(
        JSON.parse(decodeURIComponent(atob(param.params)))
      );
      this.hotelInfo = JSON.parse(decodeURIComponent(atob(param.hotel)));
    });
  }

  ngOnInit() {
    this.flightSelectedDone$.take(1).subscribe(done => {
      if (!done && this._comboSelectedService.flightReset) {
        this._comboSelectedService.resetFLight();
        this._comboSelectedService.flightReset = false;
        const flightBookingFromStorage = JSON.parse(
          localStorage.getItem('flightBooking')
        );
        for (const item of flightBookingFromStorage) {
          this._comboSelectedService.selectFlight(
            item.searchId,
            item.pricedItinerary,
            item.itemFlight,
            item.type
          );
        }
      }
    });
    this.subscriptions.add(
      this.flightSelectedDone$.subscribe(done => {
        if (done) {
          setTimeout(() => {
            const flightListPrice = this._comboSelectedService.flightListSelectedArray.map(
              flightSelected => flightSelected.pricedItinerary
            );
            this._comboSelectedService.setLowestPriceInFlight(flightListPrice);
          }, 100);
        }
      })
    );
    combineLatest(this.flightBooking$, this.hotelBooking$, this.roomBooking$)
      .take(1)
      .subscribe(data => {
        const [flight, hotel, room] = data;
        if (
          localStorage.getItem('hotelBooking') &&
          localStorage.getItem('flightBooking') &&
          localStorage.getItem('roomBooking')
        ) {
          const hotelBooking = JSON.parse(localStorage.getItem('hotelBooking'));
          const roomBooking = JSON.parse(localStorage.getItem('roomBooking'));
          const flightBooking = JSON.parse(
            localStorage.getItem('flightBooking')
          );
          if (!hotel && !flight && !room) {
            this._comboSelectedService.selectHotel(hotelBooking);
            this._comboSelectedService.selectRoom(roomBooking);
            const type = flightBooking.length === 1 ? 'ONEWAY' : 'ROUNDTRIP';
            for (const item of flightBooking) {
              this._comboSelectedService.selectFlight(
                item.searchId,
                item.pricedItinerary,
                item.itemFlight,
                item.type
              );
            }
          }
        } else {
          this.router.navigate(['/combo/hotel'], {
            queryParams: {
              params: this.param.params
            }
          });
        }
        // if (flight && hotel && room) {
        //   this.flightBooking = flight;
        //   this.hotelBooking = hotel;
        //   this.roomBooking = room;
        // } else {
        //   this.router.navigate(['/combo/hotel'], {
        //     queryParams: {
        //       params: this.param
        //     }
        //   });
        // }
      });

    this.subscriptions.add(
      this.comboCreateDraftbookingFacade.allComboCreateDraftBooking$.subscribe(
        createDraftBooking => {
          if (createDraftBooking) {
            this._snackBar.dismiss();
            if (createDraftBooking.success) {
              localStorage.removeItem('flightBooking');
              localStorage.removeItem('hotelBooking');
              localStorage.removeItem('roomBooking');
              this._gaTrackingService.track('combo_make_booking');
              this._gaTrackingService.track('make_booking');
              if (isPlatformBrowser(this.platformId)) {
                window.parent.postMessage({ action: 'scrollToTop' }, '*');
              }
              this._snackBar.dismiss();
              this.router
                .navigate(['/checkout/combo'], {
                  queryParams: {
                    bookingNumber: createDraftBooking.booking.bookingNumber
                  }
                })
                .then(() => {});
              // if (this.appName === 'B2B2C') {
              //   let newWindow = window.open('', '_self');
              //   newWindow.location.href = decodeURIComponent(url);
              // } else {
              //   if (this.deviceService.isDesktop() === true) {
              //     this.newTab.location.href = decodeURIComponent(url);
              //     this.router.navigate(['/'], {
              //       queryParams: {
              //         type: 'combo'
              //       }
              //     });
              //   } else {
              //     this.router
              //       .navigate(['/checkout/combo'], {
              //         queryParams: {
              //           bookingNumber: createDraftBooking.booking.bookingNumber
              //         }
              //       })
              //       .then(() => {});
              //   }
              // }
              //   .navigate(['/checkout/combo'], {
              //     queryParams: {
              //       bookingNumber: createDraftBooking.booking.bookingNumber
              //     }
              //   })
              //   .then(() => {});
            } else {
              // if (this.newTab !== null) {
              //   this.newTab.close();
              // }
              this.dialog.open(DialogMessageComponent, {
                data: {
                  btn: [{ btnText: 'global.close' }],
                  content: 'user.system-error-message',
                  heading: 'user.system-error',
                  icon: 'exclamation-triangle',
                  messageType: 'error'
                },
                panelClass: 'dialog-message',
                disableClose: true
              });
            }
          }
          this._snackBar.dismiss();
        },
        () => {
          this._snackBar.dismiss();
          this.dialog.open(DialogMessageComponent, {
            data: {
              btn: [
                {
                  btnText: 'booking-result.reload',
                  btnReload: true
                }
              ],
              content: 'booking-result.message-error-validate-air-fare',
              heading: 'booking-result.validate-air-fare-error',
              icon: 'exclamation-triangle',
              messageType: 'error'
            },
            panelClass: 'dialog-message',
            disableClose: true
          });
        }
      )
    );
    this.subscriptions.add(
      this.comboCreateDraftbookingFacade.errorComboCreateDraftBooking$.subscribe(
        getErrors => {
          if (getErrors) {
            this._snackBar.dismiss();
            this.dialog.open(DialogMessageComponent, {
              data: {
                btn: [{ btnText: 'global.close' }],
                content: 'user.system-error-message',
                heading: 'user.system-error',
                icon: 'exclamation-triangle',
                messageType: 'error'
              },
              panelClass: 'dialog-message',
              disableClose: true
            });
          }
        }
      )
    );
  }
  _handleQueryFromParams(searchParams: any) {
    this.searchParams = searchParams;
  }

  createCraftBooking() {
    if (
      this.hotelInfo.tripId &&
      this.hotelInfo.roomId &&
      this.hotelInfo.ratePlanId
    ) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        data: {
          message: 'hotel-detail.initializing-booking',
          loading: true
        },
        panelClass: 'snackbar-loading',
        verticalPosition: 'top'
      });
      const itineraryInfoVM = new Array<ItineraryInfoVM>();
      const flightBooking = JSON.parse(localStorage.getItem('flightBooking'));
      for (let i = 0; i < flightBooking.length; i++) {
        itineraryInfoVM.push({
          fareSourceCode:
            flightBooking[i].pricedItinerary.airItineraryPricingInfo
              .fareSourceCode,
          groupId: flightBooking[i].itemFlight.groupId,
          searchId: flightBooking[i].searchId
        });
      }
      this.comboCreateDraftbookingFacade.createDraftBooking({
        createDraftBookingHotelPayload: {
          tripId: this.hotelInfo.tripId,
          roomId: this.hotelInfo.roomId,
          ratePlanId: this.hotelInfo.ratePlanId
        },
        ticketDraftBookingVM: {
          itineraryInfos: itineraryInfoVM
        }
      });
    } else {
      this._snackBar.dismiss();
      console.error('param required invalid {}', this.hotelInfo);
      this.dialog.open(DialogMessageComponent, {
        data: {
          btn: [{ btnText: 'global.close' }],
          content: 'user.system-error-message',
          heading: 'user.system-error',
          icon: 'exclamation-triangle',
          messageType: 'error'
        },
        panelClass: 'dialog-message',
        disableClose: true
      });
    }

    // window.open('/checkout/combo');
    // if (this.appName !== 'B2B2C') {
    // this.newTab = window.open('/checkout/combo');
    // }
  }
  listenHotelChange(type: 'hotel' | 'room') {
    if (type === 'hotel') {
      this.router.navigate(['/combo/hotel'], {
        queryParams: {
          params: this.param.params,
          hotel: this.param.hotel,
          flightSelected: true
        }
      });
    } else {
      this.router.navigate(['/combo/hotel/detail'], {
        queryParams: {
          params: this.param.params,
          hotel: this.param.hotel
        }
      });
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.comboCreateDraftbookingFacade.reset();
  }

  getDestination(item: any, type: string, idx: number): any {
    if (item.itemFlight.flightType === 'INTERNATIONAL') {
      let originDestinationOptions = item.pricedItinerary.originDestinationOptions.filter(
        (originDestinationOption: any) =>
          originDestinationOption.flightDirection === (idx === 0 ? 'D' : 'R')
      );
      if (!originDestinationOptions.length) return null;
      return {
        city:
          type === 'destination'
            ? originDestinationOptions[0].destinationCity
            : originDestinationOptions[0].originCity,
        city2:
          type === 'destination'
            ? originDestinationOptions[0].destinationCity
            : originDestinationOptions[0].originCity,
        code:
          type === 'destination'
            ? originDestinationOptions[0].destinationLocationCode
            : originDestinationOptions[0].originLocationCode
      };
    } else {
      const flight = item.itemFlight;
      return {
        city:
          type === 'destination' ? flight.destinationCity : flight.originCity,
        city2:
          type === 'destination' ? flight.destinationCity : flight.originCity,
        code:
          type === 'destination'
            ? flight.destinationLocationCode
            : flight.originLocationCode
      };
    }
  }

  resetFlight(type: 'from' | 'to', pricedItinerary: PricedItinerary) {
    const flightBooking = JSON.parse(localStorage.getItem('flightBooking'));
    this._comboSelectedService.removeFlight(
      type,
      flightBooking[type === 'from' ? 0 : 1].pricedItinerary
        .airItineraryPricingInfo.itinTotalFare.totalFare.amount
    );
    let dataComboSearch = localStorage.getItem('dataComboSearch');
    let params = JSON.stringify({
      ...JSON.parse(dataComboSearch),
      flightType: flightBooking[0].itemFlight.flightType,
      typeSelect: type
    });
    this.router.navigate(['/combo/flight'], {
      queryParams: {
        params: btoa(encodeURIComponent(params)),
        hotel: this.param.hotel
      }
    });

  }

  getDateTimeFlight(itemFlight: any, type: 'D' | 'R') {
    if(itemFlight.pricedItineraries && itemFlight.pricedItineraries.length &&
      itemFlight.pricedItineraries[0].originDestinationOptions &&
      itemFlight.pricedItineraries[0].originDestinationOptions.length
    ) {
      let itemFlightType = itemFlight.pricedItineraries[0].originDestinationOptions.filter(
        destination => destination.flightDirection === type
      )
      return itemFlightType.length ? itemFlightType[0].originDateTime: null
    }
  }
}
