import { AirItineraryInfo, ItineraryFilter } from '@gtd/b2c-client';
import { defaultFilter, DEFAULT_PAGINATION_OFFSET } from '@gtd/helpers';
import {
  FlightFilterAvailableAction,
  FlightFilterAvailableActionTypes
} from './flight-filter-available.actions';

export const FLIGHTFILTERAVAILABLE_FEATURE_KEY = 'flightFilterAvailable';

export interface FlightFilterAvailableState extends ItineraryFilter {
  page?: number;
  size?: number;
  sort?: Array<string>;
  searchID?: string;
  filterFromPrice?: number;
  filterToPrice?: number;
  departureItinerary: AirItineraryInfo;
}
export interface SelectDepartureItem {
  searchID?: string;
  departureItinerary?: AirItineraryInfo;
}
/**
 * Interface for the 'FlightFilterAvailable' data used in
 *  - FlightFilterAvailableState, and the reducer function
 *
 *  Note: replace if already defined in another module
 */

/* tslint:disable:no-empty-interface */
export interface Entity {}

export interface FlightFilterAvailablePartialState {
  readonly [FLIGHTFILTERAVAILABLE_FEATURE_KEY]: FlightFilterAvailableState;
}

export const initialState: FlightFilterAvailableState = {
  ...defaultFilter,
  page: 0,
  size: DEFAULT_PAGINATION_OFFSET,
  sort: null,
  searchID: '',
  filterFromPrice: null,
  filterToPrice: null,
  departureItinerary: null
};

export function reducer(
  state: FlightFilterAvailableState = initialState,
  action: FlightFilterAvailableAction
): FlightFilterAvailableState {
  switch (action.type) {
    case FlightFilterAvailableActionTypes.ResetFlightFilterAvailable: {
      state = { ...initialState };
      break;
    }
    case FlightFilterAvailableActionTypes.ResetFlightFilterAvailableRoundTrip: {
      initialState.searchID = action.payload.searchID;
      initialState.flightType = action.payload.flightType;
      state = { ...initialState };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeAirlineOptions: {
      state = {
        ...state,
        airlineOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeArrivalDateTimeOptions: {
      state = {
        ...state,
        arrivalDateTimeOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeArrivalDateTimeReturnOptions: {
      state = {
        ...state,
        arrivalDateTimeReturnOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeCabinClassOptions: {
      state = {
        ...state,
        cabinClassOptions: action.payload.slice()
      };

      break;
    }
    case FlightFilterAvailableActionTypes.ChangeDepartureDateTimeOptions: {
      state = {
        ...state,
        departureDateTimeOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeFlightType: {
      state = {
        ...state,
        flightType: action.payload
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeStep: {
      state = {
        ...state,
        step: action.payload
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeStopOptions: {
      state = {
        ...state,
        stopOptions: action.payload.slice()
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeTicketPolicyOptions: {
      state = {
        ...state,
        ticketPolicyOptions: action.payload.slice()
      };
      break;
    }

    case FlightFilterAvailableActionTypes.ChangeFlightPaginationPage: {
      state = {
        ...state,
        page: action.payload
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeFlightPaginationSize: {
      state = {
        ...state,
        size: action.payload,
        page: 0
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeFlightPaginationSort: {
      state = {
        ...state,
        sort: action.payload.slice(),
        page: 0
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangeSearchID: {
      state = {
        ...state,
        searchID: action.payload
      };
      break;
    }
    case FlightFilterAvailableActionTypes.SelectDeparture: {
      state = {
        ...initialState,
        searchID: action.payload.searchID,
        departureItinerary: action.payload.departureItinerary
      };
      break;
    }
    case FlightFilterAvailableActionTypes.ChangePrice: {
      state = {
        ...state,
        filterFromPrice: action.payload.fromPrice,
        filterToPrice: action.payload.toPrice
      };
      break;
    }
  }
  return state;
}
