import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from "@env/environment";
import {FlightDetailDialogComponent} from "@gtd/flights/ui/flight-detail-dialog";
import {MatDialog} from "@angular/material";
import { ComboDetailDialogComponent } from '@gtd/components/combo-detail-dialog';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'gtd-flight-booking-item-summary',
  templateUrl: './flight-booking-item-summary.component.html',
  styleUrls: ['./flight-booking-item-summary.component.scss']
})
export class FlightBookingItemSummaryComponent implements OnInit {
  @Input() bookingItem: any;
  @Input() toDestination: any;
  @Input() fromDestination: any;
  @Input() searchId: string;
  @Input() groupId: string;
  @Input() datetime: string;
  @Input() flightBookingType: string;
  @Input() flightType: string;
  @Input() isDisabled: boolean;
  @Input() isDetail = true;
  @Input() isChanged: boolean =  true;
  @Input() isCombo: boolean;
  @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentLang: string;

  constructor(
    private _dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.currentLang = localStorage.getItem('language');
  }


  displayDestination(destination) {
    return destination && destination.city && destination.code
      ? `<span>${
        this.currentLang == 'vi' && destination.city2
          ? destination.city2
          : destination.city
      } (</span>${destination.code}<span>)</span>`
      : destination;
  }

  displayAirlineLogo(groupPricedItinerary: any) {
    if(groupPricedItinerary.airline) {
      if (
        groupPricedItinerary.airline === 'VN' ||
        groupPricedItinerary.airline === 'VJ' ||
        groupPricedItinerary.airline === 'QH' ||
        groupPricedItinerary.airline === 'BL'
      )
        return environment.cdn.logoAirline + groupPricedItinerary.airline + '.svg';
      else return environment.cdn.logoAirline + groupPricedItinerary.airline + '.gif';
    } else {
      if(groupPricedItinerary
        && groupPricedItinerary.flightSegments
        && groupPricedItinerary.flightSegments.length
      ) {
        let airlineCode = groupPricedItinerary.flightSegments[0].operatingAirline.code
        if (
          airlineCode === 'VN' ||
          airlineCode === 'VJ' ||
          airlineCode === 'QH' ||
          airlineCode === 'BL'
        )
          return environment.cdn.logoAirline + airlineCode + '.svg';
        else return environment.cdn.logoAirline + airlineCode + '.gif';
      }
    }
  }

  editFlight() {
    this.changed.emit(true);
  }

  openDetailDialog(flightType: string) {
    this._dialog.open(
      this.isCombo? ComboDetailDialogComponent as ComponentType<any> : FlightDetailDialogComponent as ComponentType<any>
      , {
      data: {
        flightItem: this.bookingItem.groupPricedItinerary,
        pricedItinerary: this.bookingItem.pricedItinerary,
        originDestinationOption: this.bookingItem.pricedItinerary.originDestinationOptions[0],
        searchId: this.searchId,
        groupId: this.groupId,
        flightBookingType: this.flightBookingType,
        flightType: flightType
      },
      position: {
        right: '0',
        top: '0'
      },
      autoFocus: false,

      panelClass: [
        'animate__animated',
        'animate__slideInRight',
        'animate__faster',
        'flight-detail-dialog__deep'
      ],
      disableClose: true
    });
  }

}
