import { Action } from '@ngrx/store';
import { Entity } from './flight-add-booking-traveller.reducer';

export enum FlightAddBookingTravellerActionTypes {
  LoadFlightAddBookingTraveller = '[FlightAddBookingTraveller] Load FlightAddBookingTraveller',
  FlightAddBookingTravellerLoaded = '[FlightAddBookingTraveller] FlightAddBookingTraveller Loaded',
  FlightAddBookingTravellerLoadError = '[FlightAddBookingTraveller] FlightAddBookingTraveller Load Error'
}

export class LoadFlightAddBookingTraveller implements Action {
  readonly type =
    FlightAddBookingTravellerActionTypes.LoadFlightAddBookingTraveller;
}

export class FlightAddBookingTravellerLoadError implements Action {
  readonly type =
    FlightAddBookingTravellerActionTypes.FlightAddBookingTravellerLoadError;
  constructor(public payload: any) {}
}

export class FlightAddBookingTravellerLoaded implements Action {
  readonly type =
    FlightAddBookingTravellerActionTypes.FlightAddBookingTravellerLoaded;
  constructor(public payload: Entity[]) {}
}

export type FlightAddBookingTravellerAction =
  | LoadFlightAddBookingTraveller
  | FlightAddBookingTravellerLoaded
  | FlightAddBookingTravellerLoadError;

export const fromFlightAddBookingTravellerActions = {
  LoadFlightAddBookingTraveller,
  FlightAddBookingTravellerLoaded,
  FlightAddBookingTravellerLoadError
};
