import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { HotelBookingPartialState } from './hotel-booking.reducer';
import { hotelBookingQuery } from './hotel-booking.selectors';
import { LoadHotelBooking, ResetHotelBooking } from './hotel-booking.actions';
import { CheckoutPayload } from '@gtd/b2c-client';
import { Reset } from '@ngrx/store-devtools/src/actions';

@Injectable()
export class HotelBookingFacade {
  loaded$ = this.store.pipe(select(hotelBookingQuery.getLoaded));
  allHotelBooking$ = this.store.pipe(
    select(hotelBookingQuery.getAllHotelBooking)
  );
  selectedHotelBooking$ = this.store.pipe(
    select(hotelBookingQuery.getSelectedHotelBooking)
  );

  constructor(private store: Store<HotelBookingPartialState>) {}

  loadAll(payload: CheckoutPayload) {
    this.store.dispatch(new LoadHotelBooking(payload));
  }

  reset() {
    this.store.dispatch(new ResetHotelBooking());
  }
}
