import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Amenity } from '@gtd/b2c-client';

@Component({
  selector: 'gtd-amenities',
  templateUrl: './amenities.component.html',
  styleUrls: ['./amenities.component.scss']
})
export class AmenitiesComponent implements OnInit {
  amenitiesGroup: any;

  constructor(
    public dialogRef: MatDialogRef<AmenitiesComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      amenities: Array<Amenity>;
    }
  ) {}

  ngOnInit() {
    this.amenitiesGroup = this.data.amenities.reduce(function(arr, cur) {
      arr[cur.group] = arr[cur.group] || [];
      arr[cur.group].push(cur);
      return arr;
    }, Object.create(null));
  }

  close() {
    this.dialogRef.close();
  }
}
