import { Action } from '@ngrx/store';
import { Entity } from './hotel-search-filter-options.reducer';
import { FilterOptionsResult } from '@gtd/b2c-client';

export enum HotelSearchFilterOptionsActionTypes {
  LoadHotelSearchFilterOptions = '[HotelSearchFilterOptions] Load HotelSearchFilterOptions',
  ResetHotelSearchFilterOptions = '[HotelSearchFilterOptions] Reset HotelSearchFilterOptions',
  HotelSearchFilterOptionsLoaded = '[HotelSearchFilterOptions] HotelSearchFilterOptions Loaded',
  HotelSearchFilterOptionsLoadError = '[HotelSearchFilterOptions] HotelSearchFilterOptions Load Error'
}

export class LoadHotelSearchFilterOptions implements Action {
  readonly type =
    HotelSearchFilterOptionsActionTypes.LoadHotelSearchFilterOptions;
  constructor(public payload: string) {}
}

export class ResetHotelSearchFilterOptions implements Action {
  readonly type =
    HotelSearchFilterOptionsActionTypes.ResetHotelSearchFilterOptions;
}

export class HotelSearchFilterOptionsLoadError implements Action {
  readonly type =
    HotelSearchFilterOptionsActionTypes.HotelSearchFilterOptionsLoadError;
  constructor(public payload: any) {}
}

export class HotelSearchFilterOptionsLoaded implements Action {
  readonly type =
    HotelSearchFilterOptionsActionTypes.HotelSearchFilterOptionsLoaded;
  constructor(public payload: FilterOptionsResult) {}
}

export type HotelSearchFilterOptionsAction =
  | LoadHotelSearchFilterOptions
  | ResetHotelSearchFilterOptions
  | HotelSearchFilterOptionsLoaded
  | HotelSearchFilterOptionsLoadError;

export const fromHotelSearchFilterOptionsActions = {
  LoadHotelSearchFilterOptions,
  ResetHotelSearchFilterOptions,
  HotelSearchFilterOptionsLoaded,
  HotelSearchFilterOptionsLoadError
};
