import { GroupPricedItinerary, PricedItinerary } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';

export enum FlightBookingActionTypes {
  ResetFlightBooking = '[FlightBooking] Reset FlightBooking',
  ResetFlightWay = '[FlightBooking] Reset FlightWay',
  SetFlightBooking = '[FlightBooking] Set FlightBooking',
  SetRoundType = '[FlightBooking] Set RoundType'
}

export interface FlightBooking {
  searchId: string;
  pricedItinerary: PricedItinerary;
  groupPricedItinerary: GroupPricedItinerary;
}

export class ResetFlightBooking implements Action {
  readonly type = FlightBookingActionTypes.ResetFlightBooking;
}

export class ResetFlightWay implements Action {
  readonly type = FlightBookingActionTypes.ResetFlightWay;
  constructor(public payload: 'from' | 'to') {}
}

export class SetFlightBooking implements Action {
  readonly type = FlightBookingActionTypes.SetFlightBooking;
  constructor(public payload: FlightBooking) {}
}

export class SetRoundType implements Action {
  readonly type = FlightBookingActionTypes.SetRoundType;
  constructor(public payload: GroupPricedItinerary.RoundTypeEnum) {}
}

export type FlightBookingAction =
  | ResetFlightBooking
  | SetFlightBooking
  | SetRoundType
  | ResetFlightWay;

export const fromFlightBookingActions = {
  ResetFlightBooking,
  SetFlightBooking,
  SetRoundType,
  ResetFlightWay
};
