import { Action } from '@ngrx/store';
import { Entity } from './hotel-create-draft-booking.reducer';
import {
  CreateDraftBookingHotelPayload,
  OtaResultOfBooking
} from '@gtd/b2c-client';
import { Reset } from '@ngrx/store-devtools/src/actions';

export enum HotelCreateDraftBookingActionTypes {
  LoadHotelCreateDraftBooking = '[HotelCreateDraftBookingRoom] Load HotelCreateDraftBooking',
  ResetHotelCreateDraftBooking = '[HotelCreateDraftBookingRoom] Reset HotelCreateDraftBooking',
  HotelCreateDraftBookingLoaded = '[HotelCreateDraftBookingRoom] HotelCreateDraftBooking Loaded',
  HotelCreateDraftBookingLoadError = '[HotelCreateDraftBookingRoom] HotelCreateDraftBooking Load Error'
}

export class LoadHotelCreateDraftBooking implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.LoadHotelCreateDraftBooking;
  constructor(public payload: CreateDraftBookingHotelPayload) {}
}

export class ResetHotelCreateDraftBooking implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.ResetHotelCreateDraftBooking;
}

export class HotelCreateDraftBookingLoadError implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.HotelCreateDraftBookingLoadError;
  constructor(public payload: any) {}
}

export class HotelCreateDraftBookingLoaded implements Action {
  readonly type =
    HotelCreateDraftBookingActionTypes.HotelCreateDraftBookingLoaded;
  constructor(public payload: OtaResultOfBooking) {}
}

export type HotelCreateDraftBookingAction =
  | LoadHotelCreateDraftBooking
  | ResetHotelCreateDraftBooking
  | HotelCreateDraftBookingLoaded
  | HotelCreateDraftBookingLoadError;

export const fromHotelCreateDraftBookingActions = {
  LoadHotelCreateDraftBooking,
  ResetHotelCreateDraftBooking,
  HotelCreateDraftBookingLoaded,
  HotelCreateDraftBookingLoadError
};
