import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Amenity, CancelPenalty, PropertyRoom, RatePlan} from '@gtd/b2c-client';
import {HotelBookingFacade, HotelSearchAllRatesFacade} from '@gtd/hotels/data-access/store';
import {RoomComponent} from '@gtd/hotels/ui/detail-dialog';
import {ExchangeOptionService, PartnerService} from '@gtd/lotus/shared/data-access/services';
import {Observable, of} from 'rxjs';
import {debounceTime, map, tap} from 'rxjs/operators';
import {HotelFunctionService} from "@gtd/hotels/data-access/services";

@Component({
  selector: 'gtd-rate-plan-item',
  templateUrl: './rate-plan-item.component.html',
  styleUrls: ['./rate-plan-item.component.scss']
})
export class RatePlanItemComponent implements OnInit, OnChanges {
  @Input() ratePlan: RatePlan;
  @Input() room: PropertyRoom;
  search: any;
  roomAmenities: Array<Amenity> = [];
  amenitiesWithoutDescription: Array<Amenity> = [];
  tripId: string;
  startDate: string;
  endDate: string;
  partner$ = this._partnerService.partner$;
  exchangeOption = 'CASH';
  rewardExchangeRate$: Observable<number>;
  numberOfRoom: number;
  dayGap: number;
  utm_source = localStorage.getItem('utm_source')

  constructor(
    private _hotelSearchAllRatePlansFacade: HotelSearchAllRatesFacade,
    private _hotelBookingFacade: HotelBookingFacade,
    private _hotelFunctionService: HotelFunctionService,
    private _partnerService: PartnerService,
    private _exchangeOptionService: ExchangeOptionService,
    private _dialog: MatDialog
  ) {
    this.rewardExchangeRate$ = this._hotelSearchAllRatePlansFacade.allHotelSearchAllRates$.pipe(
      map(rates => rates.rewardExchangeRate)
    );
    this._exchangeOptionService.option$.subscribe(option => {
      if (!option) {
        return;
      }
      this.exchangeOption = option;
    });
    this._hotelSearchAllRatePlansFacade.allHotelSearchAllRates$
      .pipe(
        tap(rates => {
          if (!rates) return;
          this.roomAmenities = rates.amenities;
          this.numberOfRoom = rates.rooms.length;
        })
      )
      .subscribe();
    this._hotelSearchAllRatePlansFacade.tripId$
      .pipe(tap(id => (this.tripId = id)))
      .subscribe();
    this._hotelSearchAllRatePlansFacade.searchId$
      .subscribe(searchId => {
        if(searchId) {
          this.generateDataSearch(searchId);
        }
      });
  }

  generateDataSearch(searchId: string) {
    const searchIdP = JSON.parse(atob(searchId)) as any;
    this.search = ({
      locationCode: searchIdP.searchCode,
      checkIn: searchIdP.checkIn,
      checkOut: searchIdP.checkOut,
      roomsArrs: searchIdP.paxInfos
    })
  }

  ngOnInit() {
    if(this.ratePlan) {
      this.amenitiesWithoutDescription = this.ratePlan.amenities.filter(
        amenity => amenity.id !== 'rate_description'
      );
    }
  }

  getCancelPelnaltiesDate(type: 'start' | 'end', cancelPenalty: CancelPenalty) {
    this.startDate = cancelPenalty.startDate
      .split(' ')[0]
      .replace('.999999999', '');
    this.endDate = cancelPenalty.endDate
      .split(' ')[0]
      .replace('.999999999', '');
    if (type == 'start') {
      return this.startDate;
    }
    return this.endDate;
  }

  getPricePerNight(type: 'after' | 'before', utm_source?: string) {
    return of(null).pipe(
      debounceTime(100),
      map(value => {
        this.dayGap = this._hotelFunctionService.getDayGap(
          this.search.checkIn,
          this.search.checkOut
        );
        let numberOfRooms = this.search.roomsArrs.length;
        let numberOfNights = this.dayGap;

        if(utm_source !== 'DHA') {
          const after =
            Math.ceil(
              this.ratePlan.basePrice / (numberOfRooms * numberOfNights * 1000)
            ) * 1000;
          const before =
            Math.ceil(
              this.ratePlan.basePriceBeforePromo /
              (numberOfRooms * numberOfNights * 1000)
            ) * 1000;
          return type == 'after' ? after : before;
        } else {
          const after = this.ratePlan.basePrice / (numberOfRooms * numberOfNights);
          const before =this.ratePlan.basePriceBeforePromo / (numberOfRooms * numberOfNights);
          return type == 'after' ? after : before;
        }
      })
    )
  }

  getNumberOfPassengers(type: 'adult' | 'child') {
    return this.search.roomsArrs.reduce((arr, cur) => {
      return arr + cur[type == 'adult' ? 'adultQuantity' : 'childQuantity'];
    }, 0);
  }

  selectRoom() {
    // this._hotelNewTabService.setTab(window.open('/checkout/hotel', '_blank'));
    this._hotelBookingFacade.loadAll({
      tripId: this.tripId,
      ratePlanId: this.ratePlan.ratePlanId,
      roomId: this.room.id
    });
  }

  openDetailDialog(index: number) {
    const dialogRef = this._dialog.open(RoomComponent, {
      data: {
        room: this.room,
        ratePlan: this.ratePlan,
        amenities: this.roomAmenities,
        exchangeType: this.exchangeOption,
        exchangeRate: 0.5
      },
      autoFocus: false,
      panelClass: [
        'animate__animated',
        'animate__fadeIn',
        'animate__faster',
        'hotel-detail-dialog',
        'room-dialog'
      ],
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectRoom();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { ratePlan } = changes;
    if (
      ratePlan &&
      ratePlan.currentValue &&
      ratePlan.currentValue.cancelPenalties &&
      ratePlan.currentValue.cancelPenalties.length
    ) {
      this.startDate = ratePlan.currentValue.cancelPenalties[0].startDate.split(
        ' '
      )[0];
      this.endDate = ratePlan.currentValue.cancelPenalties[0].endDate.split(
        ' '
      )[0];
    }
  }
}
