import { AirItineraryFilterRS, AirLowFareFilterVM } from '@gtd/b2c-client';
import { Action } from '@ngrx/store';
import { Entity } from './flight-search-filter-options.reducer';

export enum FlightSearchFilterOptionsActionTypes {
  LoadFlightSearchFilterOptions = '[FlightSearchFilterOptions] Load FlightSearchFilterOptions',
  ResetFlightSearchFilterOptions = '[FlightSearchFilterOptions] Reset FlightSearchFilterOptions',
  FlightSearchFilterOptionsLoaded = '[FlightSearchFilterOptions] FlightSearchFilterOptions Loaded',
  FlightSearchFilterOptionsLoadError = '[FlightSearchFilterOptions] FlightSearchFilterOptions Load Error'
}

export class LoadFlightSearchFilterOptions implements Action {
  readonly type =
    FlightSearchFilterOptionsActionTypes.LoadFlightSearchFilterOptions;
  constructor(public payload: AirLowFareFilterVM) {}
}

export class ResetFlightSearchFilterOptions implements Action {
  readonly type =
    FlightSearchFilterOptionsActionTypes.ResetFlightSearchFilterOptions;
}

export class FlightSearchFilterOptionsLoadError implements Action {
  readonly type =
    FlightSearchFilterOptionsActionTypes.FlightSearchFilterOptionsLoadError;
  constructor(public payload: any) {}
}

export class FlightSearchFilterOptionsLoaded implements Action {
  readonly type =
    FlightSearchFilterOptionsActionTypes.FlightSearchFilterOptionsLoaded;
  constructor(public payload: AirItineraryFilterRS) {}
}

export type FlightSearchFilterOptionsAction =
  | LoadFlightSearchFilterOptions
  | FlightSearchFilterOptionsLoaded
  | FlightSearchFilterOptionsLoadError
  | ResetFlightSearchFilterOptions;

export const fromFlightSearchFilterOptionsActions = {
  LoadFlightSearchFilterOptions,
  FlightSearchFilterOptionsLoaded,
  FlightSearchFilterOptionsLoadError,
  ResetFlightSearchFilterOptions
};
