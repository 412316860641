import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapViewDetailComponent } from './map-view-detail.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatIconModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCtgUEmt9xOzkgnBQsWM0tyNDooMrVgmFQ'
    })
  ],
  declarations: [MapViewDetailComponent],
  entryComponents: [MapViewDetailComponent],
  exports: [MapViewDetailComponent]
})
export class MapViewDetailModule {}
