import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashAndMilesComponent } from './cash-and-miles.component';
import {
  GButtonModule,
  GInputModule,
  GTypographyModule
} from '@gotadi/design-system';
import { Ng5SliderModule } from 'ng5-slider';
import { PipesModule } from '@gtd/hotels/data-access/pipes';
import { MatTooltipModule } from '@angular/material';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        GTypographyModule,
        GButtonModule,
        Ng5SliderModule,
        GInputModule,
        PipesModule,
        MatTooltipModule,
        TranslateModule
    ],
  declarations: [CashAndMilesComponent],
  exports: [CashAndMilesComponent]
})
export class CashAndMilesModule {}
