import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {ComboSelectedService} from "../../../../combo-selected.service";
import {Router} from "@angular/router";

@Component({
  selector: 'gtd-combo-booking',
  templateUrl: './combo-booking.component.html',
  styleUrls: ['./combo-booking.component.scss']
})
export class ComboBookingComponent implements OnInit {
  @Input() flightBooking;
  @Input() hotelBooking;
  @Input() params;
  @Input() searchParams;
  @Output() scrollToSection: EventEmitter<any> = new EventEmitter<any>();
  hotelBookingPrice: number;
  flightBookingPrice: number;
  priceOnePerson: number;
  constructor(
    private router: Router,
    private _comboSelectedService: ComboSelectedService
  ) {}

  ngOnInit() {
    this.priceCalculator();
  }
  priceCalculator() {
    this.flightBookingPrice = this.flightBooking.reduce((arr, cur) => {
      return (
        arr +
        cur.pricedItinerary.airItineraryPricingInfo.itinTotalFare.totalFare
          .amount
      );
    }, 0);

    this.hotelBookingPrice = this.hotelBooking.totalPrice;
    const numberPerson = JSON.parse(this.searchParams.rooms).reduce(
      (arr, cur) => {
        return arr + cur.adultQuantity + cur.infantCount + cur.childQuantity;
      },
      0
    );

    const adultTicketPrice = this.flightBooking.reduce((arr, cur) => {
      return (
        arr +
        cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .baseFare.amount +
        (cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .comboMarkup
          ? cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
              .comboMarkup.amount
          : 0) +
        cur.pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare
          .serviceTax.amount
      );
    }, 0);

    this.priceOnePerson =
      Math.ceil(this.hotelBookingPrice / numberPerson) + adultTicketPrice;
  }
  scrollToRooms() {
    this.scrollToSection.emit('detail');
  }

  getDestination(item: any, type: 'destination' | 'origin'): any {
    if (!item || !item.itemFlight) {
      return null;
    }
    const flight = item.itemFlight;
    return {
      city: type === 'destination' ? flight.destinationCity : flight.originCity,
      city2: type === 'destination' ? flight.destinationCity : flight.originCity,
      code: type === 'destination' ? flight.destinationLocationCode : flight.originLocationCode,
    };
  }

  resetFlight(type: 'from' | 'to') {
    const flightBooking = JSON.parse(localStorage.getItem('flightBooking'));

    this._comboSelectedService.removeFlight(
      type,
      flightBooking[type === 'from' ? 0 : 1].pricedItinerary
        .airItineraryPricingInfo.itinTotalFare.totalFare.amount
    );
    this.router.navigate(['/combo/flight'], {
      queryParams: {
        params: this.params
      }
    });
  }
}
