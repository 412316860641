import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { fadeIn, slideInHorizontal, slideInVertical } from '@gtd/helpers';
import {
  AirItineraryInfo,
  FlightSegment,
  GroupPricedItinerary,
  PricedItinerary
} from '@gtd/b2c-client';
import { GroupItineraryFacade } from '../flight-search/group-itinerary/+state/group-itinerary.facade';
import { combineLatest, Subscription } from 'rxjs';
import { FlightResultItemService } from './flight-result-item.service';
import { FarerulesFacade } from '../flight-search/farerules/+state/farerules.facade';
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { AvailableFlightsFacade } from '../flight-search/flight-cache-search/+state/available-flights.facade';
import { environment } from '@env/environment';
import { SelectItemSrvService } from '../select-item-srv.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ComboVJComboBookingFacade } from '../../../../state/combo-booking/combo-booking.facade';
import { FlightDetailDialogComponent } from '@gtd/flights/ui/flight-detail-dialog';
import { MatDialog } from '@angular/material';
import { ComboSelectedService } from '@gtd/components/combo-flight-booking-item';
import { ComboDetailDialogComponent } from '@gtd/components/combo-detail-dialog';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'gtd-flight-result-item',
  templateUrl: './flight-result-item.component.html',
  styleUrls: ['./flight-result-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideInHorizontal, slideInVertical, fadeIn],
  encapsulation:
    environment.appName === 'B2B2C'
      ? ViewEncapsulation.None
      : ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlightResultItemComponent),
      multi: true
    }
  ]
})
export class FlightResultItemComponent
  implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() formSearchResult?: any;
  @Input() groupPricedItineraries?: GroupPricedItinerary;
  @Input() searchId?: string;
  @Input() isCreateBooking: boolean;
  @Input() lowestFlightPrice: number;
  @Input() itemFlight: number;
  @Input() disableButton: boolean;
  @Input() searchParams: any;
  @Input() cabinClassOptions: Array<string>;
  @Input() hotelSelected;
  @Input() roomSelected;
  @Input() flightDirection$;
  @Input() flightBooking: any;

  @Output() listenSelectFlight = new EventEmitter();
  flightBooking$ = this._comboSelectedService.flightListSelected$;

  itemFormGroup: FormGroup;
  flightSegments?: Array<FlightSegment>;
  subscriptions = new Subscription();
  hotelBookedPrice = 0;
  hotelBookedOnePersonPrice = 0;
  panelOpenState: boolean;
  isCollapsed: boolean;
  isDetailJourney: boolean;
  submitLoadMore: boolean;
  submitLoadFareRule: boolean;
  fareSourceCode: string;

  itemFlightSelect: number;

  flightAutoPrice$ = this._comboSelectedService.priceOfFlightAutoSelected$;
  allGroupItinerary$ = this.groupItineraryFacade.allGroupItinerary$;
  flightAutoSelected$ = this._comboSelectedService.flightListSelected$;
  allFarerules$ = this.farerulesFacade.allFarerules$;
  loaded$ = this.availableFlightsFacade.loaded$;

  appName = '';

  constructor(
    private formBuilder: FormBuilder,
    private groupItineraryFacade: GroupItineraryFacade,
    private farerulesFacade: FarerulesFacade,
    private availableFlightsFacade: AvailableFlightsFacade,
    private flightResultItemService: FlightResultItemService,
    public controlContainer: ControlContainer,
    private selectItemSrvService: SelectItemSrvService,
    private deviceDetectorService: DeviceDetectorService,
    private comboBookingFacade: ComboVJComboBookingFacade,
    private _comboSelectedService: ComboSelectedService,
    private _dialog: MatDialog
  ) {}

  ngOnInit() {
    this.appName = localStorage.getItem('appName');
    combineLatest(this.flightAutoPrice$, this.flightDirection$).subscribe(
      data => {
        if (data) {
          const [priceArr, direction] = data;
          const priceArrLength = priceArr.length;
          if (priceArr && direction) {
            this.lowestFlightPrice =
              direction === 'D' ? priceArr[0] : priceArr[1];
          }
        }
      }
    );

    this.hotelBookedPrice =
      this.hotelSelected && this.roomSelected
        ? this.hotelSelected.totalPrice +
          this.roomSelected.ratePlan.additionPrice
        : this.hotelSelected
        ? this.hotelSelected.totalPrice
        : 0;
    const numOfPerson =
      this.searchParams.adult +
      this.searchParams.child +
      this.searchParams.infant;
    this.hotelBookedOnePersonPrice = Math.ceil(
      this.hotelBookedPrice / numOfPerson
    );
    this.itemFormGroup = new FormGroup({
      listSelected: this.formBuilder.group({
        from: [null, Validators.required],
        to: [null],
        roundType: [PricedItinerary.DirectionIndEnum.DEPARTURE],
        resetFlight: [null]
      })
    });

    this.subscriptions.add(
      this.selectItemSrvService.getIsLoadedChildren.subscribe(loaded => {
        if (loaded) {
          this.itemFlightSelect = loaded;
        }
      })
    );

    this.flightSegments = this.groupPricedItineraries.pricedItineraries[0].originDestinationOptions[0].flightSegments;
    this.flightResultItemService.getOpenPanel.subscribe(openPanel => {
      if (openPanel) {
        setTimeout(() => {
          this.isCollapsed = false;
        }, 50);
      }
    });
    this.subscriptions.add(
      this.groupItineraryFacade.allGroupItinerary$.subscribe(
        allGroupPriceItinerary => {
          if (allGroupPriceItinerary) {
            this.submitLoadMore = false;
          }
        }
      )
    );
    this.subscriptions.add(
      this.farerulesFacade.allFarerules$.subscribe(allFarerules => {
        if (allFarerules) {
          this.fareSourceCode = null;
          this.submitLoadFareRule = false;
        }
      })
    );
  }

  getFareRules(fareSourceCode: string) {
    const currentLanguage = localStorage.getItem('language');
    this.fareSourceCode = fareSourceCode;
    this.submitLoadFareRule = true;
    this.farerulesFacade.loadAll(
      {
        fareSourceCode: fareSourceCode,
        groupId: this.groupPricedItineraries.groupId,
        searchId: this.searchId
      },
      currentLanguage
    );
  }

  itineraryMore(flightDirection?: string) {
    this.isDetailJourney = false;
    if (!this.isCollapsed) {
      this.selectItemSrvService.setIsLoadedChildren(-1);
      this.submitLoadMore = true;
      this.flightResultItemService.setOpenPanel(true);
      let departureItinerary = null;
      if (
        this.formSearchResult &&
        this.formSearchResult.listSelected &&
        this.formSearchResult.listSelected.from
      ) {
        departureItinerary = {
          groupId: this.formSearchResult.listSelected.from.itemFlight.groupId,
          airlineCode: this.formSearchResult.listSelected.from.itemFlight
            .airline,
          fareSourceCode: this.formSearchResult.listSelected.from.itemFlight
            .pricedItineraries[0].airItineraryPricingInfo.fareSourceCode,
          supplierCode: this.formSearchResult.listSelected.from.itemFlight
            .airSupplier,
          searchId: this.formSearchResult.listSelected.from.searchId
        } as AirItineraryInfo;
      }

      this.groupItineraryFacade.loadAll({
        id: this.groupPricedItineraries.groupId,
        airSearchId: {
          searchId: this.searchId,
          departureItinerary:
            flightDirection === 'R' ? departureItinerary : null,
          filter: {
            stopOptions: [],
            airlineOptions: [],
            cabinClassOptions: this.cabinClassOptions,
            ticketPolicyOptions: [],
            departureDateTimeOptions: [],
            arrivalDateTimeOptions: [],
            departureDateTimeReturnOptions: [],
            arrivalDateTimeReturnOptions: [],
            flightType: this.groupPricedItineraries.flightType,
            groupId: this.groupPricedItineraries.groupId,
            loadMore: true,
            step: '1'
          }
        },
        page: 0,
        size: 1000,
        sort: ['departureDate', 'asc']
      });
      setTimeout(() => {
        this.isCollapsed = true;
      }, 100);
    } else {
      this.isCollapsed = false;
    }
  }

  selectItem(
    pricedItinerary: PricedItinerary,
    itemFlight: number,
    children?: boolean
  ) {
    this.listenSelectFlight.emit({
      searchId: this.searchId,
      pricedItinerary: pricedItinerary,
      groupPricedItineraries: this.groupPricedItineraries,
      roundType: this.searchParams.roundType
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  writeValue(obj: any): void {
    if (obj) {
      this.itemFormGroup.controls['listSelected'].setValue(obj);
    }
    if (obj === null) {
      this.itemFormGroup.reset();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openDetailDialog(flightItem: any, pricedItinerary: PricedItinerary) {
    this._dialog.open(ComboDetailDialogComponent, {
        data: {
          flightItem: flightItem,
          pricedItinerary: pricedItinerary,
          originDestinationOption: flightItem.pricedItineraries[0].originDestinationOptions[0],
          searchId: this.searchId,
          groupId: flightItem.groupId,
          flightBookingType: flightItem.flightType,
          flightType: flightItem.pricedItineraries[0]
            .originDestinationOptions[0].flightDirection === 'D'? 'from': 'to'
        },
        position: {
          right: '0',
          top: '0'
        },
        autoFocus: false,

        panelClass: [
          'animate__animated',
          'animate__slideInRight',
          'animate__faster',
          'flight-detail-dialog__deep'
        ],
        disableClose: true
      });
  }



  calculatePricePerPassenger(totalFare: number, quantity: number): number {
    return totalFare / quantity;
  }

  calculatePriceDifference(): number | null {
    if (
      this.flightBooking.length &&
      this.flightBooking[0].pricedItinerary
    ) {
      const groupFare =
        this.groupPricedItineraries.pricedItineraries[0].airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount;

      const groupQuantity =
        this.groupPricedItineraries.pricedItineraries[0].airItineraryPricingInfo.adultFare.passengerTypeQuantities.quantity;

      const bookingFare =
        this.flightBooking[0].pricedItinerary.airItineraryPricingInfo.adultFare.passengerFare.totalFare.amount;

      const bookingQuantity =
        this.flightBooking[0].pricedItinerary.airItineraryPricingInfo.adultFare.passengerTypeQuantities.quantity;

      return (
        this.calculatePricePerPassenger(groupFare, groupQuantity) -
        this.calculatePricePerPassenger(bookingFare, bookingQuantity)
      );
    }
    return null;
  }

  isHigherOrEqualPrice(totalFare: number, quantity: number, lowestPrice: number): boolean {
    const pricePerPassenger = this.calculatePricePerPassenger(totalFare, quantity);
    return pricePerPassenger >= lowestPrice;
  }
}
