import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundPipe } from './round.pipe';
import { PriceToPercentPipe } from './price-to-percent.pipe';
import { KeysPipe } from './keys.pipe';
import { MilesPipe } from './miles.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [RoundPipe, PriceToPercentPipe, KeysPipe, MilesPipe],
  exports: [RoundPipe, PriceToPercentPipe, KeysPipe, MilesPipe]
})
export class PipesModule {}
