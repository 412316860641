import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { filter } from 'rxjs/operators';

declare const gtag: any;
export enum SupplierFirstCharacter {
  'AIR' = 'F',
  'HOTEL' = 'H',
  'COMBO' = 'C',
  'TOURS' = 'T'
}
@Injectable({
  providedIn: 'root'
})
export class GaTrackingService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {}

  initialize() {
    this.onRouteChange();

    try {
      const url = 'https://www.googletagmanager.com/gtag/js?id=';
      const gTagScript = document.createElement('script');
      gTagScript.async = true;
      gTagScript.src = `${url}${environment.googleAnalyticsId}`;
      document.head.appendChild(gTagScript);

      const dataLayerScript = document.createElement('script');
      dataLayerScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${
          environment.googleAnalyticsId
        }', {'send_page_view': false});`;
      document.head.appendChild(dataLayerScript);
    } catch (e) {
      console.error('Error adding Google Analytics', e);
    }
  }

  private onRouteChange() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsId, {
          page_path: event.urlAfterRedirects
        });
      }
    });
  }

  track(eventName: string, properties?: any): void {
    if (
      !isPlatformBrowser(this.platformId) ||
      localStorage.getItem('appName') !== 'B2C'
    ) {
      return;
    }
    if (properties) {
      gtag('event', eventName, properties);
    } else {
      gtag('event', eventName);
    }
  }
}
