import { Action } from '@ngrx/store';
import { HotelFilterAvailableState } from './hotel-filter-available.reducer';

export enum HotelFilterAvailableActionTypes {
  ResetHotelFilter = '[HotelFilterAvailable] Reset HotelFilterAvailable',
  ChangeHotelFilterAvailable = '[HotelFilterAvailable] Change HotelFilterAvailable',
  ChangeFilterHotelName = '[HotelFilterAvailable] Change Filter Hotel Name',
  ChangeFilterHotelCategories = '[HotelFilterAvailable] Change Filter Hotel Categories',
  ChangeFilterBreakfastIncluded = '[HotelFilterAvailable] Change Filter Breakfast Included',
  ChangeFilterPrice = '[HotelFilterAvailable] Change Filter Price',
  ChangeFilterDistance = '[HotelFilterAvailable] Change Filter Distance',
  ChangeFilterRateAmenities = '[HotelFilterAvailable] Change Filter Rate Amenities',
  ChangeFilterStarRating = '[HotelFilterAvailable] Change Filter Star Rating',
  ChangeFilterGuestRating = '[HotelFilterAvailable] Change Filter Guest Rating',
  ChangeFilterRoomAmenities = '[HotelFilterAvailable] Change Filter Room Amenities',
  ChangeFilterPropertyAmenities = '[HotelFilterAvailable] Change Filter Property Amenities',
  ChangeFilterRoomViews = '[HotelFilterAvailable] Change Filter Room Views',
  ChangeFilterThemes = '[HotelFilterAvailable] Change Filter Themes',
  ChangeFilterMealPlans = '[HotelFilterAvailable] Change Filter Meal Plans',
  ChangeFilterLanguage = '[HotelFilterAvailable] Change Filter Language',
  ChangeFilterBedTypes = '[HotelFilterAvailable] Change Filter Bed Types',
  ChangeSort = '[HotelFilterAvailable] Change Sort',
  ChangePageNumber = '[HotelFilterAvailable] Change Page Number',
  RetryHotelSearch = '[HotelFilterAvailable] Retry Hotel Search',
  ChangePageSize = '[HotelFilterAvailable] Change Page Size'
}

export class ResetHotelFilter implements Action {
  readonly type = HotelFilterAvailableActionTypes.ResetHotelFilter;
}

export class ChangeHotelFilterAvailable implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeHotelFilterAvailable;
  constructor(public payload: HotelFilterAvailableState) {}
}

export class ChangeFilterHotelName implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterHotelName;
  constructor(public payload: string) {}
}

export class ChangeFilterHotelCategories implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterHotelCategories;
  constructor(public payload: Array<string>) {}
}

export class ChangeFilterBreakfastIncluded implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterBreakfastIncluded;
  constructor(public payload: boolean) {}
}

export class ChangeFilterPrice implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterPrice;
  constructor(public payload: { fromPrice: number; toPrice: number }) {}
}
export class ChangeFilterDistance implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterDistance;
  constructor(public payload: { fromDistance: number; toDistance: number }) {}
}

export class ChangeFilterStarRating implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterStarRating;
  constructor(public payload: Array<string>) {}
}

export class ChangeFilterGuestRating implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterGuestRating;
  constructor(public payload: { from: number; to: number }) {}
}

export class ChangeFilterRoomAmenities implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterRoomAmenities;
  constructor(public payload: Array<string>) {}
}

export class ChangeFilterRateAmenities implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterRateAmenities;
  constructor(public payload: Array<string>) {}
}

export class ChangeFilterPropertyAmenities implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterPropertyAmenities;
  constructor(public payload: Array<string>) {}
}
export class ChangeFilterBedTypes implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterBedTypes;
  constructor(public payload: Array<string>) {}
}

export class ChangeFilterRoomViews implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterRoomViews;
  constructor(public payload: Array<string>) {}
}

export class ChangeFilterThemes implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterThemes;
  constructor(public payload: Array<string>) {}
}

export class ChangeFilterMealPlans implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterMealPlans;
  constructor(public payload: Array<string>) {}
}

export class ChangeFilterLanguage implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeFilterLanguage;
  constructor(public payload: 'vi' | 'en') {}
}

export class ChangeSort implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangeSort;
  constructor(
    public payload: {
      sortField: 'order' | 'price' | 'starRating' | 'guestRating';
      sortOrder: 'ASC' | 'DESC';
    }
  ) {}
}

export class ChangePageNumber implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangePageNumber;
  constructor(public payload: number) {}
}


export class RetryHotelSearch implements Action {
  readonly type = HotelFilterAvailableActionTypes.RetryHotelSearch;
  constructor() {}
}

export class ChangePageSize implements Action {
  readonly type = HotelFilterAvailableActionTypes.ChangePageSize;
  constructor(public payload: number) {}
}

export type HotelFilterAvailableAction =
  | ResetHotelFilter
  | ChangeHotelFilterAvailable
  | ChangeFilterHotelName
  | ChangeFilterHotelCategories
  | ChangeFilterBreakfastIncluded
  | ChangeFilterPrice
  | ChangeFilterStarRating
  | ChangeFilterGuestRating
  | ChangeFilterRoomAmenities
  | ChangeFilterPropertyAmenities
  | ChangeFilterRoomViews
  | ChangeFilterThemes
  | ChangeFilterMealPlans
  | ChangeFilterLanguage
  | ChangeSort
  | ChangePageNumber
  | RetryHotelSearch
  | ChangePageSize
  | ChangeFilterBedTypes
  | ChangeFilterDistance
  | ChangeFilterRateAmenities;

export const fromHotelFilterAvailableActions = {
  ResetHotelFilter,
  ChangeHotelFilterAvailable,
  ChangeFilterHotelName,
  ChangeFilterHotelCategories,
  ChangeFilterBreakfastIncluded,
  ChangeFilterPrice,
  ChangeFilterStarRating,
  ChangeFilterGuestRating,
  ChangeFilterRoomAmenities,
  ChangeFilterPropertyAmenities,
  ChangeFilterRoomViews,
  ChangeFilterThemes,
  ChangeFilterMealPlans,
  ChangeFilterLanguage,
  ChangeSort,
  ChangePageNumber,
  RetryHotelSearch,
  ChangePageSize,
  ChangeFilterBedTypes,
  ChangeFilterDistance,
  ChangeFilterRateAmenities
};
