import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { BranchFilter } from '../brand-filter/brand-item/string2-branch-mapper.pipe';
import { MatCheckboxChange } from '@angular/material';
import { ComboSelectedService } from '../../../combo-selected.service';
import {TranslateService} from "@ngx-translate/core";
import {Subject} from "rxjs";

@Component({
  selector: 'gtd-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['./checkbox-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CheckboxFilterComponent implements OnInit, OnChanges {
  @Input() data: BranchFilter[] = [];
  @Input() checkedItems: string[] = [];
  @Input() name: string;
  @Input() rightInfo: boolean;
  @Input() type: 'checkbox' | 'radio' | 'range' | 'timeRange' = 'checkbox';
  @Input() flightFilterLoaded: boolean;
  @Input() title: string;
  @Input() translateField: boolean;
  @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input() priceRange;
  rangeValue = 0;
  priceValueDebounce$: Subject<any> = new Subject();


  rangeOptions: {
    animate: boolean;
    floor: number;
    ceil: number;
    hideLimitLabels: boolean;
    hidePointerLabels: boolean;
    draggableRange: boolean;
  };

  panelOpenState = true;


  checkedResults = this.checkedItems.slice();


  constructor(
    private _comboSelectedService: ComboSelectedService,
    private _translateService: TranslateService,
  ) {}

  ngOnInit() {
    if (this.name === 'price') {
      this.rangeValue = this.priceRange.max;
    }

    this._comboSelectedService.airlineOptions$.subscribe(airline => {
      if (airline) {
        if (airline === 'VJ') {
          const VJIndex = this.data.findIndex(item => item.branchCode === 'VJ');
          this.data = this.data.slice(VJIndex, 1);
        } else {
          this.data = this.data.filter(item => item.branchCode !== 'VJ');
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.type === 'range' &&
      changes.priceRange &&
      changes.priceRange.currentValue
    ) {
      const priceRange = changes.priceRange.currentValue;
      this.rangeOptions = this.makeRangeOptions(priceRange.min, priceRange.max);
      this.rangeValue = priceRange.max;
    }
  }

  rangeChanged(event: any) {
    this.priceValueDebounce$.next(event.value);
  }

  makeRangeOptions(min: number, max: number) {
    return {
      animate: false,
      floor: min,
      ceil: max,
      hideLimitLabels: true,
      hidePointerLabels: true,
      draggableRange: false
    };
  }

  checkCheckboxChecked(value: any) {
    return this.checkedItems.find(item => item === value);
  }

  onCheckboxChanged(event: MatCheckboxChange) {
    const checkedValue = event.source.value;
    const index = this.checkedResults.findIndex(item => item === checkedValue);
    if (index >= 0) {
      this.checkedResults.splice(index, 1);
    }
    if (event.checked) {
      this.checkedResults.push(checkedValue);
    }
    this.applyChecked()
  }
  resetChecked() {
    this.checkedResults = [];
    this.checkedItems = [];
    this.data.forEach(element => {
      element.checked = false;
    });
    setTimeout(() => {
      this.changed.emit(this.checkedResults);
    }, 100);
  }
  applyChecked() {
    this.changed.emit(this.checkedResults);
  }


  displayCheckboxName(name: string, filter: any) {
    switch (name) {
      case 'airlineName':
        return filter.name;
      case 'cabinName':
        return this._translateService.instant('airline.' + filter.value);
      case 'stopName':
        return filter.value == 0 ? 'Bay thẳng' : `${filter.value} điểm dừng`;
    }
  }

  displayTime(timeArr: string[]) {
    const allTimeSelected = [];
    timeArr.forEach((value, index) => {
      if (value) allTimeSelected.push(this.checkedItems[index]);
    });
    return allTimeSelected.length == this.checkedItems.length ||
    !allTimeSelected.length
      ? this._translateService.instant('newFlight.filter.allTime')
      : allTimeSelected
        .map(timeSelected => this._translateService.instant(timeSelected))
        .join(', ');
  }
}
