import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import { FlightSearchFilterOptionsPartialState } from './flight-search-filter-options.reducer';
import {
  LoadFlightSearchFilterOptions,
  FlightSearchFilterOptionsLoaded,
  FlightSearchFilterOptionsLoadError,
  FlightSearchFilterOptionsActionTypes
} from './flight-search-filter-options.actions';
import { AirTicketsResourceService } from '@gtd/b2c-client';
import { map } from 'rxjs/operators';

@Injectable()
export class FlightSearchFilterOptionsEffects {
  @Effect() loadFlightSearchFilterOptions$ = this.dataPersistence.fetch(
    FlightSearchFilterOptionsActionTypes.LoadFlightSearchFilterOptions,
    {
      run: (
        action: LoadFlightSearchFilterOptions,
        state: FlightSearchFilterOptionsPartialState
      ) => {
        // Your custom REST 'load' logic goes here. For now just return an empty list...
        return this._airTicketResourceService
          .getAirFilterOptionsUsingPOST(action.payload)
          .pipe(
            map(payload => ({
              type:
                FlightSearchFilterOptionsActionTypes.FlightSearchFilterOptionsLoaded,
              payload: payload
            }))
          );
      },

      onError: (action: LoadFlightSearchFilterOptions, error) => {
        console.error('Error', error);
        return new FlightSearchFilterOptionsLoadError(error);
      }
    }
  );

  constructor(
    private actions$: Actions,
    private dataPersistence: DataPersistence<
      FlightSearchFilterOptionsPartialState
    >,
    private _airTicketResourceService: AirTicketsResourceService
  ) {}
}
