import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import { FlightSearchLowFarePartialState } from './flight-search-low-fare.reducer';
import { flightSearchLowFareQuery } from './flight-search-low-fare.selectors';
import {
  LoadFlightSearchLowFare,
  ResetFlightSearchLowFare
} from './flight-search-low-fare.actions';
import { LowfareSearchQueryParam } from '@gtd/helpers';

@Injectable()
export class FlightSearchLowFareFacade {
  loaded$ = this.store.pipe(select(flightSearchLowFareQuery.getLoaded));
  allFlightSearchLowFare$ = this.store.pipe(
    select(flightSearchLowFareQuery.getAllFlightSearchLowFare)
  );
  selectedFlightSearchLowFare$ = this.store.pipe(
    select(flightSearchLowFareQuery.getSelectedFlightSearchLowFare)
  );

  constructor(private store: Store<FlightSearchLowFarePartialState>) {}

  loadAll(payload: LowfareSearchQueryParam) {
    this.store.dispatch(new LoadFlightSearchLowFare(payload));
  }

  reset() {
    this.store.dispatch(new ResetFlightSearchLowFare());
  }
}
