import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatePlanItemComponent } from './rate-plan-item.component';
import {
  GButtonModule,
  GMenuModule,
  GTypographyModule
} from '@gotadi/design-system';
import { MatDialogModule, MatIconModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { DetailDialogModule } from '@gtd/hotels/ui/detail-dialog';
import { DetailPriceExchangeModule } from '@gtd/hotels/ui/detail-price-exchange';
import {CurrencyExchangeModule} from "@gtd/pipes/currency-exchange";

@NgModule({
  imports: [
    CommonModule,
    GButtonModule,
    MatIconModule,
    TranslateModule,
    GMenuModule,
    GTypographyModule,
    MatDialogModule,
    DetailDialogModule,
    TranslateModule,
    DetailPriceExchangeModule,
    CurrencyExchangeModule
  ],
  declarations: [RatePlanItemComponent],
  exports: [RatePlanItemComponent]
})
export class RatePlanItemModule {}
