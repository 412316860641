import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { PropertyAvailable } from '@gtd/b2c-client';
import { HotelFunctionService } from '@gtd/hotels/data-access/services';
import { ExchangeOptionService } from '@gtd/lotus/shared/data-access/services';
import { NgbDayTemplateData } from '@ng-bootstrap/ng-bootstrap/datepicker/datepicker-view-model';

@Component({
  selector: 'gtd-price-exchange',
  templateUrl: './price-exchange.component.html',
  styleUrls: ['./price-exchange.component.scss']
})
export class PriceExchangeComponent implements OnInit {
  @Input() shortLinkId?: string;
  @Input() dayGap: number;
  @Input() numberOfRooms: number;
  @Input() numberOfAdults: number;
  @Input() numberOfChilds: number;
  @Input() priceBeforePromo: number;
  @Input() priceAfterPromo: number;
  @Input() exchangeRate = 0.5;
  @Input() search: any;
  @Input() searchId: string;
  @Input() item: PropertyAvailable;
  @Input() mapView: boolean;

  userValue = 0;
  exchangeOption$ = this._exchangeOptionService.option$;
  utm_source = localStorage.getItem('utm_source')
  constructor(
    private _exchangeOptionService: ExchangeOptionService,
    private _hotelFunctionService: HotelFunctionService
  ) {}

  ngOnInit() {}

  get params() {
    return this._hotelFunctionService.generateUrl(
      this.shortLinkId,
      this.item.masterPropertyId,
      this.item.propertyId,
      this.item.supplier,
    );
  }

  selectRoom() {
    // this._hotelNewTabService.setTab(window.open('/checkout/hotel', '_blank'));
    // this._hotelBookingFacade.loadAll({
    //   tripId: this.tripId,
    //   ratePlanId: this.ratePlan.ratePlanId,
    //   roomId: this.room.id
    // });
  }
}
