/**
 * metasrv API
 * metasrv API documentation
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ExternalConfigValueDTO { 
    data: string;
    id?: number;
    type: ExternalConfigValueDTO.TypeEnum;
}
export namespace ExternalConfigValueDTO {
    export type TypeEnum = 'REF_CODE' | 'EXPIRED' | 'DATE_CREATED' | 'LINK_NEWS' | 'DATE_START' | 'DATE_EXPIRED' | 'IMG' | 'TITLE';
    export const TypeEnum = {
        REFCODE: 'REF_CODE' as TypeEnum,
        EXPIRED: 'EXPIRED' as TypeEnum,
        DATECREATED: 'DATE_CREATED' as TypeEnum,
        LINKNEWS: 'LINK_NEWS' as TypeEnum,
        DATESTART: 'DATE_START' as TypeEnum,
        DATEEXPIRED: 'DATE_EXPIRED' as TypeEnum,
        IMG: 'IMG' as TypeEnum,
        TITLE: 'TITLE' as TypeEnum
    };
}
