import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BranchFilter} from "../brand-filter/brand-item/string2-branch-mapper.pipe";
import {MatCheckboxChange} from "@angular/material";

@Component({
  selector: 'gtd-filter-timer',
  templateUrl: './filter-timer.component.html',
  styleUrls: ['./filter-timer.component.scss']
})
export class FilterTimerComponent implements OnInit {
  @Input() title: string;
  @Input() branchFilters: BranchFilter[] = [];
  @Input() checkedItems: string[] = [];
  @Output() changed: EventEmitter<string[]> = new EventEmitter<string[]>();

  checkedResults = this.checkedItems.slice();

  constructor() { }

  ngOnInit() {
  }

  checkCheckboxChecked(value: any) {
    return this.checkedItems.find(item => item === value);
  }

  onCheckboxChanged(checkedValue: string, isChecked: boolean) {
    const index = this.checkedResults.findIndex(item => item === checkedValue);
    if (index >= 0) {
      this.checkedResults.splice(index, 1);
    }
    if (!isChecked) {
      this.checkedResults.push(checkedValue);
    }
    this.applyChecked()
  }

  applyChecked() {
    this.changed.emit(this.checkedResults);
  }
}
