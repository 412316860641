import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';

import {
  HotelFilterAvailablePartialState,
  HotelFilterAvailableState
} from './hotel-filter-available.reducer';
import { hotelFilterAvailableQuery } from './hotel-filter-available.selectors';
import {
  ChangeFilterBedTypes,
  ChangeFilterBreakfastIncluded,
  ChangeFilterDistance,
  ChangeFilterGuestRating,
  ChangeFilterHotelCategories,
  ChangeFilterHotelName,
  ChangeFilterLanguage,
  ChangeFilterMealPlans,
  ChangeFilterPrice,
  ChangeFilterPropertyAmenities,
  ChangeFilterRateAmenities,
  ChangeFilterRoomAmenities,
  ChangeFilterRoomViews,
  ChangeFilterStarRating,
  ChangeFilterThemes,
  ChangeHotelFilterAvailable,
  ChangePageNumber,
  RetryHotelSearch,
  ChangePageSize,
  ChangeSort,
  ResetHotelFilter
} from './hotel-filter-available.actions';
import {Observable} from "rxjs/Observable";
import {of} from "rxjs";

@Injectable()
export class HotelFilterAvailableFacade {
  allHotelFilterAvailable$ = this.store.pipe(
    select(hotelFilterAvailableQuery.getAllHotelFilterAvailable)
  );

  constructor(private store: Store<HotelFilterAvailablePartialState>) {}

  resetFilter() {
    this.store.dispatch(new ResetHotelFilter());
  }

  changeHotelFilter(filterAvailableState: HotelFilterAvailableState) {
    this.store.dispatch(new ChangeHotelFilterAvailable(filterAvailableState));
  }

  changeFilterHotelName(hotelName: string) {
    this.store.dispatch(new ChangeFilterHotelName(hotelName));
  }

  changeFilterHotelCategories(hotelCategories: Array<string>) {
    this.store.dispatch(new ChangeFilterHotelCategories(hotelCategories));
  }

  changeFilterBreakfastIncluded(breakfastIncluded: boolean) {
    this.store.dispatch(new ChangeFilterBreakfastIncluded(breakfastIncluded));
  }

  changeFilterPrice(fromPrice: number, toPrice: number) {
    this.store.dispatch(
      new ChangeFilterPrice({ fromPrice: fromPrice, toPrice: toPrice })
    );
  }

  changeFilterDistance(fromDistance: number, toDistance: number) {
    this.store.dispatch(
      new ChangeFilterDistance({
        fromDistance: fromDistance,
        toDistance: toDistance
      })
    );
  }

  changeFilterStarRating(starRating: Array<string>) {
    this.store.dispatch(new ChangeFilterStarRating(starRating));
  }

  changeFilterGuestRating(from: number, to: number) {
    this.store.dispatch(new ChangeFilterGuestRating({ from: from, to: to }));
  }

  changeFilterRoomAmenities(filterRoomAmenities: Array<string>) {
    this.store.dispatch(new ChangeFilterRoomAmenities(filterRoomAmenities));
  }

  changeFilterRateAmenities(filterRateAmenities: Array<string>) {
    this.store.dispatch(new ChangeFilterRateAmenities(filterRateAmenities));
  }

  changeFilterPropertyAmenities(filterPropertyAmenities: Array<string>) {
    this.store.dispatch(
      new ChangeFilterPropertyAmenities(filterPropertyAmenities)
    );
  }

  changeFilterRoomViews(filterRoomViews: Array<string>) {
    this.store.dispatch(new ChangeFilterRoomViews(filterRoomViews));
  }

  changeFilterThemes(filterThemes: Array<string>) {
    this.store.dispatch(new ChangeFilterThemes(filterThemes));
  }

  changeFilterMealPlans(filterMealPlans: Array<string>) {
    this.store.dispatch(new ChangeFilterMealPlans(filterMealPlans));
  }

  changeFilterBedTypes(filterBedTypes: Array<string>) {
    this.store.dispatch(new ChangeFilterBedTypes(filterBedTypes));
  }

  changeFilterLanguage(language: 'vi' | 'en') {
    this.store.dispatch(new ChangeFilterLanguage(language));
  }

  changeSort(
    sortField: 'order' | 'price' | 'starRating' | 'guestRating',
    sortOrder: 'ASC' | 'DESC'
  ) {
    this.store.dispatch(
      new ChangeSort({ sortField: sortField, sortOrder: sortOrder })
    );
  }

  changePageNumber(pageNumber: number): Observable<boolean> {
    this.store.dispatch(new ChangePageNumber(pageNumber));
    return of(true);
  }

  retryHotel(): Observable<boolean> {
    this.store.dispatch(new RetryHotelSearch());
    return of(true);
  }

  changePageSize(pageSize: number) {
    this.store.dispatch(new ChangePageSize(pageSize));
  }
}
