import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_PAGINATION_OFFSET, fadeIn } from '@gtd/helpers';
import { AirLowFareSearchRS, GroupPricedItinerary } from '@gtd/b2c-client';
import { ActivatedRoute } from '@angular/router';
import { FlightSearchFacade } from '../flight-search/flight-search/+state/flight-search.facade';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gtd-flight-search-info',
  templateUrl: './flight-search-info.component.html',
  styleUrls: ['./flight-search-info.component.scss'],
  animations: [fadeIn]
})
export class FlightSearchInfoComponent implements OnInit {
  @Input() groupPricedItineraries: Array<GroupPricedItinerary>;
  @Input() flightSelected: any;
  @Input() isLoading: boolean;
  @Input() availableFlightsFacade: AirLowFareSearchRS;
  @Input() flightDirection = 'D';
  @Input() flightInformation: any;
  @Input() roomLength: number = 1;
  @Input() searchParams: any;

  panelOpenState: boolean;
  isCollapsed: boolean;
  loadedFlightSearch$ = this.flightSearchFacade.loaded$;
  // searchParams: any;
  subscription: Subscription = new Subscription();
  flightSearchHistory: any;
  @Output() changed: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private flightSearchFacade: FlightSearchFacade
  ) {
    // this.activatedRoute.queryParams.subscribe(param => {
    //   this._handleQueryFromParams(
    //     JSON.parse(decodeURIComponent(atob(param.params)))
    //   );
    // });
  }

  ngOnInit() {
    this.flightSearchHistory = {
      from: JSON.parse(localStorage.getItem('comboFrom')),
      to: JSON.parse(localStorage.getItem('comboTo'))
    };
  }

  // private _handleQueryFromParams(param) {
  //   this.searchParams = {
  //     adutsQtt: param.adult,
  //     cabinClass: 'E',
  //     childrenQtt: param.child,
  //     departureDate: param.fromDate,
  //     destinationCode: param.to,
  //     infantsQtt: param.infant,
  //     originCode: param.from,
  //     page: 0,
  //     returntureDate: param.toDate ? param.toDate : '',
  //     routeType: param.roundType,
  //     size: DEFAULT_PAGINATION_OFFSET,
  //     time: new Date().getTime().toString()
  //   };
  // }

  resetFilter(formSearchResult: any) {
    this.changed.emit(formSearchResult);
  }
}
