import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Btn, DialogMessage } from '../dialogMessage';
import { Observable, timer } from 'rxjs';
import { take, map, tap, find } from 'rxjs/operators';
import {
  AirTicketsResourceService,
  BookingTravellerRQ,
  OTPServiceReq
} from '@gtd/b2c-client';
import { TranslateService } from '@ngx-translate/core';

interface OtpCheckoutMessage extends DialogMessage {
  bookingTravellerRQ: BookingTravellerRQ;
  verificationCode: string;
}

@Component({
  selector: 'gtd-dialog-otp-checkout',
  templateUrl: './dialog-otp-checkout.component.html',
  styleUrls: ['./dialog-otp-checkout.component.scss'],
  providers: [AirTicketsResourceService]
})
export class DialogOtpCheckoutComponent implements OnInit {
  otp: string;
  counter$: Observable<number>;
  counter: number;
  count = 180;
  error = '';
  loading = false;
  resendOtpCount = 0;
  sendCount = 0;
  otpResended: boolean;

  constructor(
    public dialogRef: MatDialogRef<DialogOtpCheckoutComponent>,
    private airTicketsResourceService: AirTicketsResourceService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: OtpCheckoutMessage
  ) {
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count),
      tap(count => {
        if (count == 0) {
          this.error = this.translateService.instant('checkout.otp-expired');
        }
      })
    );
  }

  ngOnInit() {
    this.counter$.subscribe(counter => {
      this.counter = counter;
    });
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  onNoClick() {
    this.dialogRef.close();
  }

  verifyOtp(): boolean {
    if (!this.otp || this.otp.length !== 6) return false;
    return true;
  }

  onActionClick(): void {
    if (this.sendCount == 3) {
      this.dialogRef.close({ action: 'tooManyActions' });
      return;
    }
    this.sendCount++;

    this.loading = true;
    this.data.bookingTravellerRQ.otpServiceReq = {
      otpCode: this.otp,
      verificationCode: this.data.verificationCode
    };
    this.airTicketsResourceService
      .addBookingTravellerUsingPOST(this.data.bookingTravellerRQ, 'B2C_WEB')
      .subscribe(
        response => {
          if (!response) return;
          this.loading = false;
          if (!response.otpServiceRes.matched) {
            this.error = this.translateService.instant('checkout.otp-wrong');
            this.data.verificationCode =
              response.otpServiceRes.verificationCode;
            return;
          }
          this.dialogRef.close({ action: 'passed' });
        },
        err => {
          this.loading = false;
          this.error = 'Đã xảy ra lỗi khi gửi yêu cầu';
          console.error('API Error:', err);
        }
      );
  }
  onActionResend(): void {
    if (this.resendOtpCount == 3) {
      this.dialogRef.close({ action: 'tooManyActions' });
      return;
    }
    this.resendOtpCount++;

    this.error = '';
    this.loading = true;
    this.data.bookingTravellerRQ.otpServiceReq = {
      lang: OTPServiceReq.LangEnum.VI
    };
    this.airTicketsResourceService
      .addBookingTravellerUsingPOST(this.data.bookingTravellerRQ, 'B2C_WEB')
      .subscribe(response => {
        if (!response) return;
        this.loading = false;
        this.otpResended = true;
        this.data.verificationCode = response.otpServiceRes.verificationCode;
        setTimeout(() => {
          this.otpResended = false;
        }, 5000);
      });
  }
}
