import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelDateComponent } from './panel-date.component';
import { TransitTimeRoomComponent } from './transit-time-room.component';
import { DisplayDateByCurrentLanguagePipe } from './display-date-by-current-language.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [
    DisplayDateByCurrentLanguagePipe,
    PanelDateComponent,
    TransitTimeRoomComponent
  ],
  declarations: [
    DisplayDateByCurrentLanguagePipe,
    PanelDateComponent,
    TransitTimeRoomComponent
  ]
})
export class DatePipeModule {}
